import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Grid, TextField } from '@material-ui/core';
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import {getItem } from '../../components/services/LocaleStorage';
import axios from "axios";
import functions from "../../constants/serverFunctions";
import logoutoutline from '@iconify/icons-eva/log-in-outline';
import checkmarkcircle from '@iconify/icons-eva/checkmark-circle-fill';
import closecircle from '@iconify/icons-eva/close-circle-outline';
import Modal from 'react-bootstrap/Modal';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {Button as Button2} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Auth from "../../components/contexts/Auth";
import { logout } from "../../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 380;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, value }) {

  const token = getItem('token-assembleia-api');
  const IdRoom = getItem('IdRoom'); 
  const institution = getItem('institutionName');
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [DadosReuniao, setDadosReuniao] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);



//Consulta dados da reuniao
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          toast.warn('Sessão expirada!');
        }else{
              if(res.data.records === 1){

                axios
                .get(functions.assembleia.BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_BOTAO_ADM+"/"+institution+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
                  .then((res) => {
                 (res.data.records !== 0 && setDadosReuniao(res.data.result.recordset))
                  })
                  .catch((err) => {
                  });
              
              }else{
                logout();
                setIsAuthenticated(false);
                toast.error('Token inválido!');
              } 


        }


      }); 

}, [IdEntidade,IdUser,institution,IdRoom,token]);




//mapeia a dados da reuniao
const LISTADADOS = DadosReuniao.map((Dado, index) => ({
  ID_Assembleia: Dado.ID_Assembleia,
  TimerInicio: Dado.TimerInicio,
  TimerFim: Dado.TimerFim

}));










 

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

                      {LISTADADOS.map((pauta, id) => (
                        <DADOSTOPO key={id} {...pauta} />       
                      ))}

      
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
         
       

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}








const DADOSTOPO = (props) => {

  const{
    ID_Assembleia,
    TimerInicio,
    TimerFim

  }= props;
  


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  let history = useHistory();

  const IdUser = getItem('userId'); 
  const token = getItem('token-assembleia-api');
    
   
 

 const LoginSchema = Yup.object({

  encerrar: Yup
  .string()
  .required("Campo Obrigatório")
  .test("é maior", 'Palavra incorreta', function(value) {
   return value === "encerrar" || value === "ENCERRAR" ;
  })


  });

const formik = useFormik({
  initialValues: {
    encerrar: '',
    id: ID_Assembleia,
    fk_usuario_encerramento: IdUser
  },
 
  validationSchema: LoginSchema,
  onSubmit: async () => {


    //console.log(values)

axios.post(functions.itemPauta.ENCERRAR_ITENS_PAUTA, 
      values,
      { headers: {"Authorization" : `${token}`} }
      )
         .then((response) => {
       
          axios.post(functions.assembleia.ENCERRAR_ASSEMBLEIA, 
            values,
            { headers: {"Authorization" : `${token}`} }
            )
               .then((response) => {
              toast.success('Reunião Encerrada!');
              history.replace('/meetings');
               });

         });    
    

     

  }
});



//botao apagar item
const iniciarreuniao = () => {

axios.post(functions.assembleia.INICIAR_ASSEMBLEIA, 
{
  id:ID_Assembleia,
  fk_usuario_inicio:IdUser
},
{ headers: {"Authorization" : `${token}`} }
)
   .then((response) => {
  toast.success('Reunião Iniciada!');
  window.location.reload(false);
   });

}

const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
 


      return (
        <>


<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

    <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Deseja iniciar reunião?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>


        <Stack spacing={3}>
        <div>
        <Icon icon={checkmarkcircle} width={70} height={70} color="#27ab55"/>
        </div>
        <br /> 
        <b style={{fontSize:"13px"}}>Após iniciada estará aberta para os participantes registrarem presença e 
          não será mais possível alterar a data. 
          <br />A criação de novos itens de pauta, 
          de propostas nos itens, eleições, chapas e candidatos continua liberada.</b>
        <br />
       
        </Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2 className="btn-success" variant="primary" onClick={(e) => iniciarreuniao('',e)}>Iníciar Reunião</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>





{/* MODAL DE ENCERRAR REUNIAO */}

<Modal visible style={{ zIndex: 1200}}
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

    <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Você deseja encerrar essa assembleia?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>


        <Stack spacing={3}>
        <div>
        <Icon icon={closecircle} width={60} height={60} color="#d10e04"/>
        </div>
        <b style={{fontSize:"14px"}}>Ao encerrar a assembleia, não será possível criar e nem alterar nenhum Item de Pauta.</b><br />
        <p>Digite <b>ENCERRAR</b> no campo abaixo para encerrar a reunião </p>
        


        <TextField
            fullWidth
            autoComplete="encerrar"
            type="text"
            label=""
            {...getFieldProps('encerrar')}
            error={Boolean(touched.encerrar && errors.encerrar)}
            helperText={touched.encerrar && errors.encerrar}
            style={{padding: "10px 14px"}}
          />

          <br />

       
        </Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose2}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
          {/*<Button2 className="btn-success" variant="primary" onClick={(e) => iniciarreuniao('',e)}>Iníciar Reunião</Button2>*/}
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>


{/* FINAL MODAL ENCERRAR */}






{(
(TimerInicio > 0 &&
        <Grid item xs={3}>
          <Button
              fullWidth
              variant="contained"
              onClick={handleShow}
              style={{textDecoration:"none",color:"#ffffff"}}
            >
              Iniciar Reunião
            </Button>
          </Grid>)

          ||

          (TimerFim > 0 &&
            <Grid item xs={3}>
              <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleShow2}
                  style={{textDecoration:"none",color:"#27ab55"}}
                >
                   <Icon icon={logoutoutline} style={{fontSize:"25px"}} /> &nbsp;&nbsp; Encerrar Reunião
                </Button>

              </Grid>)

              ||

            ""

)}



    
        </>
      );
    };
    
