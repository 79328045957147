import * as Yup from 'yup';
import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
//Notificacao
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Autenticar
import { login } from "./LoginAuth";
import Auth from "../../contexts/Auth";

// ----------------------------------------------------------------------

export default function LoginForm( ) {

  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

  const [Entidades, setEntidades] = useState();
  const [Perfil, setPerfil] = useState();

  let history = useHistory();



  const LoginSchema = Yup.object().shape({
   // emailUsuario: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
    emailUsuario: Yup.string().required('Campo obrigatório'),
    senha: Yup.string().required('Senha obrigatória')
  });

  const formik = useFormik({
    initialValues: {
      emailUsuario: '',
      senha: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
    

                try {
                  const response = await login(values);
                  setEntidades(response[0]);
                  setPerfil(response[1]);
                  setIsAuthenticated(response[0]);
                  toast.success('Sucesso! Bem Vindo');
                
                } catch (error) {
                
                }

    }
  });



  useEffect(() => {
    if (isAuthenticated) {

     history.push({
           pathname: `/Instituicao`,
           state: { entidades: Entidades,nivelPerfil: Perfil  },
         });

    }
  }, [history, isAuthenticated,Entidades,Perfil]);



  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
        
          <TextField
            fullWidth
            autoComplete="emailUsuario"
            type="text"
            label="CPF / CNPJ / E-mail"
            {...getFieldProps('emailUsuario')}
            error={Boolean(touched.emailUsuario && errors.emailUsuario)}
            helperText={touched.emailUsuario && errors.emailUsuario}
          />

          <TextField
            fullWidth
            autoComplete="current-senha"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('senha')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.senha && errors.senha)}
            helperText={touched.senha && errors.senha}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Lembrar login"
          />

          <div className = 'link-tela-login' >
          <Link component={RouterLink} variant="subtitle2" to="../RecuperarSenha">
          Esqueceu sua senha?
          </Link>
          </div>
        </Stack>

        <LoadingButton
          fullWidth 
          className = 'btn-login'
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>

     

      <ToastContainer />
    </FormikProvider>
  );
}
