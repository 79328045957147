import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  TextField,
  CardContent,
  Button,
  FormHelperText,
  Avatar,
  AvatarGroup,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect,useContext} from "react";
import { Link as RouterLink, useHistory} from 'react-router-dom';
import axios from "axios";
import { useFormik, FormikProvider} from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
import { Icon } from '@iconify/react';
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import {Button as Button2} from 'reactstrap';
//token
import { getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";
import { IMaskInput } from 'react-imask';
import Form from 'react-bootstrap/Form';
import { Checkbox, Row, Col } from "antd";
import "antd/dist/antd.css";
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));


// ----------------------------------------------------------------------




VoteParticipante.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});






export default function VoteParticipante() {

  const token = getItem('token-assembleia-api');

  const [DadosItem, setDadosItem] = useState([]);
  const [AbasTipoEleicao, setAbasTipoEleicao] = useState([]);
  const [ListProps, setListProps] = useState([]);
  const [setQuantListProps] = useState([]);
  const [ListCandts, setListCandts] = useState([]);
  const [ListCandtsInd, setListCandtsInd] = useState([]);
  const [setQuantCandInd] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);

  const IdItem = getItem('IdPropVote');




// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.itemPauta.BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID_VOTACAOELEICAO_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        toast.warn('Sessão expirada!');
      }else{
        (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
        setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])
      }

    })
    .catch((err) => {
    });
}, [IdItem,token]);





//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM_VOTACAO_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
   setQuantListProps(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token,setQuantListProps]);


/*
//mapeia a consulta
const LISTAPROPOSTA = ListProps.map((ListaProp, index) => ({

  itemPauta: ListaProp.itemPauta, 
  idItem: ListaProp.idItem, 
  tipo: ListaProp.tipo, 
  Chapa: ListaProp.Chapa, 
  idChapa: ListaProp.idChapa, 
  descricaoChapa: ListaProp.descricaoChapa, 
  quantCandidatos: ListaProp.quantCandidatos,


  }));
*/




//Consulta lista de candidatos do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_POR_CHAPA_POR_IDITEM_VOTACAO_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListCandts(res.data.result.recordset)))
    })
    .catch((err) => {
    });
}, [IdItem,token]);





  //Consulta lista de candidatos INDIVIDUAL do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_IDITEM_VOTACAO_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListCandtsInd(res.data.result.recordset)))
   setQuantCandInd(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token,setQuantCandInd]);




  





  return (
    <RootStyle title="Votação Eleição | ASSEMBLEIACOOP">
       <AuthLayout>
        <Button
                className="btn btn-cinza" 
                component={RouterLink} 
                to="room"
                
              >
               <Icon icon={cornerupleft}/>&nbsp; Voltar
            </Button>
       </AuthLayout>
   
     

      <Container maxWidth="sm" style={{paddingLeft: "0px",paddingRight: "100px"}}>
        <ContentStyle>


       

          <Stack>

         


  {(AbasTipoEleicao === "Chapa Eleitoral" &&
            <>
              <ListaChapaDetail value={[ListProps,ListCandts,DadosItem]}/>
            </>      
    )
    ||
    (AbasTipoEleicao === "Candidato Individual" &&
            <>
              <ListaCandIndDetail value={[ListCandtsInd,DadosItem]}/>
            </>   
    )
    ||
    (AbasTipoEleicao === "Ambos" &&
            <>
             
              <ListaAmbosDetail value={[ListProps,ListCandts,ListCandtsInd,DadosItem]} />
            </>   
    )

  }



          </Stack>
 
        </ContentStyle>
      </Container>
      
    </RootStyle>
  );

  
}





//votacao chapa
const ListaChapaDetail = (props) => {

  const history = useHistory();
  const IdItem= getItem('IdPropVote'); 
  const token = getItem('token-assembleia-api');
  const IdUser = getItem('userId');
  const cpfcnpj = getItem('tagId');
  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;
  const listCandidatos = props.value[1];
  const coutCandidatos = props.value[1].length;
  const dadosDoItem = props.value[2];

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState('Selecione uma das opções acima');

  const { setIsAuthenticated } = useContext(Auth);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };


  const handleSubmit2 = (event) => {
    event.preventDefault();

    handleShow();

  }


  const LoginSchema = Yup.object({

    codigovotacao: Yup
    .number()
    .required("Campo Obrigatório")
    .test("é maior", 'Código incorreto', function(value) {
     return value === dadosDoItem[0]['codigo'];
    })


    });

  const formik = useFormik({
    initialValues: {
      codigovotacao: ''
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      let opcaoproposta = '';

      if(value !== 0){
       opcaoproposta = 1;
      }else{
        opcaoproposta = 0;
      }



      axios.get(functions.votacao.VERIFICAR_VOTACAO+"/"+IdItem+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
      .then((retorno) => {
      

        if(retorno.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          toast.warn('Sessão expirada!');
        }else{
      
       if(retorno.data.result.recordset[0]['tempoVotacao'] >= 0){
             if(retorno.data.result.recordset[0]['voto'] === "NÃO"){
                 
                    axios.post(functions.votacao.INSERIR_VOTACAO_CHAPA, 
                      {
                        idItempauta: IdItem,
                        idChapa: value,
                        opcaoproposta: opcaoproposta,
                        cpfcnpj: cpfcnpj
                      },
                      { headers: {"Authorization" : `${token}`} })
                    .then((res) => {
                        axios.post(functions.voto.INSERIR_VOTO, {
                          idItemPauta: IdItem,
                          idUsuario: IdUser,
                          cpfcnpj: cpfcnpj
                          },
                          { headers: {"Authorization" : `${token}`} })
                        .then((response) => {
                          toast.success('Votação realizada com sucesso!');
                          history.replace('/room');
                          });
          
                    });
   
             }else{
             toast.error('Voto já realizado!');
             history.replace('/room');
             }
      
       }else{
         toast.error('Votação finalizada!');
         history.replace('/room');
       }
      


      }


      
      });
      
      



     

    }
  });

  const { errors, touched, //values,
     isSubmitting, handleSubmit, getFieldProps } = formik;


  

  let chapas = []
  let users = []
  let users2 = []
  //let candidatos = []
  //let listacandidatos = []






   for (let i = 0; i < coutChapa; i++) {
        users = []
       // listacandidatos = []
       let imgAvatar = "/static/illustrations/candidato/default3.png";
        for(let j = 0; j < coutCandidatos; j++){
            if(listCandidatos[j]['idChapa'] === listChapas[i]['idChapa']){
              
      if(listCandidatos[j]['idCandidato']==129){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
      }else if(listCandidatos[j]['idCandidato']==130){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
      }else if(listCandidatos[j]['idCandidato']==131){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
      }else if(listCandidatos[j]['idCandidato']==132){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
      }else if(listCandidatos[j]['idCandidato']==136){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
      }else if(listCandidatos[j]['idCandidato']==137){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
      }   
      else{
        imgAvatar = "/static/illustrations/candidato/default3.png";
      }


              users.push(<>

             { /*
                            <Avatar 
                            alt={listCandidatos[j]['candidato']}
                            src="/static/illustrations/candidato/default3.png"
                            sx={{ width: 30, height: 30 }}
                            style={{marginLeft: "-1px"}} 
                            />
                            &nbsp;&nbsp;&nbsp;{listCandidatos[i]['candidato']} 
               */}  

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <b style={{alignItems:"center"}}> 
                    <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                    &nbsp;&nbsp;
                     <Avatar 
                      alt={listCandidatos[j]['candidato']}
                      //src="/static/illustrations/candidato/default3.png"
                      src={imgAvatar}
                      sx={{ width: 30, height:30 }}
                      style={{marginLeft: "-1px"}} 
                      />
                      &nbsp;&nbsp;&nbsp;{listCandidatos[j]['candidato']}     
                    </Typography>
                    </b>
               </Form.Group>  

               </>          
                           
                           )

                          

       }
     }
     
chapas.push(
<>
  <Card>
<CardContent style={{paddingBottom:"10px",padding:"12px"}}>
<Grid container spacing={2}>    
         <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
             <Box sx={{ mb: 0 }}>
        
                <Form.Check
                type="radio"
                label={
                 
                 <>                  
                 <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                  {listChapas[i]['descricaoChapa']}
                 </Typography>
                 <Typography variant="body2" color="text.secondary" style={{marginLeft:"15px"}}>
                  {/*<AvatarGroup max={9} style={{display: "inline-flex"}}>{users}</AvatarGroup>*/}
                  {users}
                 </Typography>
                 </>

                }
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                style={{marginTop:"1.5em", fontSize: "20px"}}
                onChange={handleRadioChange}
                value={listChapas[i]['idChapa']}
                />
        
               </Box>
         </Grid>    
      </Grid>
 </CardContent>   



 </Card>
 <br /> 
 </>

             
                )

        
   }


   
   if(dadosDoItem[0]["voto_branco"] === "SIM"){ 
  
    
    chapas.push(
      <>
        <Card>
      <CardContent style={{paddingBottom:"10px",padding:"12px"}}>
      <Grid container spacing={2}>    
               <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
                   <Box sx={{ mb: 0 }}>
              
                      <Form.Check
                      type="radio"
                      label={
                       
                       <>                  
                       <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                       Votar em branco
                       </Typography>
                       <Typography variant="body2" color="text.secondary"></Typography>
                       </>
      
                      }
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      style={{marginTop:"1.5em", fontSize: "20px"}}
                      onChange={handleRadioChange}
                      value={0}
                      />
              
                     </Box>
               </Grid>    
            </Grid>
       </CardContent>   
       </Card>
       <br /> 
       </>
      
                   
                      )

}
 





    return(
            <>

    {
    (
     ( dadosDoItem[0]["voto_branco"] === "SIM" && 

     <Stack sx={{ mb: 3 }}>
     <Typography variant="h5" gutterBottom>
       Selecione a chapa eleitoral ou se preferir pode votar em branco.
     </Typography>
     <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
   </Stack>
     
     )
    ||
      ( 
        <Stack sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Selecione a chapa eleitoral.
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
      </Stack>
      )
    )
    }       

<Form onSubmit={handleSubmit2}>
  <fieldset>
    <Form.Group as={Row} className="mb-3">
      <Col sm={10} style={{display: "block",flex: "0 0 100%", maxWidth: "100%"}}>

        {chapas}
        
      </Col>
    </Form.Group> 
    <FormHelperText style={{color:"red"}}>{helperText}</FormHelperText>
  </fieldset>


 
<Button 
sx={{ mt: 1, mr: 1 }} 
component={RouterLink} 
to="room" 
variant="outlined"
color="secondary"
>
  Cancelar
</Button>

  <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="contained" disabled={error}>
        Votar
        </Button>
</Form>



<Modal visible style={{ zIndex: 1200}}
        show={show} 
        className='borda tamanho-md'
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Código de votação2</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={2}>

<label>
Para confirmar seu voto, digite o código <u style={{color:"green",fontSize:"20px"}}>{dadosDoItem[0]['codigo']} </u> no campo abaixo
</label>
<br />



        <TextField 
        id="codigovotacao" 
        variant="standard"
        label="Código*"
        type="number"
        inputComponent={TextMaskCustom}
        {...getFieldProps('codigovotacao')}
        error={Boolean(touched.codigovotacao && errors.codigovotacao)}
        helperText={touched.codigovotacao && errors.codigovotacao}
        style={{width: "30%"}}
        />
        <br />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      <br/>
      <br />
      Ao confirmar o código, seu voto será computado.
    </label>
        
</Stack>

        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
</Modal>


            </>
          )

}








//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


//Candidato Individual - 
const ListaCandIndDetail = (props) => {

  const history = useHistory();
  const IdItem = getItem('IdPropVote'); 
  const token = getItem('token-assembleia-api');
  const IdUser = getItem('userId');
  const cpfcnpj = getItem('tagId');

  const listCandidatos = props.value[0];
  const coutCandidatos = props.value[0].length;
  const dadosDoItem = props.value[1];

  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState('Selecione os candidatos acima');
  const [checked, setchecked] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



const onChange = checkedValues => {
      setchecked(checkedValues);

  if(dadosDoItem[0]['quantidadeExataCandidatos'] === 'SIM'){
      if(checkedValues.length === dadosDoItem[0]['quantidade_candidatos']){
        setHelperText(' ');
        setError(false);
      }else if(checkedValues[0] === "0"){
        setHelperText(' ');
        setError(false);
      }else{
        setHelperText('Selecione os candidatos acima');
        setError(true);
      }
  }else if(dadosDoItem[0]['quantidadeExataCandidatos'] === 'NÃO'){
      if(dadosDoItem[0]['countVotosMin'] === null){
          if(checkedValues.length > 0){
            setHelperText(' ');
            setError(false);
          }else if(checkedValues[0] === "0"){
            setHelperText(' ');
            setError(false);
          }else{
            setHelperText('Selecione os candidatos acima');
            setError(true);
          }

      }else{
          if(checkedValues.length >= dadosDoItem[0]['countVotosMin']){
            setHelperText(' ');
            setError(false);
          }else if(checkedValues[0] === "0"){
            setHelperText(' ');
            setError(false);
          }else{
            setHelperText('Selecione os candidatos acima');
            setError(true);
          }

      }


  }

};


const isDisabled = id => {

    if(checked.length === 1 && checked[0] === "0"){
         return( checked.indexOf(id) === -1);
    }else if(checked.length > 0 && checked[0] !== "0" && id === "0"){
          return( checked.indexOf(id) === -1);
    }else if(checked.length === dadosDoItem[0]['quantidade_candidatos']){
         return( checked.indexOf(id) === -1);
    }

};




const LoginSchema = Yup.object({

      codigovotacao: Yup
      .number()
      .required("Campo Obrigatório")
      .test("correto", 'Código incorreto', function(value) {
      return value === dadosDoItem[0]['codigo'];
      })

  });


  const formik = useFormik({
    initialValues: {
      codigovotacao: ''
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      const token = getItem('token-assembleia-api');


   let idCandidato = checked;


   axios.get(functions.votacao.VERIFICAR_VOTACAO+"/"+IdItem+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
   .then((retorno) => {


    if(retorno.data.result.recordset[0]['tempoVotacao'] >= 0){
          if(retorno.data.result.recordset[0]['voto'] === "NÃO"){
               
            axios.post(functions.votacao.INSERIR_VOTACAO_CANDIDATO, 
              {
                idItempauta: IdItem,
                idCandidato: idCandidato,
                cpfcnpj: cpfcnpj
              },
              { headers: {"Authorization" : `${token}`} })
            .then((res) => {
                 axios.post(functions.voto.INSERIR_VOTO, {
                  idItemPauta: IdItem,
                  idUsuario: IdUser,
                  cpfcnpj: cpfcnpj
                   },
                   { headers: {"Authorization" : `${token}`} })
                 .then((response) => {
                  toast.success('Votação realizada com sucesso!');
                  history.replace('/room');
                  });
       
             });
          
          }else{
          toast.error('Voto já realizado!');
          history.replace('/room');
          }

    }else{
      toast.error('Votação finalizada!');
      history.replace('/room');
    }


  });

   

    }
  });







const { errors, touched, //values,
   isSubmitting, handleSubmit, getFieldProps } = formik;


let candidatos = []

let imgAvatar = "/static/illustrations/candidato/default3.png";
for (let i = 0; i < coutCandidatos; i++) {

  if(listCandidatos[i]['idCandidato']==129){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
  }else if(listCandidatos[i]['idCandidato']==130){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
  }else if(listCandidatos[i]['idCandidato']==131){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
  }else if(listCandidatos[i]['idCandidato']==132){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
  }else if(listCandidatos[i]['idCandidato']==136){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
  }else if(listCandidatos[i]['idCandidato']==137){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
  }   
  else{
    imgAvatar = "/static/illustrations/candidato/default3.png";
  }



  candidatos.push(
    <>
      <Card>
    <CardContent style={{paddingBottom:"10px",padding:"10px",marginTop:"25px"}}>
    <Grid container spacing={2}>    
             <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
                 <Box sx={{ mb: 0 }}>


                 <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Checkbox 
                  value={listCandidatos[i]['idCandidato']}
                  disabled={isDisabled(listCandidatos[i]['idCandidato'])}
                  style={{alignItems:"center"}}
                  > 
                  
                    <b style={{display:"flex", alignItems:"center"}}> 
                    <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                    &nbsp;&nbsp;
                     <Avatar 
                      alt={listCandidatos[i]['candidato']}
                      //src="/static/illustrations/candidato/default3.png"
                      src={imgAvatar}
                      sx={{ width: 56, height:56 }}
                      style={{marginLeft: "-1px"}} 
                      />
                      &nbsp;&nbsp;&nbsp;{listCandidatos[i]['candidato']}     
                    </Typography>
                    </b>

                  </Checkbox>
               </Form.Group>        
            
                   </Box>
             </Grid>    
          </Grid>
     </CardContent>   
    
     </Card>
     <br />
     </>
    
                 
    )

}





//se tiver opcao de voto em branco 
if(dadosDoItem[0]["voto_branco"] === "SIM"){ 

  candidatos.push(
    <>
    <Card>
    <CardContent style={{paddingBottom:"10px",padding:"10px",marginTop:"45px"}}>
    <Grid container spacing={2}>    
          <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
            <Box sx={{ mb: 0 }}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Checkbox 
                value={'0'}
                disabled={isDisabled('0')}
                > 
              
                  <b style={{display:"flex", alignItems:"center"}}> 
                  <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"contents", alignItems:"center"}}>
                    &nbsp;&nbsp;&nbsp;
                    Votar em branco                        
                  </Typography>
                  </b> <br />

                </Checkbox>
              </Form.Group>        
            </Box>
          </Grid>    
    </Grid>
    </CardContent>   
    </Card>
     <br />
     </>           
  )



}




 

return (
      <>

{
  (
     ( dadosDoItem[0]["voto_branco"] === "SIM" && 
          <Stack sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Escolha {((dadosDoItem[0]["quantidadeExataCandidatos"] === 'SIM' && 'exatamente ') ||
                        (dadosDoItem[0]["countVotosMin"] === null && 'até ') ||
                        'entre '
                      )}

                      {((dadosDoItem[0]["countVotosMin"] === null && dadosDoItem[0]["quantidade_candidatos"]) ||
                        (dadosDoItem[0]["countVotosMin"] +' e '+ dadosDoItem[0]["quantidade_candidatos"] )
                      )}

                      {((dadosDoItem[0]["quantidade_candidatos"] > 1 && ' candidatos') || ' candidato')} ou se preferir pode votar em branco
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
          </Stack>        
     )

    ||

    ( 
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
          Escolha {((dadosDoItem[0]["quantidadeExataCandidatos"] === 'SIM' && 'exatamente ') ||
                        (dadosDoItem[0]["countVotosMin"] === null && 'até ') ||
                        'entre '
                      )}

                      {((dadosDoItem[0]["countVotosMin"] === null && dadosDoItem[0]["quantidade_candidatos"]) ||
                        (dadosDoItem[0]["countVotosMin"] +' e '+ dadosDoItem[0]["quantidade_candidatos"] )
                      )}

                      {((dadosDoItem[0]["quantidade_candidatos"] > 1 && ' candidatos') || ' candidato')}
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
        </Stack>
    )
  )
}  



<Checkbox.Group style={{ width: "100%" }} onChange={onChange}>

{candidatos}

</Checkbox.Group>

<FormHelperText style={{color:"red"}}>{helperText}</FormHelperText>

<Checkbox.Group>
  <Button className='btn btn-cinza'
  sx={{ mt: 1, mr: 1 }} 
  component={RouterLink} 
  to="room"
  >
  <Icon icon={cornerupleft}/>&nbsp;
  Voltar
  </Button>

  <Button 
  sx={{ mt: 1, mr: 1 }} 
  variant="contained" 
  disabled={error}
  onClick={handleShow}
  >
    Votar
  </Button>
</Checkbox.Group>
     
     
     






 
<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Código de votação1</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


        <Stack spacing={2}>

<label>
Para confirmar seu voto, digite o código <u style={{color:"green",fontSize:"20px"}}>{dadosDoItem[0]['codigo']} </u> no campo abaixo
</label>
<br />



        <TextField 
        id="codigovotacao" 
        variant="standard"
        label="Código*"
        type="number"
        inputComponent={TextMaskCustom}
        {...getFieldProps('codigovotacao')}
        error={Boolean(touched.codigovotacao && errors.codigovotacao)}
        helperText={touched.codigovotacao && errors.codigovotacao}
        style={{width: "30%"}}
        />
        <br />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      <br/>
      <br />
      Ao confirmar o código, seu voto será computado.
    </label>
        
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>
    
      </>
    )
  
}



//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>






//Candidato Individual e chapa - AMBOS 
const ListaAmbosDetail = (props) => {

  const history = useHistory();
  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;
  const listCandidatosChapa = props.value[1];
  const coutCandidatos = props.value[1].length;
  const listCandidatosIndi = props.value[2];
  const coutCandidatosIndi = props.value[2].length;
  const dadosDoItem = props.value[3];

  const IdItem= getItem('IdPropVote'); 
  const token = getItem('token-assembleia-api');
  const IdUser = getItem('userId');
  const TitleVote = getItem('TitleVotation');
  const cpfcnpj = getItem('tagId');



  const steps = [
    "Escolher a Chapa",
    "Escolher o Candidato"
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError(true);
    setHelperText('Selecione uma das opções acima');

    setError2(true);
    setHelperText2('Selecione o candidato acima');

    setchecked([])
  };

  const handleReset = () => {
    setActiveStep(0);
  };







  //>>>>>>>>>>>>>>>>>>>>>


  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState('Selecione uma das opções acima');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };


  const handleSubmit2 = (event) => {
    event.preventDefault();

    handleShow();

  }



  let chapas = []
  let users = []
  let candidatos = []



   for (let i = 0; i < coutChapa; i++) {
        users = []
        let imgAvatar = "/static/illustrations/candidato/default3.png";
        for(let j = 0; j < coutCandidatos; j++){
            if(listCandidatosChapa[j]['idChapa'] === listChapas[i]['idChapa']){
              if(listCandidatosChapa[j]['idCandidato']==129){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
              }else if(listCandidatosChapa[j]['idCandidato']==130){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
              }else if(listCandidatosChapa[j]['idCandidato']==131){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
              }else if(listCandidatosChapa[j]['idCandidato']==132){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
              }else if(listCandidatosChapa[j]['idCandidato']==136){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
              }else if(listCandidatosChapa[j]['idCandidato']==137){
                imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
              }   
              else{
                imgAvatar = "/static/illustrations/candidato/default3.png";
              }

              users.push(<Avatar 
                            alt={listCandidatosChapa[j]['candidato']}
                            //src="/static/illustrations/candidato/default3.png"
                            src={imgAvatar}
                            sx={{ width: 30, height: 30 }}
                            style={{marginLeft: "-1px"}} 
                            />)

       }
     }
     
chapas.push(
<>
  <Card>
<CardContent style={{paddingBottom:"10px",padding:"12px"}}>
<Grid container spacing={2}>    
         <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
             <Box sx={{ mb: 0 }}>
        
                <Form.Check
                type="radio"
                label={
                 
                 <>                  
                 <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                  {listChapas[i]['descricaoChapa']}
                 </Typography>
                 <Typography variant="body2" color="text.secondary">
                  <AvatarGroup max={9} style={{display: "inline-flex"}}>{users}</AvatarGroup>
                 </Typography>
                 </>

                }
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                style={{marginTop:"1.5em", fontSize: "20px"}}
                onChange={handleRadioChange}
                value={listChapas[i]['idChapa']}
                />
        
               </Box>
         </Grid>    
      </Grid>
 </CardContent>   



 </Card>
 <br /> 
 </>

             
                )

        
   }

   
   if(dadosDoItem[0]["voto_branco"] === "SIM"){ 
  
    
    chapas.push(
      <>
        <Card>
      <CardContent style={{paddingBottom:"10px",padding:"12px"}}>
      <Grid container spacing={2}>    
               <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
                   <Box sx={{ mb: 0 }}>
              
                      <Form.Check
                      type="radio"
                      label={
                       
                       <>                  
                       <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                       Votar em branco
                       </Typography>
                       <Typography variant="body2" color="text.secondary"></Typography>
                       </>
      
                      }
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      style={{marginTop:"1.5em", fontSize: "20px"}}
                      onChange={handleRadioChange}
                      value={0}
                      />
              
                     </Box>
               </Grid>    
            </Grid>
       </CardContent>   
       </Card>
       <br /> 
       </>
      
                   
                      )

}



  //<<<<<<<<<<<<<<<<<<<<<<<<<< 



  //>>>>>>>>>>>>>>>>>INDIVIDUAL>>>>>>>>>>>>>>>>>>


  const [error2, setError2] = React.useState(true);
  const [helperText2, setHelperText2] = React.useState('Selecione o candidato acima');
  const [checked, setchecked] = useState([]);


const onChange = checkedValues => {
  setchecked(checkedValues);

if(dadosDoItem[0]['quantidadeExataCandidatos'] === 'SIM'){
  if(checkedValues.length === dadosDoItem[0]['quantidade_candidatos']){
    setHelperText2(' ');
    setError2(false);
  }else if(checkedValues[0] === "0"){
    setHelperText2(' ');
    setError2(false);
  }else{
    setHelperText2('Selecione o candidato acima');
    setError2(true);
  }
}else if(dadosDoItem[0]['quantidadeExataCandidatos'] === 'NÃO'){
  if(dadosDoItem[0]['countVotosMin'] === null){
      if(checkedValues.length > 0){
        setHelperText2(' ');
        setError2(false);
      }else if(checkedValues[0] === "0"){
        setHelperText2(' ');
        setError2(false);
      }else{
        setHelperText2('Selecione o candidato acima');
        setError2(true);
      }

  }else{
      if(checkedValues.length >= dadosDoItem[0]['countVotosMin']){
        setHelperText2(' ');
        setError2(false);
      }else if(checkedValues[0] === "0"){
        setHelperText2(' ');
        setError2(false);
      }else{
        setHelperText2('Selecione o candidato acima');
        setError2(true);
      }

  }


}


};


const isDisabled = id => {

if(checked.length === 1 && checked[0] === "0"){
     return( checked.indexOf(id) === -1);
}else if(checked.length > 0 && checked[0] !== "0" && id === "0"){
      return( checked.indexOf(id) === -1);
}else if(checked.length === dadosDoItem[0]['quantidade_candidatos']){
     return( checked.indexOf(id) === -1);
}

};


let imgAvatar = "/static/illustrations/candidato/default3.png";

for (let i = 0; i < coutCandidatosIndi; i++) {
  if(listCandidatosIndi[i]['idCandidato']==129){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
  }else if(listCandidatosIndi[i]['idCandidato']==130){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
  }else if(listCandidatosIndi[i]['idCandidato']==131){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
  }else if(listCandidatosIndi[i]['idCandidato']==132){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
  }else if(listCandidatosIndi[i]['idCandidato']==136){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
  }else if(listCandidatosIndi[i]['idCandidato']==137){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
  }   
  else{
    imgAvatar = "/static/illustrations/candidato/default3.png";
  }


  candidatos.push(
    <>
      <Card>
    <CardContent style={{paddingBottom:"10px",padding:"10px",marginTop:"25px"}}>
    <Grid container spacing={2}>    
             <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
                 <Box sx={{ mb: 0 }}>

                <div className='check-votacao'>
                 <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Checkbox 
                  value={listCandidatosIndi[i]['idCandidato']}
                  disabled={isDisabled(listCandidatosIndi[i]['idCandidato'])}
                  style={{alignItems:"center"}}
                  > 
                  
                    <b style={{display:"flex", alignItems:"center"}}> 
                    <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                    &nbsp;&nbsp;
                     <Avatar 
                      alt={listCandidatosIndi[i]['candidato']}
                      //src="/static/illustrations/candidato/default3.png"
                      src={imgAvatar}
                      sx={{ width: 56, height:56 }}
                      style={{marginLeft: "-1px"}} 
                      />
                      &nbsp;&nbsp;&nbsp;{listCandidatosIndi[i]['candidato']}     
                    </Typography>
                    </b>

                  </Checkbox>
               </Form.Group>        
               </div>
                   </Box>
             </Grid>    
          </Grid>
     </CardContent>   
    
     </Card>
     <br />
     </>
    
                 
    )

}





//se tiver opcao de voto em branco 
if(dadosDoItem[0]["voto_branco"] === "SIM"){ 

  candidatos.push(
    <>
    <Card>
    <CardContent style={{paddingBottom:"10px",padding:"10px",marginTop:"45px"}}>
    <Grid container spacing={2}>    
          <Grid item xs={12} style={{fontSize:"13px",paddingTop:"0px"}}>
            <Box sx={{ mb: 0 }}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Checkbox 
                value={'0'}
                disabled={isDisabled('0')}
                //style={{alignItems:"center"}}
                > 
              
                  <b style={{display:"flex", alignItems:"center"}}> 
                  <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"contents", alignItems:"center"}}>
                    &nbsp;&nbsp;&nbsp;
                    Votar em branco
                  </Typography>
                  </b> <br />

                </Checkbox>
              </Form.Group>        
            </Box>
          </Grid>    
    </Grid>
    </CardContent>   
    </Card>
     <br />
     </>           
  )



}





  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<




const LoginSchema = Yup.object({

  codigovotacao: Yup
  .number()
  .required("Campo Obrigatório")
  .test("correto", 'Código incorreto', function(value) {
  return value === dadosDoItem[0]['codigo'];
  })

});


const formik = useFormik({
initialValues: {
  codigovotacao: ''
},

validationSchema: LoginSchema,
onSubmit: async () => {

  let opcaoproposta = '';

      if(value !== 0){
       opcaoproposta = 1;
      }else{
        opcaoproposta = 0;
      }


let idCandidato = checked;




axios.get(functions.votacao.VERIFICAR_VOTACAO+"/"+IdItem+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
.then((retorno) => {


 if(retorno.data.result.recordset[0]['tempoVotacao'] >= 0){
       if(retorno.data.result.recordset[0]['voto'] === "NÃO"){
               
              axios.post(functions.votacao.INSERIR_VOTACAO_CHAPA, 
                {
                  idItempauta: IdItem,
                  idChapa: value,
                  opcaoproposta: opcaoproposta,
                  cpfcnpj: cpfcnpj
                },
                { headers: {"Authorization" : `${token}`} })
              .then((res) => {

                axios.post(functions.votacao.INSERIR_VOTACAO_CANDIDATO, 
                  {
                    idItempauta: IdItem,
                    idCandidato: idCandidato,
                    cpfcnpj: cpfcnpj
                  },
                  { headers: {"Authorization" : `${token}`} })
                .then((res) => {
                    axios.post(functions.voto.INSERIR_VOTO, {
                      idItemPauta: IdItem,
                      idUsuario: IdUser,
                      cpfcnpj: cpfcnpj
                      },
                      { headers: {"Authorization" : `${token}`} })
                    .then((response) => {
                      toast.success('Votação realizada com sucesso!');
                      history.replace('/room');
                      });

                });

              });
       
       }else{
       toast.error('Voto já realizado!');
       history.replace('/room');
       }

 }else{
   toast.error('Votação finalizada!');
   history.replace('/room');
 }


});



}
});



const { errors, touched, //values, 
  isSubmitting, handleSubmit, getFieldProps } = formik;




  //>>>>>>>>>>>>><<<<<<<<<<<<<<>>>>>>>>>><<<<<<<<<>>>>

  return(
    <>

<div className='titulo-votacao'>
  <h2>{TitleVote}</h2>
</div>

<Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished 
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        
        <React.Fragment>
         
          <Typography sx={{ mt: 2, mb: 1 }}>
            {(activeStep + 1 === 1 && 

            <>
             {
    (
     ( dadosDoItem[0]["voto_branco"] === "SIM" && 

     <Stack sx={{ mb: 3 }}>
     <Typography variant="h4" gutterBottom>
       Selecione a chapa eleitoral ou se preferir pode votar em branco.
     </Typography>
     <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
   </Stack>
     
     )
    ||
      ( 
        <Stack sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Selecione a chapa eleitoral.
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
      </Stack>
      )
    )
    }       

<Form onSubmit={handleSubmit2}>
  <fieldset>
    <Form.Group as={Row} className="mb-12">
      <Col sm={12} style={{flex:"0 0 100%",maxWidth:"100%"}} >

        {chapas}
        
      </Col>
    </Form.Group> 
    <FormHelperText style={{color:"red"}}>{helperText}</FormHelperText>
  </fieldset>


 

</Form>




            </>
             
           
             
)

||
  
(activeStep + 1 === 2 && 

  <>


{
  (
     ( dadosDoItem[0]["voto_branco"] === "SIM" && 
          <Stack sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Escolha {((dadosDoItem[0]["quantidadeExataCandidatos"] === 'SIM' && 'exatamente ') ||
                        (dadosDoItem[0]["countVotosMin"] === null && 'até ') ||
                        'entre '
                      )}

                      {((dadosDoItem[0]["countVotosMin"] === null && dadosDoItem[0]["quantidade_candidatos"]) ||
                        (dadosDoItem[0]["countVotosMin"] +' e '+ dadosDoItem[0]["quantidade_candidatos"] )
                      )}

                      {((dadosDoItem[0]["quantidade_candidatos"] > 1 && ' candidatos') || ' candidato')} ou se preferir pode votar em branco
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
          </Stack>        
     )

    ||

    ( 
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
          Escolha {((dadosDoItem[0]["quantidadeExataCandidatos"] === 'SIM' && 'exatamente ') ||
                        (dadosDoItem[0]["countVotosMin"] === null && 'até ') ||
                        'entre '
                      )}

                      {((dadosDoItem[0]["countVotosMin"] === null && dadosDoItem[0]["quantidade_candidatos"]) ||
                        (dadosDoItem[0]["countVotosMin"] +' e '+ dadosDoItem[0]["quantidade_candidatos"] )
                      )}

                      {((dadosDoItem[0]["quantidade_candidatos"] > 1 && ' candidatos') || ' candidato')}
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}></Typography>
        </Stack>
    )
  )
}  


<Checkbox.Group style={{ width: "100%" }} onChange={onChange}>

{candidatos}

</Checkbox.Group>

<FormHelperText style={{color:"red"}}>{helperText2}</FormHelperText>


  </>


)
             
}

          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>

          {(activeStep === 0 &&

            <Button 
              color="secondary"
              variant="outlined"
              sx={{ mr: 1 }}
              to="room"
              component={RouterLink}
            >
              Cancelar
            </Button>
            )
            ||
            (
            <Button
              color="secondary"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Anterior
            </Button>
            )
          }



            <Box sx={{ flex: "1 1 auto" }} />

            {(activeStep === steps.length - 1 && 
              <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="contained" disabled={error2} onClick={handleShow}>
              Votar
              </Button>
            )
            ||
            (<Button onClick={handleNext} variant="contained" disabled={error}>
            Próximo
             </Button>
            )
           }
           

          </Box>
        </React.Fragment>
      )}
    </Box>



<div className='modal-vote-election'>


<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        
      >

  <FormikProvider  value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header className='titulo-votacao-md' style={{justifyContent: 'center'}} >
        <Modal.Title>Código de votação tese</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>



        <Stack spacing={2}>

<label>
Para confirmar seu voto, digite o código <u style={{color:"green",fontSize:"20px"}}>{dadosDoItem[0]['codigo']} </u> no campo abaixo
</label>
<br />



        <TextField 
        id="codigovotacao" 
        variant="standard"
        label="Código*"
        type="number"
        inputComponent={TextMaskCustom}
        {...getFieldProps('codigovotacao')}
        error={Boolean(touched.codigovotacao && errors.codigovotacao)}
        helperText={touched.codigovotacao && errors.codigovotacao}
        style={{width: "30%"}}
        />
        <br />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      <br/>
      <br />
      Ao confirmar o código, seu voto será computado.
    </label>
        
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>
 
</div>

    </>
  )


}