import rotas from "../constants/serverRoutes"


const assembleia =  {    
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE:  `${rotas.ASSEMBLEIA}/buscaPorEntidadeParticipante`,
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE_ADMIN:  `${rotas.ASSEMBLEIA}/buscaPorEntidadeParticipanteAdmin`,
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE_ID:  `${rotas.ASSEMBLEIA}/buscaReuniaoPorEntidadeId`,

    INSERIR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/insereAssembleia`,
    ATUALIZAR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/atualizaAssembleia`,
    INICIAR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/iniciaAssembleia`,
    ENCERRAR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/encerraAssembleia`,
    ARQUIVAR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/arquivaAssembleia`,
    DESARQUIVAR_ASSEMBLEIA:  `${rotas.ASSEMBLEIA}/desarquivaAssembleia`,
    EXCLUIR_POR_ID:  `${rotas.ASSEMBLEIA}/excluiPorId`,
    //BUSCAR_ASSEMBLEIA_POR_ENTIDADE:  `${rotas.ASSEMBLEIA}/buscaPorEntidade`,
    BUSCAR_LISTA_ASSEMBLEIAS:  `${rotas.ASSEMBLEIA}/buscarListaAssembleia`,

    //novas rotas limpas - cooperado 
    BUSCAR_ASSEMBLEIA_ENTIDADE_COOP:  `${rotas.ASSEMBLEIA}/buscaReunioesPorEntidadeCoop`,
    BUSCAR_ASSEMBLEIA_ENTIDADE_IDASSEMBLEIA_COOP:  `${rotas.ASSEMBLEIA}/buscaReuniaoPorEntidadePorIdAssembleiaCoop`,

    //novas rotas limpas - Administrador
    BUSCAR_LISTA_ASSEMBLEIA_POR_ENTIDADE_ADM:  `${rotas.ASSEMBLEIA}/buscaListaPorEntidadeADM`,
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_BOTAO_ADM:  `${rotas.ASSEMBLEIA}/buscaReuniaoPorEntidadePorIdAssembleiaBotaoADM`,
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_ADM:  `${rotas.ASSEMBLEIA}/buscaReuniaoPorEntidadePorIdAssembleiaADM`, 
    BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_CHECKIN_ADM:  `${rotas.ASSEMBLEIA}/buscaReuniaoPorEntidadePorIdAssembleiaCheckinADM`,

    
};

const candidato =  {    
    BUSCAR_POR_ID:  `${rotas.CANDIDATOS}/buscaPorId`,
    INSERIR_CANDIDATO_CHAPA:  `${rotas.CANDIDATOS}/InsereCandidatoChapa`,
    INSERIR_CANDIDATO_INDIVIDUAL:  `${rotas.CANDIDATOS}/InsereCandidatoIndividual`,
    ATUALIZA_CANDIDATO:  `${rotas.CANDIDATOS}/atualizaCandidato`,
    EXCLUIR_POR_ID:  `${rotas.CANDIDATOS}/excluiPorId`,
    BUSCAR_LISTA_CANDIDATOS_CHAPA_POR_ITEM:  `${rotas.CANDIDATOS}/buscaListaCandidatosChapaPorItem`,
    BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_ITEM:  `${rotas.CANDIDATOS}/buscaListaCandidatosPorItem`,
    BUSCAR_LISTA_CANDIDATOS:  `${rotas.CANDIDATOS}/buscarListaCandidato`,


    //novas rotas limpas - cooperado 
    BUSCAR_LISTA_CANDIDATOS_POR_CHAPA_POR_IDITEM_COOP:  `${rotas.CANDIDATOS}/buscaListaCandidatosPorChapaPorIdItemCoop`,
    BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_IDITEM_COOP:  `${rotas.CANDIDATOS}/buscaListaCandidatosIndividualPorIdItemCoop`,
    BUSCAR_LISTA_CANDIDATOS_POR_CHAPA_POR_IDITEM_VOTACAO_COOP:  `${rotas.CANDIDATOS}/buscaListaCandidatosPorChapaPorIdItemVotacaoCoop`,
    BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_IDITEM_VOTACAO_COOP:  `${rotas.CANDIDATOS}/buscaListaCandidatosIndividualPorIdItemVotacaoCoop`,
    

};

const chapaProposta =  {    
    BUSCAR_POR_ID:  `${rotas.CHAPAS_PROPOSTAS}/buscaPorId`,
    INSERIR_CHAPA_PROPOSTA:  `${rotas.CHAPAS_PROPOSTAS}/insereChapaProposta`,
    INSERIR_NOVA_CHAPA_PROPOSTA:  `${rotas.CHAPAS_PROPOSTAS}/insereChapaPropostaNova`,
    ATUALIZAR_CHAPA_PROPOSTA:  `${rotas.CHAPAS_PROPOSTAS}/atualizaChapaProposta`,
    EXCLUIR_POR_ID:  `${rotas.CHAPAS_PROPOSTAS}/excluiPorId`,
    BUSCAR_LISTA_CHAPAS_PROPOSTAS_POR_IDITEM:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasPropostasPorIdItem`,
    BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasEleicaoPorIdItem`,
    BUSCAR_LISTA_CHAPAS_PROPOSTAS:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasPropostas`,


    //novas rotas limpas - cooperado 
    BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_COOP: `${rotas.CHAPAS_PROPOSTAS}/buscaListaPropostasPorIdItemCoop`,
    BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM_COOP:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasEleicaoPorIdItemCoop`,
    BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_VOTACAOPROPOSTA_COOP: `${rotas.CHAPAS_PROPOSTAS}/buscaListaPropostasPorIdItemVotacaoCoop`,
    BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM_VOTACAO_COOP:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasEleicaoPorIdItemVotacaoCoop`,
    BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM_RESULTVOTACAO_COOP:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaChapasEleicaoPorIdItemResultVotacaoCoop`,

    //novas rotas limpas - adm
    BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_ADM:  `${rotas.CHAPAS_PROPOSTAS}/buscaListaPropostasPorIdItemADM`,
    BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_RESULT_ADM: `${rotas.CHAPAS_PROPOSTAS}/buscaListaPropostasPorIdItemResultADM`,
};

const configuracaoEleicao =  {    
    BUSCAR_POR_ID:  `${rotas.CONFIGURACAO_ELEICAO}/buscaPorId`,
    INSERIR_CONFIGURACAO_ELEICAO:  `${rotas.CONFIGURACAO_ELEICAO}/insereConfiguracaoEleicao`,
    ATUALIZA_CONFIGURACAO_ELEICAO:  `${rotas.CONFIGURACAO_ELEICAO}/atualizaConfiguracaoEleicao`,
    EXCLUIR_POR_ID:  `${rotas.CONFIGURACAO_ELEICAO}/excluiPorId`,
    BUSCAR_POR_ENTIDADE:  `${rotas.CONFIGURACAO_ELEICAO}/buscaPorEntidade`,
    BUSCAR_LISTA_CONFIGURACAO_ELEICOES:  `${rotas.CONFIGURACAO_ELEICAO}/buscaListaConfiguracaoEleicao`
};

const entidade =  {    
    BUSCAR_POR_ID:  `${rotas.ENTIDADE}/buscaPorId`,
    INSERIR_ENTIDADE:  `${rotas.ENTIDADE}/insereEntidade`,
    ATUALIZAR_ENTIDADE:  `${rotas.ENTIDADE}/atualizaEntidade`,
    EXCLUIR_POR_ID:  `${rotas.ENTIDADE}/excluiPorId`,
    BUSCAR_LISTA_ENTIDADES:  `${rotas.ENTIDADE}/buscaListaEntidade`
};

const entidadeUsuario =  {    
    BUSCAR_POR_ID:  `${rotas.ENTIDADE_USUARIO}/buscaPorId`,
    INSERIR_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/insereEntidadeUsuario`,
    INSERIR_BLOQUEIO_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/insereBloqueioEntidadeUsuario`,
    REMOVER_BLOQUEIO_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/removeBloqueioEntidadeUsuario`,
    INSERIR_BLOQUEIO_TOTAL_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/insereBloqueioTotalEntidadeUsuario`,
    REMOVER_BLOQUEIO_TOTAL_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/removeBloqueioTotalEntidadeUsuario`,
    ATUALIZAR_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/atualizaEntidadeUsuario`,    
    EXCLUIR_POR_ID:  `${rotas.ENTIDADE_USUARIO}/excluiPorId`,
    BUSCAR_POR_ID_USUARIO:  `${rotas.ENTIDADE_USUARIO}/buscaPorIdUsuario`,
    BUSCAR_POR_ENTIDADE:  `${rotas.ENTIDADE_USUARIO}/buscaPorEntidade`,
    BUSCAR_LISTA_BLOCK_POR_ENTIDADE:  `${rotas.ENTIDADE_USUARIO}/buscaListaBlockPorEntidade`,
    BUSCAR_LISTA_BLOCK_POR_USUARIO:  `${rotas.ENTIDADE_USUARIO}/buscaListaBlockPorUsuario`,
    BUSCAR_LISTA_ENTIDADE_USUARIO:  `${rotas.ENTIDADE_USUARIO}/buscaListaEntidadeUsuario`,

    BUSCAR_POR_ID_USUARIO_PAINEL:  `${rotas.ENTIDADE_USUARIO}/buscaPorIdUsuarioPainel`,
    
};

const itemPauta =  {    
    BUSCAR_POR_ID:  `${rotas.ITENS_PAUTA}/buscaPorId`,
    INSERIR_ITENS_PAUTA:  `${rotas.ITENS_PAUTA}/insereItemPauta`,
    ATUALIZAR_ITENS_PAUTA:  `${rotas.ITENS_PAUTA}/atualizaItemPauta`,
    ENCERRAR_ITENS_PAUTA:  `${rotas.ITENS_PAUTA}/encerraItensPauta`,
    EXCLUIR_POR_ID:  `${rotas.ITENS_PAUTA}/excluiPorId`,
    BUSCAR_LISTA_ITENS_PAUTA:  `${rotas.ITENS_PAUTA}/buscaListaItensPauta`,
    BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO:  `${rotas.ITENS_PAUTA}/buscaListaItensPautaPorAssembleia`,
    BUSCAR_LISTA_ITENS_PAUTA_USER_POR_REUNIAO:  `${rotas.ITENS_PAUTA}/buscaListaItensPautaUserPorAssembleia`,
    BUSCA_LISTA_ITENS_PAUTA_POR_REUNIAO:  `${rotas.ITENS_PAUTA}/buscarListaItensPautaPorAssembleia`,
    BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID:  `${rotas.ITENS_PAUTA}/buscaItemPautaAssembleiaPorId`,

    INSERIR_ITENS_PROPOSTA_PAUTA:  `${rotas.ITENS_PAUTA}/insereItemPautaProposta`,

     //novas rotas limpas - cooperado 
     BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO_COOP:  `${rotas.ITENS_PAUTA}/buscaListaItensPautaPorAssembleiaCoop`,
     BUSCAR_ITEM_PAUTA_POR_REUNIAO_COOP:  `${rotas.ITENS_PAUTA}/buscaItemPautaPorIdPorAssembleiaCoop`,
     BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID_VOTACAOPROPOSTA_COOP:  `${rotas.ITENS_PAUTA}/buscaItemPautaAssembleiaPorIdVotacaoPropostaCoop`,
     BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID_VOTACAOELEICAO_COOP:  `${rotas.ITENS_PAUTA}/buscaItemPautaAssembleiaPorIdVotacaoEleicaoCoop`,
     BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID_RESULTVOTACAO_COOP:  `${rotas.ITENS_PAUTA}/buscaItemPautaAssembleiaPorIdResultVotacaoCoop`,
     
     //novas rotas limpas - ADM
     BUSCAR_LISTA_ITENS_PAUTA_POR_IDREUNIAO_ADM:  `${rotas.ITENS_PAUTA}/buscaListaItensPautaPorIdAssembleiaADM`,
     BUSCAR_ITEM_PROP_PAUTA_REUNIAO_POR_IDITEM_ADM:  `${rotas.ITENS_PAUTA}/buscaItemPropPautaAssembleiaPorIdItemADM`,
     BUSCAR_ITEM_ELECTION_PAUTA_REUNIAO_POR_IDITEM_ADM:  `${rotas.ITENS_PAUTA}/buscaItemElectionPautaAssembleiaPorIdItemADM`,
    

};

const presenca =  {    
    BUSCAR_POR_ID:  `${rotas.PRESENCA}/buscaPorId`,
    INSERIR_PRESENCA:  `${rotas.PRESENCA}/inserePresenca`,
    INSERIR_PRESENCA_ASSEMBLEIA: `${rotas.PRESENCA}/inserePresencaAssembleia`,
    INSERIR_ACESSO: `${rotas.PRESENCA}/insereLogAcesso`,
    EXCLUIR_POR_ID:  `${rotas.PRESENCA}/excluiPorId`,
    BUSCAR_LISTA_PRESENCAS:  `${rotas.PRESENCA}/buscaListaPresencas`,
    //BUSCAR_LISTA_PRESENCAS_POR_REUNIAO:  `${rotas.PRESENCA}/buscaListaPresencasPorReuniao`
    BUSCAR_LISTA_PRESENCA_POR_REUNIAO:  `${rotas.PRESENCA}/buscaListaPresencaPorReuniao`
};

const usuario =  {
    LOGIN:  `${rotas.USUARIOS}/login`,    
    BUSCAR_POR_ID:  `${rotas.USUARIOS}/buscaPorId`,
    BUSCAR_POR_CPF:  `${rotas.USUARIOS}/buscaPorCPF`,
    BUSCAR_POR_CPF_EMAIL:  `${rotas.USUARIOS}/buscaPorCPFAndEmail`,
    VERIFICAR_PERFIL:  `${rotas.USUARIOS}/verificarPerfil`,
    INSERIR_USUARIO:  `${rotas.USUARIOS}/insereUsuario`,
    ATUALIZAR_USUARIO:  `${rotas.USUARIOS}/atualizaUsuario`,
    ALTERAR_SENHA_USUARIO:  `${rotas.USUARIOS}/alteraSenhaUsuario`,
    EXCLUIR_POR_ID:  `${rotas.USUARIOS}/excluiPorId`,
    BUSCAR_LISTA_USUARIOS:  `${rotas.USUARIOS}/buscaListaUsuarios`
};

const voto =  {    
    BUSCAR_POR_ID:  `${rotas.VOTO}/buscaPorId`,
    INSERIR_VOTO:  `${rotas.VOTO}/insereVoto`,
    ATUALIZAR_VOTO:  `${rotas.VOTO}/atualizaVoto`,
    EXCLUIR_POR_ID:  `${rotas.VOTO}/excluiPorId`,
    BUSCAR_LISTA_VOTOS_POR_ITEM: `${rotas.VOTO}/buscaListaVotosPorItem`,
    BUSCAR_LISTA_VOTOS:  `${rotas.VOTO}/buscaListaVotos`
};

const votacao =  {    
    BUSCAR_POR_ID:  `${rotas.VOTACAO}/buscaPorId`,
    INICIAR_VOTACAO: `${rotas.VOTACAO}/iniciaVotacao`,
    ENCERRAR_VOTACAO: `${rotas.VOTACAO}/encerraVotacao`,
    BUSCAR_DADOS_VOTACAO: `${rotas.VOTACAO}/buscaDadosVotacaoPorId`,
    INSERIR_VOTACAO_CANDIDATO:  `${rotas.VOTACAO}/insereVotacaoCandidato`,
    INSERIR_VOTACAO_CHAPA:  `${rotas.VOTACAO}/insereVotacaoChapa`,
    INSERIR_VOTACAO_PROPOSTA:  `${rotas.VOTACAO}/insereVotacaoProposta`,
    INSERIR_VOTACAO_PROPOSTA_UNI:  `${rotas.VOTACAO}/insereVotacaoPropostaUni`,
    EXCLUIR_POR_ID:  `${rotas.VOTACAO}/excluiPorId`,
    VERIFICAR_VOTACAO:  `${rotas.VOTACAO}/verificarVotacaoPorId`,
    BUSCAR_LISTA_VOTACOES:  `${rotas.VOTACAO}/buscaListaVotacoes`,
    BUSCAR_LISTA_VOTACOES_POR_REUNIAO:  `${rotas.VOTACAO}/buscarListaPresencasPorReuniao`,
   
};



const exportedRouters = {assembleia, candidato, presenca, chapaProposta, 
                configuracaoEleicao, entidade, entidadeUsuario, 
                itemPauta, usuario, voto, votacao};


                export default exportedRouters;
