import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  CardContent,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import { Icon } from '@iconify/react';
import trash2 from '@iconify/icons-eva/trash-2-outline';
import alertcircleoutline from '@iconify/icons-eva/alert-circle-outline';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect, useRef, useContext} from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItem, getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";

import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';


import { 
  Form, 
  Input, 
  InputNumber,
   Modal as Modal2, 
   Typography as Typography2
} from 'antd';
import 'antd/dist/antd.css';
import './index.css';
import { Radio } from 'antd';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));

// ----------------------------------------------------------------------


CreateProposta.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};



const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};





function validatePrimeNumber(number) {
  if (number === 5) {
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  return {
    validateStatus: 'error',
    errorMsg: 'The prime between 8 and 12 is 11!',
  };
}

function validatePrimeNumber1(number) {}

function validatePrimeNumber2(number) {}

function validatePrimeNumber3(number) {}






//Modal Criar nova proposta

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef(); //console.log(form)
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible,prevVisible,form]);
};

const ModalForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();

  };










  const [number, setNumber] = useState({
    value: 1,
  });
  const [number1, setNumber1] = useState({
    value: 1,
  });
  const [number2, setNumber2] = useState({
    value: 1,
  });
  const [number3, setNumber3] = useState({
    value: number2.value+1,
  });


  




  const onNumberChange = (value) => {
    setNumber({ ...validatePrimeNumber(value), value });
   addItem('valorExatoCand', value);
  };
  const onNumberChange1 = (value) => {
    setNumber1({ ...validatePrimeNumber1(value), value });
    addItem('valorAteCand', value);
  };
  const onNumberChange2 = (value) => {
    setNumber2({ ...validatePrimeNumber2(value), value });
    addItem('valorEntreCand1', value);
  };
  const onNumberChange3 = (value) => {
    setNumber3({ ...validatePrimeNumber3(value), value });
    addItem('valorEntreCand2', value);
  };
  



  const [Individual, setIndividual] = React.useState(true);
  const [IndividualCand, setIndividualCand] = React.useState(false);

  const handleRadioChangeInd = (event) => {
   if(event.target.value === "1"){
    setIndividual(true);
    setIndividualCand(false);

   }else{
    setIndividual(false);
    setIndividualCand(true);

   }
  }




  const [Exato, setExato] = React.useState(true);
  const [Ate, setAte] = React.useState(true);
  const [Entre, setEntre] = React.useState(true);

  const handleRadioChange = (event) => {

    if(event.target.value === "1"){
      addItem('valorExatoCand', 1);
      setAte(true);
      setEntre(true);
      setExato(false);
    }if(event.target.value === "2"){
      addItem('valorAteCand', 1);
      setExato(true);
      setEntre(true);
      setAte(false);
    }if(event.target.value === "3"){
      addItem('valorEntreCand1', 1);
      addItem('valorEntreCand2', 2);
      setExato(true);
      setAte(true);
      setEntre(false);
    }

  };

  


  return (
    <Modal2 title="Criar Configuração" className='btn-modal' visible={visible} onOk={onOk} okText="Confirmar" cancelText="Cancelar" onCancel={onCancel} style={{ zIndex: 1500, top: "20px"}}>
      <Form form={form} layout="vertical" name="userForm">
        <Form.Item
          name="tipo"
          label="Tipo de Eleição"
          rules={[
            {
              required: true,
              message: 'Favor selecionar o tipo da eleição',
            },
          ]}
        >

          <Radio.Group onChange={handleRadioChangeInd}>
          <Radio value="1">Chapa Eleitoral</Radio>
          <Radio value="2">Candidato individual</Radio>
          <Radio value="3">Ambos</Radio>
        </Radio.Group>

        </Form.Item>

        <Form.Item
          name="quant"
          label="Quantos candidatos individuais podem ser votados?"
          rules={[
            {
              required: IndividualCand,
              message: 'Favor selecionar uma opção acima',
            },
          ]}
          tooltip="Informe a quantidade de candidatos que o participante poderá selecionar quando estiver realizando seu voto."
        >

          <Radio.Group onChange={handleRadioChange} disabled={Individual}>
          <Radio value="1" style={{lineHeight:"3"}}>Exatamente 
          <InputNumber name="exato" min={1} max={20} value={number.value} onChange={onNumberChange} disabled={Exato} />candidato.</Radio><br />
          <Radio value="2" style={{lineHeight:"3"}}>Até  <InputNumber name="ate" min={1} max={20} value={number1.value} onChange={onNumberChange1} disabled={Ate} />  {/*<input type="number" pattern="[0-9]*" variant="standard" />*/} candidato.</Radio><br />
          <Radio value="3" style={{lineHeight:"3"}}>Entre  <InputNumber name="entre" min={1} max={number3.value-1} value={number2.value} onChange={onNumberChange2} disabled={Entre} /> e  <InputNumber min={number2.value+1} max={20} value={number3.value} onChange={onNumberChange3} disabled={Entre} /> {/*<input type="number" pattern="[0-9]*" variant="standard" /> e <input type="number" pattern="[0-9]*" variant="standard" />*/} candidatos.</Radio>
        </Radio.Group>

        </Form.Item>

       
        <Form.Item
          name="votobranco"
          label="Voto em Branco"
          rules={[
            {
              required: true,
              message: 'Favor selecionar uma opção acima',
            },
          ]}
          tooltip="Selecione a opção caso deseje que sua eleição tenha a opção de voto em branco."
        >
        
          <Radio.Group>
          <Radio value="1">Disponibilizar</Radio>
          <Radio value="0">Não disponibilizar</Radio>
        </Radio.Group>

        </Form.Item>



      </Form>
    </Modal2>
  );
};








export default function CreateProposta() {

  const history = useHistory();

  const token = getItem('token-assembleia-api');
  const Identificador = getItem('userName');
  const IdEntidade = getItem('institutionId'); 
  const IdReuniao = getItem('IdRoom');

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [visible, setVisible] = useState(false);
  const [Userss, setUsers] = useState([]);
  const [Config1, setConfig1] = useState();
  const [Config2, setConfig2] = useState();
  const [Config3, setConfig3] = useState();
  const { setIsAuthenticated } = useContext(Auth);

  const showUserModal = () => {
    setVisible(true);
  };

  const hideUserModal = () => {
    setVisible(false);
  };

  const onFinish = (values) => {

   const tipo = Config1;
   const tipoQuant = Config2;
   const votoBranco = Config3;
   let quantMinCandi = null;
   let quantvotosCandi = null;
   let quantvotosCandiEx = null;


   const contvotosEx = getItem('valorExatoCand');
   const contvotosAt = getItem('valorAteCand');
   const contvotosEn1 = getItem('valorEntreCand1');
   const contvotosEn2 = getItem('valorEntreCand2');

   



   if(tipo > 1){
     if(tipoQuant === "1"){
      quantvotosCandiEx = 1;
      quantvotosCandi = contvotosEx;
     }else if(tipoQuant === "2"){
      quantvotosCandi = contvotosAt;
      quantvotosCandiEx = 0;
     }else if(tipoQuant === "3"){
      quantMinCandi = contvotosEn1;
      quantvotosCandi = contvotosEn2;
      quantvotosCandiEx = 0;
     }

   }


   axios.post(functions.configuracaoEleicao.INSERIR_CONFIGURACAO_ELEICAO, 
      {
        identificador: Identificador,
        fk_entidade: IdEntidade,
        fk_tipo_eleicao: tipo,
        quantidade_minima_candidatos: quantMinCandi,
        quantidade_candidatos: quantvotosCandi,
        quantidade_exata_candidatos: quantvotosCandiEx,
        voto_branco: votoBranco
      },
      { headers: {"Authorization" : `${token}`} })
   .then((res) => {
    if(res.data.result === "Token expirado"){
      logout();
      setIsAuthenticated(false);
      toast.warn('Sessão expirada!');
    }else{
        axios.post(functions.itemPauta.INSERIR_ITENS_PAUTA, 
            {
            titulo: values.titulo,
            idAssembleia: IdReuniao,
            idTipoItemPauta: 1,
            idConfiguracao: res.data.records
            },
            { headers: {"Authorization" : `${token}`} })
        .then((response) => {
         toast.success('Eleição criado com sucesso!');
         history.replace('/schedule');
          });
      }
      
    });






  };




  const [BotaoCriar, setBotaoCriar] = useState(false);



  const LoginSchema = Yup.object().shape({
    titulo: Yup.string().required('campo obrigatória'),
    
   });

  const formik = useFormik({
    initialValues: {
      id: '',
      titulo: ''
      
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {
  
    }
  });


  const { 
    handleSubmit, 
  } = formik;



  return(

    <RootStyle title="Create-Proposta | ASSEMBLEIACOOP">
       <AuthLayout>
            <Button
                className="btn btn-cinza" 
                component={RouterLink} 
                to="schedule"
                style={{textDecoration:"none",color:"#ffffff"}}
              >
                <Icon icon={cornerupleft}/>&nbsp; Voltar
            </Button>
       </AuthLayout>

      

      <Container maxWidth="sm" className='conteudo-geral'>
        <ContentStyle style={{maxWidth: '680px'}}>

        <Stack sx={{ mb: 3 }} className='titulo-geral' >
            <Typography variant="h4" gutterBottom>
            Crie uma configuração de eleição
            </Typography>
            <Typography variant='p'>
            Defina a forma que os participantes votarão na sua eleição.
            </Typography>
          </Stack>






          <Form.Provider
            onFormFinish={(name, { values, forms }) => {
                if (name === 'userForm') {
                const { basicForm } = forms;
                const users = basicForm.getFieldValue('users') || [];
                basicForm.setFieldsValue({
                  users: [...users, values],
                });
                setVisible(false);
                }
            }}
          >

            <Form name="basicForm" onFinish={onFinish}>
              <Form.Item {...tailLayout}>

                    <Button
                    variant="contained"
                    color="info"
                    style={{}}
                    onClick={showUserModal}
                    disabled={BotaoCriar}
                    >
                    Configurar Eleição
                    </Button>
                   

              </Form.Item>

            <Form.Item
              name="titulo"
              label="Titulo da Eleição"
              rules={[
                {
                  required: true,
                  message: 'Favor informar o titulo da eleição',
                },
              ]}
            >
              <Input />
            </Form.Item>

          <Form.Item 
            shouldUpdate={(prevValues, curValues) => prevValues.users !== curValues.users}
          >

            {({ getFieldValue }) => {
                const users = getFieldValue('users') || [];
                setUsers(users.length)
              

              if(users.length > 0){
                  setConfig1(users[0]['tipo'])
                  setConfig2(users[0]['quant'])
                  setConfig3(users[0]['votobranco'])
              }

             


              function deleteTask(index) {
                  users.splice(index, 1);
                  handleClose3();
                  setBotaoCriar(false);
               }

               

               

              if(users.length > 0){ 

               setBotaoCriar(true);

               const quantvotosEx = getItem('valorExatoCand');
               const quantvotosAt = getItem('valorAteCand');
               const quantvotosEn1 = getItem('valorEntreCand1');
               const quantvotosEn2 = getItem('valorEntreCand2');
               
               

                    return(
                      
                          <ul>
                          {users.map((user, index) => (


<Grid item xs={12} md={4} lg={12} key={index}>
    <Card>
      <CardContent>
          <Grid container spacing={2}>
         
            <Grid item xs={9}>
                <Box className='' sx={{ mb: 0.5 }}>
                  {" Tipo: "}
                  <b> {(
                        (user.tipo === "1" && 'Chapa')
                        ||
                        (user.tipo === "2" && 'Individual')
                        ||
                        (user.tipo === "3" && 'Ambos')
                      )} </b>
                  </Box>

               
                  <Box sx={{ mb: 0.5 }}>
                 
                  <b> {
                    (user.tipo === "1" && <></>) 
                    
                    || 

                    (user.quant === "1" && 
                          (quantvotosEx > 1 && 'Exatamente '+ quantvotosEx +' candidatos individuais'
                          ||
                          'Exatamente '+ quantvotosEx +' candidato individual')
                    )
                    ||
                    
                    (user.quant === "2" && 
                          (quantvotosAt > 1 && 
                            'Até '+ quantvotosAt +' candidatos individuais'
                            ||
                            'Até '+ quantvotosAt +' candidato individual')
                    )

                    ||

                    (user.quant === "3" && 'Entre '+ quantvotosEn1 + ' e ' + quantvotosEn2 +' candidatos')

                       
                       } </b>
                  </Box>  
                  
                  

                  <Box sx={{ mb: 0.5 }}>
                  <b> {(
                        (user.votobranco === "1" && 'Com voto em branco')
                        ||
                        (user.votobranco === "0" && 'Sem voto em branco')
                      )} </b>
                  </Box>
   
            </Grid>

            <Grid item xs={1} style={{fontSize:"25px"}}></Grid>
       
           
            <Grid item xs={1} style={{fontSize:"25px"}}>
              <Tooltip title={'Excluir'}>   
                <IconButton>
                  <Icon icon={trash2} 
                  onClick={handleShow3}
                  />
                </IconButton>
              </Tooltip>  
            </Grid>





{/* Modal Excluir */}

<Modal visible style={{ zIndex: 1200}}
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >

    <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Excluir</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>


        <Stack spacing={3}>
        <div>
        <Icon icon={alertcircleoutline} width={70} height={70} color="red"/>
        </div>
        <br /> 
        <b>Essa ação não pode ser desfeita. Isso excluirá permanentemente seu recurso.</b>
        <br />
       
        </Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 className="btn btn-cinza" variant="secondary" onClick={handleClose3}>
            Voltar
          </Button2>
          <Button2 className="btn btn-cinza" variant="primary" 
          onClick={() => deleteTask(index)}
          >
            Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>





               
         </Grid>
      </CardContent>
   </Card>
<br />

</Grid>



      ))}
      </ul>)
      }else{
      return(
      <Typography2.Text className="ant-form-text" type="secondary">
      </Typography2.Text>
      )
      }



      }
      }


</Form.Item>




<Form.Item {...tailLayout}>

  <Button
  variant="secondary"
  component={RouterLink} 
  to="schedule"
  className='btn-cinza'
  onClick={showUserModal}
  >
   Cancelar 
  </Button>


  
  

        {(Userss > 0 &&  



<Button 
htmlType="submit" 
type="primary" 
variant="contained" 
>
Salvar
</Button>




)
||
( 


<Button
variant="contained"
disabled="true"
>
Salvar
</Button>


)

}






</Form.Item>
</Form>

<ModalForm visible={visible} onCancel={hideUserModal} />


</Form.Provider>




              </ContentStyle>
            </Container>
          </RootStyle>    

        
        )

  }