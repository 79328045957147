import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  Paper,
  CardContent,
  Button as Button1
} from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
//select
import React, { useState, useEffect, useContext} from "react";
import axios from "axios";
//lista
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//token
import { addItem, getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";
import MyTimer from "./VotationTimer";
import moment from 'moment-timezone';
import { Link as RouterLink} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Label from '../layouts/css/Label';
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));



// ----------------------------------------------------------------------




Login.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};




export default function Login() {

  const token = getItem('token-assembleia-api');
  const idVoteStart = getItem('IdVotationStart');
  const ThemeVoteStart = getItem('ThemeVoteStart');
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [VotationStart, setVotationStart] = useState([]);
  const [loading] = useState(false);
  const { setIsAuthenticated } = useContext(Auth);



//Consulta dados Votacao
useEffect(() => {

   axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.result === "Token expirado"){
      logout();
      setIsAuthenticated(false);
      toast.warn('Sessão expirada!');
    }else{
      if(res.data.records === 1){

        axios
        .get(functions.votacao.BUSCAR_DADOS_VOTACAO+"/"+idVoteStart, { headers: {"Authorization" : `${token}`} })
          .then((res) => {
         (res.data.records !== 0 && setVotationStart(res.data.result.recordset))
          })
          .catch((err) => {
          });

          }else{
            logout();
            setIsAuthenticated(false);
            toast.error('Token inválido!');
          }  
    }

   
  });


  const interval =  window.setInterval(() => {

    axios
    .get(functions.votacao.BUSCAR_DADOS_VOTACAO+"/"+idVoteStart, { headers: {"Authorization" : `${token}`} })
      .then((res) => {
     (res.data.records !== 0 && setVotationStart(res.data.result.recordset))
      })
      .catch((err) => {
      });

  }, 5000);

  return () => clearInterval(interval);

}, [IdEntidade,IdUser,idVoteStart,token,setIsAuthenticated]
);
  



  //mapeia a consulta
const LISTAPAUTA = VotationStart.map((Lista, index) => ({

  idItem: Lista.idItem,
  itemPauta: Lista.itemPauta,
  tipoItem: Lista.tipoItem,
  codigo: Lista.codigo,
  presentes: Lista.presentes,
  horarioInicial: Lista.horarioInicial,
  horarioFinal: Lista.horarioFinal,
  votantes: Lista.votantes,
  TimerVotacao: Lista.TimerVotacao

  }));


  



  if (loading) {
    return <p>carregando...</p>;
  }


  const closerwindow = () => {
    window.close(); 
  }

 


  return (
    <RootStyle title="Votação | ASSEMBLEIACOOP">
       <AuthLayout>
          <RouterLink onClick={closerwindow} style={{textDecoration:"none",color:"#000000"}}>  
            <Button variant="secondary" >Voltar</Button> 
          </RouterLink>
       </AuthLayout>
   


      <Container maxWidth="sm">
        <ContentStyle>
          <Stack>
  

<div >
{LISTAPAUTA.map((lista, id) => (
<PautaDetail key={id} {...lista} />  
))}
</div>

     



          </Stack>
   

        </ContentStyle>
      </Container>
      
    </RootStyle>
  );

  
}




const PautaDetail = (props) => {
  const { 
    idItem, 
    itemPauta,
    tipoItem, 
    codigo, 
    presentes,
    votantes,
    horarioFinal,
    TimerVotacao
  } = props;





/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';


  //const time1 = moment.utc(horarioInicial).format('DD/MM/YYYY HH:mm:ss');
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60)*60);
*/

  const time = new Date();
  //time.setSeconds(time.getSeconds() + fim); 

  time.setSeconds(time.getSeconds() + (TimerVotacao*60)); 


  
  const EncerrarVotacao = (IdItemPauta) => {

    const token = getItem('token-assembleia-api');

    addItem('IdVotationResult', IdItemPauta);
    addItem('ThemeVoteResult', tipoItem);

        axios.post(functions.votacao.ENCERRAR_VOTACAO, 
          {IdItemPauta: IdItemPauta},
          {headers: {"Authorization" : `${token}`} }
        )
      .then((response) => {
            toast.success('votação encerrada com sucesso!');
            window.open('https://assembleiacoop.coop.br/result_votation', '_blank');
            window.close(); 
      });
     
  
  } 



  return(

    <>

<Card>
     
     <CardContent>
       <Grid container spacing={2}>



<Grid item xs={12}>
<div className='total-status-topo'>
       <h2>{"Código da votação"}</h2>
       <h2>
       <Typography variant="h3" style={{fontFamily:"inherit",color:"#FFFFFF"}}>
       {codigo}
       </Typography> 
       </h2> 
       </div>    
   </Grid>


   <Grid item xs={4}>
   <div className='total-status-topo'>
       <h2>{"Tempo"}</h2>
       <h2>
       <Typography>
       <MyTimer expiryTimestamp={time} />
       </Typography> 
       </h2> 
       </div> 
   </Grid>


   <Grid item xs={4}>
  <div className='total-status-topo'>     
       <h2>{"Presentes"}</h2>
       <h2>
       <Typography>
       {presentes}
       </Typography> 
       </h2> 
  </div>
  </Grid>


  <Grid item xs={4}>
   <div className='total-status-topo'>     
       <h2>{"Total Votos"}</h2>
       <h2>
       <Typography>
       {votantes}
       </Typography> 
       </h2> 
  </div>
  </Grid>





         


   <Grid item xs={12}>
     <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
       <Box sx={{ mb: 0.5 }}>{tipoItem}{" em Votação:"}</Box>
       <Typography variant="h6" className = 'titulo-resultados'>{itemPauta}</Typography>
     </Paper>
   </Grid>

   

   <Stack
          alignItems="center"
          spacing={1}
          sx={{
            p: 2,
            pt: 3,
            borderRadius: 2,
            position: 'relative',
            paddingLeft: '100px'  
          }}
        >
         

         <Button1
            target="_blank"
            variant="contained"
            onClick={() => EncerrarVotacao(idItem)}
          >
            Finalizar votação e exibir resultado
          </Button1>
    

 </Stack>
   

       </Grid>
     </CardContent>
   </Card>

    </>

  );


}