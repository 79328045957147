import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  CardContent,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import { Icon } from '@iconify/react';
import trash2 from '@iconify/icons-eva/trash-2-outline';
import alertcircleoutline from '@iconify/icons-eva/alert-circle-outline';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect, useRef, useContext} from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';

import { 
  Form, 
  Input, 
   Modal as Modal2
} from 'antd';
import 'antd/dist/antd.css';
import './index.css';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { //useFormik, 
  FormikProvider } from 'formik';
//import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));


// ----------------------------------------------------------------------


CreateProposta.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};




//Modal Criar nova proposta
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef(); //console.log(form)
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible,prevVisible,form]);
};


const ModalForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();

  };





  return (
    <Modal2 title="Criar Proposta" className='btn-modal' visible={visible} onOk={onOk} okText="Confirmar" cancelText="Cancelar" onCancel={onCancel} style={{ zIndex: 1500, top: "20px"}}>
      <Form form={form} layout="vertical" name="userForm">

      <Form.Item
          name="nome"
          label="Proposto por"
          rules={[
            {
              required: true,
              message: 'Favor informar nome do proponente',
            },
          ]}
        >
          <Input placeholder="Nome do Proponente"/>
        </Form.Item>
        <Form.Item
          name="proposta"
          label="Proposta"
          rules={[
            {
              required: true,
              message: 'Favor informar Proposta',
            },
          ]}
        >
         
          <textarea
          class="form-control"
          rows="5"
          >
         </textarea>
        </Form.Item>
       
      </Form>
    </Modal2>
  );
};
  




export default function CreateProposta() {

  const history = useHistory();

  const token = getItem('token-assembleia-api');
  const IdReuniao = getItem('IdRoom');
  const [visible, setVisible] = useState(false);
  const [BotaoCriar] = useState(false);
  const [Userss, setUsers] = useState([]);
  const [Dadosprops, setDadosprops] = useState([]);
  const [IndexId, setIndexId] = useState();
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const { setIsAuthenticated } = useContext(Auth);


  const testeid = (index) => {
    setIndexId(index);
    handleShow3()
  };

  const showUserModal = () => {
    setVisible(true);
  };

  const hideUserModal = () => {
    setVisible(false);
  };

  const onFinish = (values) => {

 
 axios.post(functions.itemPauta.INSERIR_ITENS_PROPOSTA_PAUTA, 
          {
            titulo: values.titulo,
            idAssembleia: IdReuniao
          },
          { headers: {"Authorization" : `${token}`} })
      .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          toast.warn('Sessão expirada!');
        }else{
         axios.post(functions.chapaProposta.INSERIR_CHAPA_PROPOSTA, 
                  {
                  propostas: Dadosprops,
                  contpropostas: Dadosprops.length,
                  idItempauta: res.data.records
                  },
                  { headers: {"Authorization" : `${token}`} })
                .then((response) => {
                    toast.success('Proposta criada com sucesso!');
                    history.replace('/schedule');
                  });
          }
                
      });


  }  







 // const LoginSchema = Yup.object().shape({
 //   titulo: Yup.string().required('campo obrigatória'),
    
 //  });

 // const formik = useFormik({
  //  initialValues: {
 //     id: '',
 //     titulo: ''
      
      
 //   },
   
  //  validationSchema: LoginSchema,
  //  onSubmit: async () => {

  
//    }
 // });

  /*errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps */
    
// const {} = formik;


return(

    <RootStyle title="Create-Proposta | ASSEMBLEIACOOP">
       <AuthLayout>
            <Button
                className="btn-cinza" 
                component={RouterLink} 
                to="schedule"
              >
                 <Icon icon={cornerupleft}/>&nbsp; Voltar 
            </Button>
       </AuthLayout>

      
      <Container maxWidth="sm" className='conteudo-geral'>
        <ContentStyle style={{maxWidth: '680px'}}>

        <Stack className='titulo-geral' >
            <Typography  variant="h4" gutterBottom>
            Adicione uma ou mais propostas
            </Typography>
            <Typography variant='p'>
            Quando cadastrada apenas uma proposta, serão disponibilizas três opções de voto ao participante: 
            Concordo, Discordo e Abstenho. Os itens que possuirem mais de uma proposta, as terão como opções de voto, 
            além da opção Abstenho ao final.
            </Typography>
            <br />
          </Stack>




          <Form.Provider
            onFormFinish={(name, { values, forms }) => {
                if (name === 'userForm') {
                const { basicForm } = forms;
                const users = basicForm.getFieldValue('users') || [];
                basicForm.setFieldsValue({
                  users: [...users, values],
                });
                setVisible(false);
                }
            }}
          >

          
            <Form name="basicForm" onFinish={onFinish}>
            <div className=''>
              <Form.Item {...tailLayout}>

                    <Button
                    variant="contained"
                    color="info"
                    style={{}}
                    onClick={showUserModal}
                    disabled={BotaoCriar}
                    >
                    Nova Proposta
                    </Button>
                   
              </Form.Item>
              </div>

              <Form.Item
              

              name="titulo"
              label="Titulo da Proposta"
              rules={[
                {
                  required: true,
                  message: 'Favor informar o titulo da proposta',
                },
              ]}
              >
              <Input />
              </Form.Item>



              <Form.Item 
              shouldUpdate={(prevValues, curValues) => prevValues.users !== curValues.users}
              >

              {({ getFieldValue }) => {
                  const users = getFieldValue('users') || [];
                  setUsers(users.length)


                  function deleteTask(index) {
                    users.splice(index, 1);
                    handleClose3();
                 }


                  if(users.length > 0){ 
     
                    setDadosprops(users)

                         return(
                           
                               <ul>
                               {users.map((user, index) => (
                                
                              
     
     
    
     
     
     
     <Grid item xs={12} md={4} lg={12} key={index} >
         <Card>
           <CardContent>
               <Grid container spacing={2}>
              
                 <Grid item xs={9} style={{fontSize:"13px"}}>
                    <Box sx={{ mb: 0.5 }}>
                      {" Proposto por: "}
                      <b> {user.nome} </b>
                    </Box>
                    <Box sx={{ mb: 0.5 }}>
                    <b> {user.proposta} </b>
                    </Box>
                       
                 </Grid>
     
                 <Grid item xs={1} style={{fontSize:"25px"}}></Grid>
            
                
                 <Grid item xs={1} style={{fontSize:"25px"}}>
                   <Tooltip title={'Excluir'}>   
                     <IconButton>
                       <Icon icon={trash2} 
                       onClick={() => testeid(index)}
                        id={index}
                       />
                     </IconButton>
                   </Tooltip>  
                 </Grid>
     
     
     
     

     
     {/* Modal Excluir */}
     
     <Modal visible style={{ zIndex: 1200}}
             show={show3}
             onHide={handleClose3}
             backdrop="static"
             keyboard={false}
           >
     
         <FormikProvider value={"formik"}>
            <Form autoComplete="off" noValidate onSubmit={"handleSubmit"}>
     
            <Modal.Header style={{justifyContent: 'center'}} >
             <Modal.Title>Excluir</Modal.Title>
             </Modal.Header>
             <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>
     
     
             <Stack spacing={3}>
             <div>
             <Icon icon={alertcircleoutline} width={70} height={70} color="red"/>
             </div>
             <br /> 
             <b>Essa ação não pode ser desfeita. Isso excluirá permanentemente seu recurso.</b>
             <br />
            
             </Stack>
     
     
             </Modal.Body>
             <Modal.Footer>
               <Button2 className='btn-cinza' variant="secondary" onClick={handleClose3}>
               <Icon icon={cornerupleft}/>&nbsp; Voltar
               </Button2>
               <Button2 className="btn-danger" variant="primary" id={IndexId}
               onClick={() => deleteTask(IndexId)}
               >
                 Confirmar
              </Button2>
             </Modal.Footer>
     
         </Form>
       </FormikProvider>
     </Modal>
     
                    
              </Grid>
           </CardContent>
        </Card>
     <br />
     
     </Grid>
     
     
     
           ))}
           </ul>)
           }


              }}


              </Form.Item>




<Form.Item {...tailLayout}>

<Button className='btn-cinza'
variant="secondary"
component={RouterLink} 
to="schedule"
onClick={showUserModal}
>
Cancelar
</Button>





      {(Userss > 0 &&  



<Button 
htmlType="submit" 
type="primary" 
variant="contained" 
>
Salvar
</Button>




)
||
( 

<Button
variant="contained"
disabled="true"
>
Salvar
</Button>


)


}

</Form.Item>

              



            </Form>


            <ModalForm visible={visible} onCancel={hideUserModal} />

          </Form.Provider>  


              </ContentStyle>
            </Container>
          </RootStyle>    

        
)


  }