import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHome/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHome/DashboardSidebar';
import Page from '../components/Page';

import { 
  Grid, 
  Stack,
  Button as Button1,
  Container,
  Typography, 
  Box,
  Card,
  CardHeader,
  Divider,
  CardActionArea
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import checkmarkcircle2 from '@iconify/icons-eva/checkmark-circle-fill';
import clock from '@iconify/icons-eva/clock-fill';
import closecircle from '@iconify/icons-eva/close-circle-fill';
import checkmarksquare2 from '@iconify/icons-eva/checkmark-square-2-fill';
import "../layouts/css/room.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import functions from "../constants/serverFunctions";
import Scrollbar from '../components/Scrollbar';
import Label from '../layouts/css/Label';
import "react-widgets/styles.css";
import moment from 'moment-timezone';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


export default function PageRoom() {

  const token = getItem('token-assembleia-api');
  const IdRoom = getItem('IdRoom'); 
  const IdUser = getItem('userId');
  const institution = getItem('institutionName');
  const [ListPauta, setListPauta] = useState([]);
  const [DadosReuniao, setDadosReuniao] = useState([]);
  const [open, setOpen] = useState(false);
  const { setIsAuthenticated } = useContext(Auth);
  const history = useHistory();




    //Consulta dados da reuniao
useEffect(() => {
  

  console.clear();

  axios
  .get(functions.assembleia.BUSCAR_ASSEMBLEIA_ENTIDADE_IDASSEMBLEIA_COOP+"/"+institution+"/"+IdRoom, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          toast.warn('Sessão expirada!');
        }else{
          (res.data.records !== 0 && setDadosReuniao(res.data.result.recordset))
        }
    })
    .catch((err) => {
    });
}, [token,IdRoom,institution]);




//mapeia a dados da reuniao
const LISTADADOS = DadosReuniao.map((Dado, index) => ({
  ID_Assembleia: Dado.ID_Assembleia,
  Titulo_Assembleia: Dado.Titulo_Assembleia,
  Data_Assembleia: Dado.Data_Assembleia,
  Link_Assembleia: Dado.Link_Assembleia,
  Horario_PrimeiraChamada: Dado.Horario_PrimeiraChamada,
  Descricao_Assembleia: Dado.Descricao_Assembleia,
  ID_Entidade_Assembleia: Dado.ID_Entidade_Assembleia,
  Entidade_Assembleia: Dado.Entidade_Assembleia,
  TimerInicio: Dado.TimerInicio,
  TimerFim: Dado.TimerFim

}));


//Consulta Intens da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO_COOP+"/"+IdRoom+"/"+IdUser, { headers: {"Authorization" : ` ${token}`} })
  .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
      //  toast.warn('Sessão expirada!');
      }else{
            (res.data.records !== 0 && setListPauta(res.data.result.recordset))
      }
    })
    .catch((err) => {
    });

    const interval =  window.setInterval(() => {

      axios
      .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO_COOP+"/"+IdRoom+"/"+IdUser, { headers: {"Authorization" : ` ${token}`} })
      .then((res) => {
          if(res.data.result === "Token expirado"){
            logout();
            setIsAuthenticated(false);
          //  toast.warn('Sessão expirada!');
          }else{
                if(res.data.result.recordset[0]['TimerFim'] <= 0){
                toast.info('Reunião finalizada!');
                history.replace('/rooms');
                }else{
                    (res.data.records !== 0 && setListPauta(res.data.result.recordset))
                }
          }
        })
        .catch((err) => {
        });
    
        
      }, 5000);

      return () => clearInterval(interval);


}, [token,IdRoom,IdUser,setIsAuthenticated]
);





//mapeia a consulta
const LISTAPAUTA = ListPauta.map((Lista, index) => ({

  idItem: Lista.idItem,
  itemPauta: Lista.itemPauta,
  //assembleia: Lista.assembleia,
  //idAssembleia: Lista.idAssembleia,
  //entidade: Lista.entidade,
  //idEntidade: Lista.idEntidade,
  tipoItem: Lista.tipoItem,
  //configuracao: Lista.configuracao,
  //idConfiguracao: Lista.idConfiguracao,
  //tipoEleicao: Lista.tipoEleicao,
  //quantidade_candidatos:Lista.quantidade_candidatos,
  //quantidadeExataCandidatos:Lista.quantidadeExataCandidatos,
  //Quant_proposta:Lista.Quant_proposta,
  //voto_branco:Lista.voto_branco,
  //codigoItem: Lista.codigo,
  horarioInicial: Lista.horarioInicial,
  horarioFinal: Lista.horarioFinal,
  //DataInicio_Assembleia: Lista.DataInicio_Assembleia,
  //DataFim_Assembleia: Lista.DataFim_Assembleia,
  votou: Lista.votou,
  TimerInicio: Lista.TimerInicio,
  TimerFim: Lista.TimerFim,
  TimerVotacaoItem: Lista.TimerVotacaoItem
 
  }));








  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      

      
        <MainStyle>

        <Page title="Votação | ASSEMBLEIACOOP">
      <Container>

<Grid >
<div className = 'titulo-topo-votacao' >

{LISTADADOS.map((pauta, id) => (
  <DADOSTOPO key={id} {...pauta} />       
))}

</div>
</Grid>


<Grid>
<div >

{LISTAPAUTA.map((lista, id) => (
<PautaDetail key={id} {...lista} />  
))}

</div>
</Grid>

          </Container>
          </Page>

        </MainStyle>
    </RootStyle>


  );


}







const PautaDetail = (props) => {
  const { 
    idItem, 
    itemPauta, 
    tipoItem,
    votou,
    horarioInicial, 
    horarioFinal,
    TimerInicio,
    TimerFim,
    TimerVotacaoItem
  } = props;




  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));




  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdPropVote', IdPropsSelect);
    addItem('ThemeVotation', tipoItem);
    addItem('TitleVotation', itemPauta);
  
  }  

  const ResultClick = (idItem) =>{

      addItem('IdResultVoteItem', idItem);
      addItem('ThemeResultVoteItem', tipoItem);

     // window.open('https://assembleiacoop.coop.br/result_proposal', '_blank');

  }

  const PropostasClick = (idItem) =>{

    addItem('IdPropsVoteItem', idItem);
    addItem('ThemePropsVoteItem', tipoItem);

  }




return(

<>

<Card>
<CardActionArea>

<RouterLink 
onClick={(e) => PropsClick(idItem, e)} 
to={(
  (horarioInicial === null && "/room")
  ||
  (votou === 'SIM' && "/room")
  ||
  (TimerVotacaoItem >= 0 && 
    ((tipoItem === "Proposta" && "/voteproposal")||"voteelection")
  )
  )} 
  className = 'titulos-votacao'
>

  <Box sx={{ position: 'relative' }} >
  <Label className = 'icones-tela-votacao'
  variant="filled"
  color={(tipoItem === 'Proposta' && 'info') || 'warning'}
  >
  {tipoItem}
  </Label>
  </Box>


<CardHeader title={itemPauta} />
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 2, pr: 0 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap></Typography>
      </Stack>
    </Scrollbar>

    <Divider />

<Grid container spacing={1} style={{marginTop:"10px"}} >
<Grid item xs={12} sm={2} style={{paddingTop: "0px", marginLeft: '15px'}}>
<div>
<Icon icon={(votou === 'SIM' && checkmarksquare2) || (TimerVotacaoItem < 0 && closecircle) || (horarioInicial === null && clock) || checkmarkcircle2} 
style={{textDecoration:"none",color:(TimerVotacaoItem < 0 && 'grey') || (horarioInicial === null && '#0d6efd') || 'green'}}
/>
<label style={{fontSize: "14px",color:(TimerVotacaoItem < 0 && 'grey') || (horarioInicial === null && '#0d6efd') || 'green'}}>  
{(votou === "SIM" && " Votado") || (TimerVotacaoItem < 0 && " Votação encerrada") || (horarioInicial === null && " Votação Em Breve") || " Votação aberta"}
</label>
</div>
</Grid>

<Grid item xs={12} sm={6} style={{paddingTop: "0px"}}></Grid>

<Grid item xs={12} sm={3.5} style={{paddingTop: "0px", textAlign: "right"}}>
<div>
{
(

  (horarioInicial === null && 
    <Button1
    variant="outlined"
    //onClick={handleShow}
    onClick={(e) => PropostasClick(idItem, e)}
    component={RouterLink}
    to="/candidates"
   // to={(tipoItem === "Proposta" && "/candidates")||("/candidates")}
    style={{textDecoration:"none",color:"#27ab55"}}
    disabled={false}
     >
    Visualizar
    </Button1>
  )
  ||
  (votou === 'SIM' && 
    <>  <Button1
      variant="outlined"
      //onClick={ResultClick}
      onClick={(e) => ResultClick(idItem, e)}
      component={RouterLink}
      //to={(tipoItem === "Proposta" && "/Result_Proposal")||(<></>)}
      to={"/Result_Proposal"}
      style={{textDecoration:"none",color:"#27ab55"}}
      disabled={false}
       >
      Resultado
      </Button1>

{/*<Button1
variant="outlined"
onClick={(e) => ResultClick(idItem, e)}
style={{textDecoration:"none",color:"#27ab55",width:"60%"}}
disabled={false}
>
Resultado
</Button1>*/}
</>
  )
  ||
  (TimerVotacaoItem >= 0 && 
    <><Button1
        variant="contained"
        onClick={(e) => PropsClick(idItem, e)} 
        style={{textDecoration:"none",color:"#fff"}}
        disabled={false}
         >
        Votar
        </Button1>&nbsp;&nbsp;&nbsp;
        <Button1
      variant="outlined"
      //onClick={handleShow}
      onClick={(e) => PropostasClick(idItem, e)}
      component={RouterLink}
      to="/candidates"
      //to={(tipoItem === "Proposta" && <></>)||("/candidates")}
      style={{textDecoration:"none",color:"#27ab55"}}
      disabled={false}
       >
      Visualizar
      </Button1></>
  )
  ||
  (
    <Button1
    variant="outlined"
    onClick={(e) => ResultClick(idItem, e)}
    component={RouterLink}
    to={"/Result_Proposal"}
    style={{textDecoration:"none",color:"#27ab55"}}
    disabled={false}
     >
    Resultado
    </Button1>
  )

  

)   
  
}


</div>
</Grid>
</Grid>
 <Box sx={{p: 1, textAlign: 'right' }}></Box>
 </RouterLink>
</CardActionArea>  
</Card>
  
<br />


</>

);

}







const DADOSTOPO = (props) => {

  const{
    Titulo_Assembleia,
    Data_Assembleia,
    Horario_PrimeiraChamada,
    Descricao_Assembleia,
    Link_Assembleia
        }= props;
    
    
  const DataReuiao = moment.utc(Data_Assembleia).format('DD/MM/YYYY');

    
      return (
        <>

  <Grid item xs={12} sm={9} style={{paddingTop: "0px"}}>
  <Stack sx={{ mb: 0 }}>
          <Typography variant="h4" gutterBottom>
            {Titulo_Assembleia}
          </Typography>
          <Typography sx={{fontSize: "13px", color: 'text.secondary' }}>
            {"Realização:"}&ensp; {DataReuiao + ' às ' +  moment.utc(Horario_PrimeiraChamada).format('HH:mm') }
          </Typography>
          <Typography sx={{fontSize: "13px", color: 'text.secondary' }}>
            {"Link da Reunião:"}&ensp; {(Link_Assembleia !== "" && <a href={"https://"+Link_Assembleia} target="_blank" style={{color:"blue"}}>{"https://"+Link_Assembleia}</a>)}
          </Typography>
          <Typography sx={{fontSize: "15px", color: 'text.secondary' }}>
            {"Descrição da Reunião:"}&ensp; {Descricao_Assembleia}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
          <hr />
          </Typography>
  </Stack>
  </Grid>

  <Grid item xs={12} sm={3} style={{paddingTop: "0px", textAlign: "-webkit-right"}} >
  <Grid item xs={3} style={{maxWidth:"100%"}}>

  </Grid>
  </Grid>


        </>
      );
    };
