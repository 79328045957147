import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
import Page from '../components/Page';

import { 
  Grid, 
  Stack,
  Button as Button1,
  Container,
  Typography, 
  Box,
  Card,
  CardHeader,
  Divider,
  TextField,
  CardContent,
  Paper,
  Tab,
  Tabs,
  CardActionArea,
  CardMedia,
  CardActions,
  Avatar,
  AvatarGroup,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink} from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import { Modal as Modal2} from 'antd';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form as Form2, FormikProvider} from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import functions from "../constants/serverFunctions";
import Scrollbar from '../components/Scrollbar';
import Button from 'react-bootstrap/Button';
import { ItemMoreMenu } from '../components/_dashboard/eleicao';
import { ItemMoreMenuCdt } from '../components/_dashboard/eleicao';
import "react-widgets/styles.css";
import TimeField from 'react-simple-timefield';
import moment from 'moment-timezone';
import MyTimer from "./VoteTimer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.css';
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

import { Tabs as Tabs2 } from 'antd';
import Form from 'react-bootstrap/Form';

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



//vertical
function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{paddingTop:"5px"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}








export default function Elections() {

  const token = getItem('token-assembleia-api');
  const IdItem= getItem('IdProps'); 
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');

  const [open, setOpen] = useState(false);
  const [ListProps, setListProps] = useState([]);
  const [ListCandts, setListCandts] = useState([]);
  const [ListCandtsInd, setListCandtsInd] = useState([]);
  const [setQuantListProps] = useState([]);
  const [QuantCandInd, setQuantCandInd] = useState([]);
  const [DadosItem, setDadosItem] = useState([]);
  const [AbasTipoEleicao, setAbasTipoEleicao] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  


// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {

    axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.records === 1){

        axios
        .get(functions.itemPauta.BUSCAR_ITEM_ELECTION_PAUTA_REUNIAO_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
          .then((res) => {
                if(res.data.result === "Token expirado"){
                  logout();
                  setIsAuthenticated(false);
                  //toast.warn('Sessão expirada!');
                }else{
                  (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
                  setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])
                }
          })
          .catch((err) => {
          });


    }//else{
     // logout();
    //  setIsAuthenticated(false);
    //  toast.error('Token inválido!');
    //}
  }); 


  const interval =  window.setInterval(() => {

    axios
.get(functions.itemPauta.BUSCAR_ITEM_ELECTION_PAUTA_REUNIAO_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
  .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          //toast.warn('Sessão expirada!');
        }else{
          (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
          setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])
        }
      
  })
  .catch((err) => {
  });

  }, 5000);

  return () => clearInterval(interval);
  
}, [IdEntidade,IdUser,IdItem,token,setIsAuthenticated]);



//mapeia a consulta
const DADOSITEMSELECT = DadosItem.map((DadoItem, index) => ({

  idItem: DadoItem.idItem,
  itemPauta: DadoItem.itemPauta,
  idEntidade: DadoItem.idEntidade,
  entidade: DadoItem.entidade,
  //idAssembleia: DadoItem.idAssembleia,
  //assembleia: DadoItem.assembleia,
  //dataAssembleia: DadoItem.data,
  tipoItem: DadoItem.tipoItem,
  tipoEleicao:DadoItem.tipoEleicao,
  Quant_proposta: DadoItem.Quant_proposta,
  codigo: DadoItem.codigo,
  horarioInicial: DadoItem.horarioInicial,
  horarioFinal: DadoItem.horarioFinal,
  presentes: DadoItem.presentes,
  voto_branco: DadoItem.voto_branco,
  votositem:DadoItem.votositem,
  TimerInicio: DadoItem.TimerInicio,
  TimerFim: DadoItem.TimerFim,
  TimerVotacao: DadoItem.TimerVotacao,
  votosBrancoChapa: DadoItem.votosBrancoChapa,
  votosBrancoCandidatoInd: DadoItem.votosBrancoCandidatoInd,
  quantidade_candidatos: DadoItem.quantidade_candidatos,
  countVotosMin: DadoItem.countVotosMin,
  quantidadeExataCandidatos: DadoItem.quantidadeExataCandidatos,

//TotalVotos: DadoItem.TotalVotos,





  }));




//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
   setQuantListProps(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token,setQuantListProps]);


/*
//mapeia a consulta
const LISTAPROPOSTA = ListProps.map((ListaProp, index) => ({

  entidade: ListaProp.entidade, 
  idEntidade: ListaProp.idEntidade, 
  assembleia: ListaProp.assembleia, 
  idAssembleia: ListaProp.idAssembleia, 
  data: ListaProp.data, 
  itemPauta: ListaProp.itemPauta, 
  idItem: ListaProp.idItem, 
  tipo: ListaProp.tipo, 
  Chapa: ListaProp.Chapa, 
  idChapa: ListaProp.idChapa, 
  descricaoChapa: ListaProp.descricaoChapa, 
  quantCandidatos: ListaProp.quantCandidatos,
  horarioInicial: ListaProp.horarioInicial, 
  horarioFinal: ListaProp.horarioFinal

  }));
*/




//Consulta lista de candidatos do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_CHAPA_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListCandts(res.data.result.recordset)))
    })
    .catch((err) => {
    });
}, [IdItem,token]);



//mapeia a consulta candidatos da chapas da eleicao
const LISTACANDSCHAPA = ListCandts.map((ListCandt, index) => ({

  Chapa: ListCandt.Chapa,
  assembleia: ListCandt.assembleia,
  candidato: ListCandt.candidato,
  cargo: ListCandt.cargo,
  data: ListCandt.data,
  descricaoCanditado: ListCandt.descricaoCanditado,
  descricaoChapa: ListCandt.descricaoChapa,
  entidade: ListCandt.entidade,
  //idAssembleia: ListCandt.idAssembleia,
  idCandidato: ListCandt.idCandidato,
  idChapa: ListCandt.idChapa,
  idEntidade: ListCandt.idEntidade,
  idItem: ListCandt.idItem,
  itemPauta: ListCandt.itemPauta,
  tipo: ListCandt.tipo,
  tipoCandidato: ListCandt.tipoCandidato
  
  
    }));


//Consulta lista de candidatos INDIVIDUAL do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListCandtsInd(res.data.result.recordset)))
   setQuantCandInd(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token]);


/*
//mapeia a consulta
const LISTACANDIDATOSINDIVIDUAL = ListCandts.map((ListCandt, index) => ({

Chapa: ListCandt.Chapa,
assembleia: ListCandt.assembleia,
candidato: ListCandt.candidato,
cargo: ListCandt.cargo,
data: ListCandt.data,
descricaoCanditado: ListCandt.descricaoCanditado,
descricaoChapa: ListCandt.descricaoChapa,
entidade: ListCandt.entidade,
idAssembleia: ListCandt.idAssembleia,
idCandidato: ListCandt.idCandidato,
idChapa: ListCandt.idChapa,
idEntidade: ListCandt.idEntidade,
idItem: ListCandt.idItem,
itemPauta: ListCandt.itemPauta,
tipo: ListCandt.tipo,
tipoCandidato: ListCandt.tipoCandidato

  }));

*/



  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      

      
        <MainStyle >
          <Page title="Eleição | ASSEMBLEIACOOP">
              <Container>
                  <Grid container spacing={1}  >
                        {DADOSITEMSELECT.map((dadoitem, id) => (
                          <DADOSTOPO key={id} {...dadoitem} value={QuantCandInd} />       
                        ))}
                        </Grid>
              </Container>
          </Page>




{(AbasTipoEleicao === "Chapa Eleitoral" &&

(<Box sx={{ width: '100%' }}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
      <Tab label="Resultados" {...a11yProps(1)} />
    </Tabs>
  </Box>

      <TabPanel value={value} index={0}>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500 }} >
            <ListaChapaDetail value={[ListProps,ListCandts,DadosItem]}/>
        </Box>
      </TabPanel>


      <TabPanel value={value} index={1}>


       <Grid container spacing={1} style={{marginTop:"10px"}} >
          <Grid item xs={12} sm={6} style={{paddingTop: "0px", marginLeft: '0px'}}>
            <div>
              <ResultadosVotacao value={[ListProps,ListCandts,DadosItem,LISTACANDSCHAPA]} />             
            </div>
          </Grid>

          <Grid item xs={12} sm={5} style={{paddingTop: "0px", marginLeft: '10px'}}>
                <div>
                  {DADOSITEMSELECT.map((proposta, id) => (
                    <StatusDetail key={id} {...proposta} />       
                  ))}
                </div>
          </Grid>

</Grid>


      </TabPanel>


</Box>
    
)
)


||


(AbasTipoEleicao === "Candidato Individual" &&


(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Candidatos Individuais" {...a11yProps(0)} />
          <Tab label="Resultados" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>

      <Grid container spacing={2}>

      {( DadosItem[0]['horarioInicial'] === null &&
          <AdicionarNewCandidatoDentroIndividual value={ListCandtsInd} />
      )}

       
        <ListaIndividualDetail value={[ListCandtsInd,DadosItem]}/>   
      </Grid>

      </TabPanel>
      <TabPanel value={value} index={1}>


<Grid container spacing={1} style={{marginTop:"10px"}} >
          <Grid item xs={12} sm={6} style={{paddingTop: "0px", marginLeft: '0px'}}>
            <div>
              <ResultadosVotacao2 value={[ListCandtsInd,DadosItem]} />       
            </div>
          </Grid>

          <Grid item xs={12} sm={5} style={{paddingTop: "0px", marginLeft: '15px'}}>
                <div>
                  {DADOSITEMSELECT.map((proposta, id) => (
                    <StatusDetail key={id} {...proposta} />       
                  ))}
                </div>
          </Grid>
</Grid>


      </TabPanel>

</Box>)


)



||


(AbasTipoEleicao === "Ambos" &&


(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
          <Tab label="Candidatos Individuais" {...a11yProps(1)} />
          <Tab label="Resultados" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500 }} >
            <ListaChapaDetail value={[ListProps,ListCandts,DadosItem]}/>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>

      <Grid container spacing={2}>
      {( DadosItem[0]['horarioInicial'] === null &&
           <AdicionarNewCandidatoDentroIndividual value={ListCandtsInd} />
      )}
       
        <ListaIndividualDetail value={[ListCandtsInd,DadosItem]}/>   
      </Grid>

      </TabPanel>
      <TabPanel value={value} index={2}>

          <Grid container spacing={1} style={{marginTop:"10px"}} >
                    <Grid item xs={12} sm={6} style={{paddingTop: "0px", marginLeft: '0px'}}>
                      <div>
                        <ResultadosVotacao3 value={[ListProps,ListCandts,ListCandtsInd,DadosItem,LISTACANDSCHAPA]} />       
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={5} style={{paddingTop: "0px", marginLeft: '0px'}}>
                          <div>
                            {DADOSITEMSELECT.map((proposta, id) => (
                              <StatusDetail key={id} {...proposta} />       
                            ))}
                          </div>
                    </Grid>
          </Grid>

      </TabPanel>

</Box>)


)


}


        </MainStyle>

    </RootStyle>


  );

}






// >>>>>>>>>>>>>>>>>>> Dados do item - Topo Pagina <<<<<<<<<<<<<<<<<<<<<<<<
const DADOSTOPO = (props) => {

  const{
  idItem,
  itemPauta,
  tipoItem,
  tipoEleicao,
  Quant_proposta,
  quantidade_candidatos,
  countVotosMin,
  quantidadeExataCandidatos,
  horarioInicial,
  horarioFinal,
  TimerInicio,
  TimerFim,
  TimerVotacao
  }= props;

  const token = getItem('token-assembleia-api');

  const contCandInd = props.value;
 
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const LoginSchema = Yup.object({
    tempoDuracao: Yup
      .string()
      .required("Campo Obrigatório")
      .test("é maior", "Favor informar tempo", function(value) {
      return value !== '00:00';
      })
});



  const formik = useFormik({
    initialValues: {
      tempoDuracao: ''
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      var min = 10000;
      var max = 99999;
      var Coditem = Math.floor(Math.random() * (max - min + 1)) + min;
    
      var timeBase = new Date("2014-03-14T" + values.tempoDuracao + ":00");
      var novahora = timeBase.getHours();
      var novominitu = timeBase.getMinutes();
      var time2 = new Date();
      var outraData = new Date();
      outraData.setHours(time2.getHours() + novahora);
      outraData.setMinutes(time2.getMinutes() + novominitu);
    
      const horaAtual =  moment(time2).format('YYYY-MM-DD HH:mm:ss');
      const horaFim =  moment(outraData).format('YYYY-MM-DD HH:mm:ss');
     

      addItem('IdVotationStart', idItem);
      addItem('ThemeVoteStart', tipoItem);

      
   axios.post(functions.votacao.INICIAR_VOTACAO, {
                  idItem: idItem,
                  codigo: Coditem,
                  //horarioInicial: horaAtual,
                  horarioFinal: horaFim
                },
                { headers: {"Authorization" : `${token}`}})
          .then((response) => {
          toast.success('Votação iniciada com sucesso!');
          window.open('https://assembleiacoop.coop.br/votation', '_blank');
          window.location.reload(false);
          })
          .catch((err) => {
          });
 
    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const segundos = dataAtual.getSeconds();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':' + segundos;
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));
*/



  const EncerrarVotacao = (IdItemPauta) => {

    const token = getItem('token-assembleia-api');

    addItem('IdVotationResult', IdItemPauta);
    addItem('ThemeVoteResult', tipoItem);

        axios.post(functions.votacao.ENCERRAR_VOTACAO, 
          {IdItemPauta: IdItemPauta},
          {headers: {"Authorization" : `${token}`} }
        )
      .then((response) => {
            toast.success('votação encerrada com sucesso!');
            window.open('https://assembleiacoop.coop.br/result_votation', '_blank');
            window.location.reload(false);
      });
     
  
  } 



return (
<>

<Grid item xs={12} sm={8.5} style={{paddingTop: "0px"}}>
<div className = 'topotitulocandidatos' >
  <Stack sx={{ mb: 3 }}>
    <Typography variant="h4" gutterBottom>
    {itemPauta}
    </Typography>
  </Stack>
  </div>
</Grid>


<Grid item xs={12} sm={3} style={{paddingTop: "0px", textAlign: "-webkit-right"}} >
  <Grid item xs={3} style={{maxWidth:"100%"}}>


{
  (TimerInicio >= 0 && 
    <Button variant="outline-secondary" disabled={true}>Iniciar</Button>
  )
  ||
  (TimerFim < 0 &&
    <Button variant="outline-secondary" disabled={true}>Finalizado</Button>
  )
  ||
  (horarioInicial === null &&
   ( (tipoEleicao === "Candidato Individual" &&
      (
        (quantidadeExataCandidatos === "SIM" &&
            ((contCandInd >= quantidade_candidatos &&
              <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
            )
            ||
            (
              <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
            ))
        )
        ||
        (countVotosMin !== null &&
            ((contCandInd >= countVotosMin &&
              <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
            )
            ||
            (
              <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
            ))
        )
        ||
        (
            (contCandInd > 0 &&
              <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
            )
            ||
            (
              <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
            )
        )
      )
    )
    ||
    (tipoEleicao === "Ambos" &&
      (
        (quantidadeExataCandidatos === "SIM" &&
            (
              (contCandInd >= quantidade_candidatos &&
                Quant_proposta > 0 &&
                <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
              )
              ||
              (Quant_proposta > 0 &&
                contCandInd >= quantidade_candidatos &&
                <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
              )
              ||
              (
                <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
              )
            )
        )
        ||
        (countVotosMin !== null &&
          (
            (contCandInd >= countVotosMin &&
                Quant_proposta > 0 &&
                <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
            )
            ||
            (Quant_proposta > 0 &&
                contCandInd >= countVotosMin &&
                <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
            )
            ||
            (
              <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
             )
          )
      )
      ||
        (
          (Quant_proposta > 0 &&
            contCandInd > 0 &&
            <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
          )
          ||
          (contCandInd > 0 &&
            Quant_proposta > 0 &&
            <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
          )
          ||
          (
            <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
          )
        )
      )
    )
    ||
    (
          (Quant_proposta > 0 &&
            <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
          )
          ||
          (
            <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
          )
    )
    )
  )
  ||
  ((TimerVotacao <= 0 && 
    <Button variant="outline-secondary" disabled={true}>Finalizado</Button>)
    ||
    <Button variant="outline-secondary" onClick={() => EncerrarVotacao(idItem)} disabled={false}>Encerrar</Button>
  )


}







  </Grid>
</Grid>



<Modal visible style={{ zIndex: 1500}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
>

  <FormikProvider value={formik}>
       <Form2 autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Iniciar Votação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={3}>

<label>
Informe a duração da votação
</label>



   <TimeField 
    id="tempoDuracao" 
    input={<TextField label="Tempo de duração" variant="standard" />}  
    {...getFieldProps('tempoDuracao')}
    error={Boolean(touched.tempoDuracao && errors.tempoDuracao)}
    helperText={touched.tempoDuracao && errors.tempoDuracao}   
    //showSeconds                      
    />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      Exemplo: 00:30 para 30 minutos - tempo Máximo 23h:59m
    </label>

        <br />
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form2>
  </FormikProvider>
</Modal>


</>

);

}


// >>>>>>>>>>>>>>>>>>> FIM Dados do item - Topo Pagina <<<<<<<<<<<<<<<<<<<<<<<<












// >>>>>>>>>>>>>>>>>>> Lista Chapas do Item(eleicao) <<<<<<<<<<<<<<<<<<<<<<<<
const ListaChapaDetail = (props) => {

  const IdItem= getItem('IdProps'); 
  const token = getItem('token-assembleia-api');

  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;
  const listCandidatos = props.value[1];
  const coutCandidatos = props.value[1].length;

  const dadosDoItem = props.value[2];


  const [isModalVisible, setIsModalVisible] = useState(false);

  const [nomeModal, setNomeModal] = useState("");
  const [idnomeModal, setIdNomeModal] = useState("");
  const [imgModal, setImgModal] = useState("");
  const [cargoModal, setCargoModal] = useState("");
  const [dscModal, setDscModal] = useState("");


  const showModal = (nome,id,cargo,dsc) => {
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    if(id==129){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
    }else if(id==130){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
    }else if(id==131){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
    }else if(id==132){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
    }else if(id==136){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
    }else if(id==137){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
    }   
    else{
      imgAvatar = "/static/illustrations/candidato/default3.png";
    }

    setIsModalVisible(true);
    setNomeModal(nome);
    setIdNomeModal(id);
    setImgModal(imgAvatar);
    setCargoModal(cargo);
    setDscModal(dsc);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [value2, setValue2] = React.useState(0);
     const handleChange2 = (event2, newValue2) => {
       setValue2(newValue2);
     };


// Dados do Modal adicionar chapa <<<<<<<<<<<<<<<<<<<<<<<<<<
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LoginSchema = Yup.object({
    nomeChapa: Yup.string().required("Campo Obrigatório")
  });

  const formik = useFormik({
    initialValues: {
      nomeChapa: ''
    },
   
    validationSchema: LoginSchema,
      onSubmit: async () => {
  
        axios.post(functions.chapaProposta.INSERIR_NOVA_CHAPA_PROPOSTA, {
                      idItemPauta: IdItem,
                      identificador: "CHAPA",
                      descricao: values.nomeChapa
                    },{ headers: {"Authorization" : `${token}`}})
              .then((response) => {
              toast.success('Chapa criada com sucesso!');
              window.location.reload(false);
              })
              .catch((err) => {
              });
                     
             

      }

  });


  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    
  //>>>>>>>>>>>>>>>>>>> Final Dados do Modal adicionar chapa  <<<<<<<<<<<<<<<<<<<<<<<<<



  //>>>>>>>>>>>>>>>>>>>>>>>>>   <<<<<<<<<<<<<<<<<<<<<<<<<<<


    let chapas = []
    let users = []
    let candidatos = []
    let listacandidatos = []


     for (let i = 0; i < coutChapa; i++) {
          users = []
          listacandidatos = []
          let imgAvatar = "/static/illustrations/candidato/default3.png";
          for(let j = 0; j < coutCandidatos; j++){
              if(listCandidatos[j]['idChapa'] === listChapas[i]['idChapa']){
                if(listCandidatos[j]['idCandidato']==129){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
                }else if(listCandidatos[j]['idCandidato']==130){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
                }else if(listCandidatos[j]['idCandidato']==131){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
                }else if(listCandidatos[j]['idCandidato']==132){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
                }else if(listCandidatos[j]['idCandidato']==136){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
                }else if(listCandidatos[j]['idCandidato']==137){
                  imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
                }   
                else{
                  imgAvatar = "/static/illustrations/candidato/default3.png";
                }
                

                users.push(<Avatar 
                              alt={listCandidatos[j]['candidato']}
                              src={imgAvatar}
                              sx={{ width: 30, height: 30 }}
                              style={{marginLeft: "-1px"}} 
                              />)
//img candidatos chapa eleitoral - tela inicial
listacandidatos.push(
            
          <Grid item xs={4}>
            <Card sx={{ maxWidth: 180 }} 
             onClick={() => showModal(listCandidatos[j]['candidato'],listCandidatos[j]['idCandidato'],listCandidatos[j]['cargo'],listCandidatos[j]['descricaoCanditado'])}
            >
               <CardActionArea style={{textAlign:"center"}}>
                  <Stack direction="row" spacing={2}  style={{paddingLeft:"20%",paddingTop:"10%"}}>
                    <Avatar 
                      alt="-" 
                      //src="/static/illustrations/candidato/default3.png"
                      src={imgAvatar}
                      sx={{ width: 100, height: 100 }} />
                  </Stack>
                 <CardContent style={{textAlign:"center"}}>
                   <Typography gutterBottom variant="h5" component="div" className = 'nome-candidatos'> 
                   {listCandidatos[j]['candidato']}
                   </Typography>
                   <Typography variant="body2" color="text.secondary">
                 { (listCandidatos[j]['cargo'] !== null && (listCandidatos[j]['cargo'])) || ("-") }
                   </Typography>
                 </CardContent>
               </CardActionArea>
              
                    {( listChapas[0]['horarioInicial'] === null &&
                     <CardActions>
                     <Box
                     sx={{
                     top: 10,
                     right: 5,
                     position: 'absolute',
                     }}
                     >
                       <ItemMoreMenuCdt value={listCandidatos[j]}/>
                    </Box>
                    </CardActions>
                      )}
              
             </Card>
        
           </Grid>
      
            )

         }
       }
       
chapas.push(
                <Tab label={
                  <Grid item xs={12} style={{textAlign:"left",width: "100%"}}>
                    <Card sx={{ maxWidth: 350 }}>
                        <CardActionArea >
                          <CardContent style={{padding:"15px"}}>
                            <Typography variant="body2" color="text.secondary" className = 'titulos-chapas'>
                            <b> {listChapas[i]['descricaoChapa']}</b>
                            </Typography>
                          </CardContent>
                          <CardActions>
                           {(listChapas[i]['quantCandidatos'] > 0 && (<AvatarGroup max={6}>{users}</AvatarGroup>))
                           ||
                           (listChapas[0]['horarioInicial'] === null &&
                           <AdicionarNewCandidatoChapa value={listChapas[i]} />
                           ||
                           <br />
                          )
                           }  
                          </CardActions>
                       </CardActionArea>
                   
                      {( listChapas[0]['horarioInicial'] === null &&
                       <Box
                       sx={{
                       top: 10,
                       right: 16,
                       position: 'absolute',
                       }}
                       >
                      <ItemMoreMenu value={listChapas[i]}/>
                      </Box>
                      )}
                     
                    </Card>
                  </Grid>
                          }
                    {...a11yProps2(i)} />    
                  )

candidatos.push(
                  <TabPanel2 value={value2} index={i}>
                      <Grid container spacing={2} >
                       
                         {( listChapas[0]['horarioInicial'] === null &&
                            <AdicionarNewCandidatoDentroChapa value={listChapas[i]} />
                          )}
                       
                       {listacandidatos}
                      </Grid>
                  </TabPanel2>
                )
          
     }


  //>>>>>>>>>>>>>>>>>>>>>>>>    <<<<<<<<<<<<<<<<<<<<<<<<<<<



return(
  <>

<Grid container spacing={1}>     
 <Grid item xs={12} sm={12} style={{paddingTop: "0px"}}>
  <div>
    <CardContent style={{padding:"10px"}}>
      <Grid container spacing={2}>

        <Grid item xs={4} style={{textAlign:"left"}}>
          <Card>
          
            <Grid item xs={12} style={{textAlign:"center"}} className = 'titulo-chapas'>
            {( dadosDoItem[0]['horarioInicial'] === null &&
                       ( <> <br/><Button1 
                        variant="contained"
                        startIcon={<Icon icon={plusFill} />}
                        onClick={handleShow}
                        >
                          Nova chapa eleitoral
                        </Button1></>)
                        ||
                        ( <> <br/>CHAPAS</>)
              
                      )} 
            </Grid>
  
          <Box sx={{p: 1, textAlign: 'center' }}></Box>
  
          <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value2}
          onChange={handleChange2}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
          >
                  {chapas}
          </Tabs>

        <Box sx={{p: 5, textAlign: 'center' }}></Box>
        </Card>
       </Grid>

      <Grid item xs={8} style={{textAlign:"left"}}>
          {candidatos}
      </Grid>





{/* Modal dos detalhes do candidato */}      
<Modal2 
      title="Dados do Candidato" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={null}
      style={{textAlign:"center" }}
      >

            <CardContent style={{textAlign:"center"}}>
              <Stack direction="row" spacing={2} style={{display: "inline-table"}}>
                <Avatar 
                  alt="--" 
                  //src="/static/illustrations/candidato/default3.3.png"
                  src={imgModal}
                  sx={{ width: 100, height: 100 }} />
              </Stack>
            </CardContent>

            <Typography variant="body2" >
            <b>Nome: </b>{nomeModal}
             </Typography>
             <Typography variant="body2" >
           <b>Cargo: </b>{(cargoModal !== null && cargoModal) || ("-") }
             </Typography>

            <hr />
            <b>Descrição</b><br /><br /> 

            {dscModal}

            <br />
       
      </Modal2>








      </Grid>
    </CardContent>
  </div>
 </Grid>
</Grid>







{/* >>>>>>>>>> MODAL ADICIONAR NOVA CHAPA <<<<<<<<<<<<<<<<<< */}

<Modal visible style={{ zIndex: 1500}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
    <Form2 autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Nova Chapa</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{overflow: 'scroll'}}>

        <Stack spacing={3}>
          <TextField 
          id="nomeChapa" 
          variant="standard"
          label="Nome da Chapa*"
          {...getFieldProps('nomeChapa')}
          error={Boolean(touched.nomeChapa && errors.nomeChapa)}
          helperText={touched.nomeChapa && errors.nomeChapa}
          />

          <label style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
              Digite acima o nome da nova chapa
          </label>
          <br />
        </Stack>

      </Modal.Body>
      <Modal.Footer>
        <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
        </Button2>
        <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
      </Modal.Footer>
    </Form2>
  </FormikProvider>
</Modal>

{/* >>>>>>>>>> FIM MODAL ADICIONAR NOVA CHAPA <<<<<<<<<<<<<<<<<< */}



  </>
)

}




// >>>>>>>>>>>>>>>>>>> Lista candidatos individual do Item(eleicao) <<<<<<<<<<<<<<<<<<<<<<<<
const ListaIndividualDetail = (props) => {

  //const IdItem= getItem('IdProps'); 

  const listCandidatos = props.value[0];
  const coutCandidatos = props.value[0].length;
  const dadoItem = props.value[1][0]['horarioInicial']

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [nomeModal2, setNomeModal2] = useState("");
  const [idnomeModal2, setIdNomeModal2] = useState("");
  const [imgModal2, setImgModal2] = useState("");
  const [cargoModal2, setCargoModal2] = useState("");
  const [dscModal2, setDscModal2] = useState("");


  const showModal = (nome,id,cargo,dsc) => { 
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    if(id==129){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
    }else if(id==130){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
    }else if(id==131){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
    }else if(id==132){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
    }else if(id==136){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
    }else if(id==137){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
    }   
    else{
      imgAvatar = "/static/illustrations/candidato/default3.png";
    }
    setIsModalVisible(true);
    setNomeModal2(nome);
    setIdNomeModal2(id);
    setImgModal2(imgAvatar);
    setCargoModal2(cargo);
    setDscModal2(dsc);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


//img candidato individual - tela inicial
    let listacandidatos = []
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    for(let j = 0; j < coutCandidatos; j++){

      if(listCandidatos[j]['idCandidato']==129){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
      }else if(listCandidatos[j]['idCandidato']==130){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
      }else if(listCandidatos[j]['idCandidato']==131){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
      }else if(listCandidatos[j]['idCandidato']==132){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
      }else if(listCandidatos[j]['idCandidato']==136){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
      }else if(listCandidatos[j]['idCandidato']==137){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
      }   
      else{
        imgAvatar = "/static/illustrations/candidato/default3.png";
      }

      listacandidatos.push(
            
        <Grid item xs={3}>
          <Card sx={{ maxWidth: 180 }}
          onClick={() => showModal(listCandidatos[j]['candidato'],listCandidatos[j]['idCandidato'],listCandidatos[j]['cargo'],listCandidatos[j]['descricaoCanditado'])}
          >
             <CardActionArea style={{textAlign:"center"}}>
                <Stack direction="row" spacing={2}  style={{paddingLeft:"20%",paddingTop:"10%"}}>
                  <Avatar 
                    alt="Remy Sharp" 
                    //src="/static/illustrations/candidato/default3.png"
                    src={imgAvatar}
                    sx={{ width: 100, height: 100 }} />
                </Stack>
               <CardContent style={{textAlign:"center"}}>
                 <Typography gutterBottom variant="h5" component="div" className = 'nome-candidatos'>
                 {listCandidatos[j]['candidato']}
                 </Typography>
                 <Typography variant="body2" color="text.secondary">
               {( listCandidatos[j]['cargo'] !== null && (listCandidatos[j]['cargo'])) || ("-" )}
                 </Typography>
               </CardContent>
             </CardActionArea>
            
            
                {
                (dadoItem === null &&
                  <CardActions>
                  <Box
                  sx={{
                  top: 10,
                  right: 5,
                  position: 'absolute',
                  }}
                  >
                <ItemMoreMenuCdt value={listCandidatos[j]}/>
                </Box>
                </CardActions>
                )}
               
             
           </Card>
         </Grid>
    
          )

  }     



return(
  <>

    {listacandidatos}


{/* Modal dos detalhes do candidato */}      
<Modal2 
      title="Dados do Candidato" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={null}
      style={{textAlign:"center" }}
      >

            <CardContent style={{textAlign:"center"}}>
              <Stack direction="row" spacing={2} style={{display: "inline-table"}}>
                <Avatar 
                  alt="---" 
                  //src="/static/illustrations/candidato/default3.5.png"
                  src={imgModal2}
                  sx={{ width: 100, height: 100 }} />
              </Stack>
            </CardContent>

            <Typography variant="body2" >
            <b>Nome: </b>{nomeModal2}
             </Typography>
             <Typography variant="body2" >
           <b>Cargo: </b>{(cargoModal2 !== null && cargoModal2) || ("-") }
             </Typography>

            <hr />
            <b>Descrição</b><br /><br /> 

            {dscModal2}

            <br />
       
      </Modal2>


  </>
)

}








//botao adicionar novo candidato na lista de candidatos - CHAPA
const AdicionarNewCandidatoDentroChapa = (props) => {

  const token = getItem('token-assembleia-api');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LoginSchema = Yup.object({
    nomecandidato: Yup.string().required("Campo Obrigatório")
});

  const formik = useFormik({
    initialValues: {
      nomecandidato: '',
      cargo: '',
      descricao: '',
      idChapaProposta: props.value.idChapa,
      idItempauta: props.value.idItem
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


            axios.post(functions.candidato.INSERIR_CANDIDATO_CHAPA, {
                        nome: values.nomecandidato,
                        cargo: values.cargo,
                        descricao: values.descricao,
                        idChapaProposta: values.idChapaProposta,
                        idItempauta: values.idItempauta
                    }, { headers: {"Authorization" : `${token}`}})
                  .then((response) => {
                    toast.success('Candidato adicionado a chapa!');
                  window.location.reload(false);
                  })
                  .catch((err) => {
                  })
          
     
      
    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  return(
    <>
      <Grid item xs={((props.value[0] === undefined && 4) || 3)}>
            <Card sx={{ maxWidth: 180 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  image="/static/illustrations/eleicoes/newcandidato.png"
                  alt=""
                  onClick={handleShow}
                />
              </CardActionArea>
          </Card>
      </Grid>





{ /* MODAL ADICIONAR NOVO CANDIDATO >>>>>>>>>>>>>> */}

<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form2 autoComplete="off" noValidate onSubmit={handleSubmit}>
       <Modal.Header style={{justifyContent: 'center'}} >
         <Modal.Title>Novo Candidato</Modal.Title>
       </Modal.Header>
        <Modal.Body style={{height: 370, overflow: 'scroll'}}>

          <Stack spacing={3}>

            <TextField 
            id="nomecandidato" 
            variant="standard"
            label="Nome do candidato*"
            {...getFieldProps('nomecandidato')}
            error={Boolean(touched.nomecandidato && errors.nomecandidato)}
            helperText={touched.nomecandidato && errors.nomecandidato}
            />

            <TextField 
            style={{marginTop:"10px"}}
            id="cargo" 
            label="Cargo" 
            variant="standard"
            {...getFieldProps('cargo')}
            error={Boolean(touched.cargo && errors.cargo)}
            helperText={touched.cargo && errors.cargo}
            />

            <br />
            
            <div class="form-group" style={{marginTop:"10px"}}>
            <label for="descricao">Descrição</label>
            <textarea
              class="form-control"
              id="descricao"
              rows="5"
              {...getFieldProps('descricao')}
            >
            </textarea>
            </div>
    
          </Stack>

    </Modal.Body>
    <Modal.Footer>
      <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
      </Button2>
      <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
    </Modal.Footer>
    </Form2>
  </FormikProvider>
</Modal>


{/* fim */}



    </>
  )

}




//botao adicionar novo candidato na lista de candidatos - CHAPA
const AdicionarNewCandidatoDentroIndividual = (props) => {

  const IdItem = getItem('IdProps'); 
  const token = getItem('token-assembleia-api');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LoginSchema = Yup.object({
    nomecandidato: Yup.string().required("Campo Obrigatório")
});

  const formik = useFormik({
    initialValues: {
      nomecandidato: '',
      cargo: '',
      descricao: '',
      idChapaProposta: props.value.idChapa,
      idItempauta: IdItem
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


             axios.post(functions.candidato.INSERIR_CANDIDATO_INDIVIDUAL, {
                        nome: values.nomecandidato,
                        cargo: values.cargo,
                        descricao: values.descricao,
                        idChapaProposta: values.idChapaProposta,
                        idItempauta: values.idItempauta
                    }, { headers: {"Authorization" : `${token}`}})
                  .then((response) => {
                  toast.success('Candidato adicionado com sucesso!');
                  window.location.reload(false);
                  })
                  .catch((err) => {
                  })
          
     
      
    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  return(
    <>
      <Grid item xs={((props.value[0] === undefined && 4) || 3)}>
            <Card sx={{ maxWidth: 180 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  image="/static/illustrations/eleicoes/newcandidato.png"
                  alt=""
                  onClick={handleShow}
                />
              </CardActionArea>
          </Card>
      </Grid>





{ /* MODAL ADICIONAR NOVO CANDIDATO >>>>>>>>>>>>>> */}

<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form2 autoComplete="off" noValidate onSubmit={handleSubmit}>
       <Modal.Header style={{justifyContent: 'center'}} >
         <Modal.Title>Novo Candidato</Modal.Title>
       </Modal.Header>
        <Modal.Body style={{height: 370, overflow: 'scroll'}}>

          <Stack spacing={3}>

            <TextField 
            id="nomecandidato" 
            variant="standard"
            label="Nome do candidato*"
            {...getFieldProps('nomecandidato')}
            error={Boolean(touched.nomecandidato && errors.nomecandidato)}
            helperText={touched.nomecandidato && errors.nomecandidato}
            />

            <TextField 
            style={{marginTop:"10px"}}
            id="cargo" 
            label="Cargo" 
            variant="standard"
            {...getFieldProps('cargo')}
            error={Boolean(touched.cargo && errors.cargo)}
            helperText={touched.cargo && errors.cargo}
            />

            <br />
            
            <div class="form-group" style={{marginTop:"10px"}}>
            <label for="descricao">Descrição</label>
            <textarea
              class="form-control"
              id="descricao"
              rows="5"
              {...getFieldProps('descricao')}
            >
            </textarea>
            </div>
    
          </Stack>

    </Modal.Body>
    <Modal.Footer>
      <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
      </Button2>
      <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
    </Modal.Footer>
    </Form2>
  </FormikProvider>
</Modal>


{/* fim */}



    </>
  )

}






//botao adicionar novo candidato dentro da chapa
const AdicionarNewCandidatoChapa = (props) => {

  const token = getItem('token-assembleia-api');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LoginSchema = Yup.object({
    nomecandidato: Yup.string().required("Campo Obrigatório")
});

  const formik = useFormik({
    initialValues: {
      nomecandidato: '',
      cargo: '',
      descricao: '',
      idChapaProposta: props.value.idChapa,
      idItempauta: props.value.idItem
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


          axios.post(functions.candidato.INSERIR_CANDIDATO_CHAPA, {
                      nome: values.nomecandidato,
                      cargo: values.cargo,
                      descricao: values.descricao,
                      idChapaProposta: values.idChapaProposta,
                      idItempauta: values.idItempauta
                },  { headers: {"Authorization" : `${token}`}})
                .then((response) => {
                  toast.success('Candidato adicionado com sucesso!');
                  window.location.reload(false);
                })
                .catch((err) => {
                });
     
      
    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  return(
    <>
     
      <Button1 variant="outlined" size="small"  onClick={handleShow}>
        Novo Candidato
      </Button1>





{ /* MODAL ADICIONAR NOVO CANDIDATO >>>>>>>>>>>>>> */}

<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form2 autoComplete="off" noValidate onSubmit={handleSubmit}>
       <Modal.Header style={{justifyContent: 'center'}} >
         <Modal.Title>Novo Candidato</Modal.Title>
       </Modal.Header>
        <Modal.Body style={{height: 370, overflow: 'scroll'}}>

          <Stack spacing={3}>

            <TextField 
            id="nomecandidato" 
            variant="standard"
            label="Nome do candidato*"
            {...getFieldProps('nomecandidato')}
            error={Boolean(touched.nomecandidato && errors.nomecandidato)}
            helperText={touched.nomecandidato && errors.nomecandidato}
            />

            <TextField 
            style={{marginTop:"10px"}}
            id="cargo" 
            label="Cargo" 
            variant="standard"
            {...getFieldProps('cargo')}
            error={Boolean(touched.cargo && errors.cargo)}
            helperText={touched.cargo && errors.cargo}
            />

            <br />
            
            <div class="form-group" style={{marginTop:"10px"}}>
            <label for="descricao">Descrição</label>
            <textarea
              class="form-control"
              id="descricao"
              rows="5"
              {...getFieldProps('descricao')}
            >
            </textarea>
            </div>
    
          </Stack>

    </Modal.Body>
    <Modal.Footer>
      <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
      </Button2>
      <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
    </Modal.Footer>
    </Form2>
  </FormikProvider>
</Modal>


{/* fim */}



    </>
  )

}






//ABA RESULTADOSS - Painel status item da reuniao
const StatusDetail = (props) => {

  const{
    idItem,
    tipoItem,
    codigo,
    horarioFinal,
    presentes,
    votositem,
    TimerVotacao
    }= props;


  const time = new Date();
  if (horarioFinal != null){
/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60)*60);

  time.setSeconds(time.getSeconds() + fim); 
  */
 
  time.setSeconds(time.getSeconds() + (TimerVotacao*60)); 

}


const ResultItem = (idItem) =>{
     
  addItem('IdVotationResult', idItem);
  addItem('ThemeVoteResult', tipoItem);

   window.open('https://assembleiacoop.coop.br/result_votation', '_blank');

} 


const StatusItem = (idItem) =>{
     
  addItem('IdVotationStart', idItem);
  addItem('ThemeVoteStart', tipoItem);

   window.open('https://assembleiacoop.coop.br/votation', '_blank');

} 




  return(
    <>


<Card>
        <CardContent>
          <Grid container spacing={2}>

          <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Tempo"}</h2>
       <h2>
       <Typography>
       {(horarioFinal === null && "-") 
       || 
        (<MyTimer expiryTimestamp={time} />
        )}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>

   <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Código Votação"}</h2>
       <h2>
       <Typography>
       {(codigo === null && "-") || (codigo)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>


   

          </Grid>
        </CardContent>
      </Card>

<br />
      <Card>
        <CardContent>
          <Grid container spacing={2}>


          <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Presentes"}</h2>
       <h2>
       <Typography>
       {(presentes === null && "-") || (presentes)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>


   <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Total de Votos"}</h2>
       <h2>
       <Typography>
       {(votositem === null && "-") || (votositem)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>
   
   
  <Grid item xs={12} style={{textAlign:"center"}}>
    <Button1
      variant="outlined"
      component={RouterLink} 
      to="UserVoteEleicao"
      style={{textDecoration:"none",color:"#27ab55"}}
    >
      Lista de Votação
    </Button1>
  </Grid>

          </Grid>
        </CardContent>
   </Card>
<br/>



{(horarioFinal !== null &&  

<Card>
        <CardContent>
          <Grid container spacing={2}>
   
   <Grid item xs={3}>
   <img src="/static/illustrations/itempauta/resultados.png" alt="relatorio" /> 
   </Grid>


   {(TimerVotacao < 0 &&
  <Grid item xs={9} style={{textAlign:"center"}}>
    <label style={{fontSize:"13px"}}>Disponibilizar Painel de Resultados.</label>
    <br/>
  <br/>
    <Button1
      variant="outlined"
      onClick={(e) => ResultItem(idItem, e)}
      className='btn-abrir'
    >
      Abrir
    </Button1>
  </Grid>
)
||
(
  <Grid item xs={9} style={{textAlign:"center"}}>
  <label style={{fontSize:"13px"}}>Acompanhar status da votação.</label>
  <br/>
<br/>
  <Button1
    variant="outlined"
    onClick={(e) => StatusItem(idItem, e)}
    style={{textDecoration:"none",color:"#27ab55",width:"60%"}}
  >
    Abrir
  </Button1>
</Grid>
)
}
  
 {/* <Grid item xs={9} style={{textAlign:"center"}}>
    <label style={{fontSize:"15px"}}>Disponibilizar Painel de Resultados.</label>
    <br/>
  <br/>
    <Button1
      variant="outlined"
      onClick={(e) => ResultItem(idItem, e)}
      className='btn-abrir'
    >
      Abrir
    </Button1>
  </Grid>*/}

          </Grid>
        </CardContent>
   </Card>
)
||
(
""
)}



    </>
  )


}



const ResultadosVotacao = (props) => {


  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;

  const listCandidatos = props.value[1];
  const coutCandidatos = props.value[1].length;

  const votositem = props.value[2][0]['votositem'];
  const horarioFinal = props.value[2][0]['horarioFinal'];
  const votoBranco = props.value[2][0]['voto_branco'];
  const countVotosBranco = props.value[2][0]['votosBrancoChapa']; 
  const StatusReuniao = ((props.value[2][0]['TimerVotacao'] === null && 0)|| (props.value[2][0]['TimerVotacao']))



 // const [value2, setValue2] = React.useState(0);
  //   const handleChange2 = (event2, newValue2) => {
  //     setValue2(newValue2);
  //   };



  let chapas = []
  let users = []
 // let candidatos = []



  for (let i = 0; i < coutChapa; i++) {

    const progressUni = props.value[0][i]['quantVotoChapa'];
    const progressT = ((progressUni/votositem)*100);

        users = []
        let imgAvatar = "/static/illustrations/candidato/default3.png";
        for(let j = 0; j < coutCandidatos; j++){
          if(listCandidatos[j]['idChapa'] === listChapas[i]['idChapa']){
            if(listCandidatos[j]['idCandidato']==129){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
            }else if(listCandidatos[j]['idCandidato']==130){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
            }else if(listCandidatos[j]['idCandidato']==131){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
            }else if(listCandidatos[j]['idCandidato']==132){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
            }else if(listCandidatos[j]['idCandidato']==136){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
            }else if(listCandidatos[j]['idCandidato']==137){
              imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
            }   
            else{
              imgAvatar = "/static/illustrations/candidato/default3.png";
            }
                users.push(<>

              <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                <Avatar 
                alt={listCandidatos[j]['candidato']}
                //src="/static/illustrations/candidato/default3.png"
                src={imgAvatar}
                sx={{ width: 30, height: 30 }}
                style={{marginLeft: "-1px"}} 
                />&nbsp;&nbsp;&nbsp;{listCandidatos[j]['candidato']}  
              </Typography>
     
                    </>          
                                
                          )

          }
        }



    chapas.push(
      <>
            <Grid className='conteudo-resultado' item xs={12} sm={3.5} style={{paddingTop: "0px", marginLeft: '0px', maxWidth: "95%"}}>
                <div>
                  <Card>
                    
                <CardContent style={{textAlign:"center"}}>
                <Typography>
                    <h5>{listChapas[i]['descricaoChapa']}</h5>
                </Typography>

                  <Typography variant="body2" color="text.secondary">

                   {props.value[3].map((candidatos, id) => (
                      <ListaCandidatosChapa key={id} {...candidatos} value={listChapas[i]['idChapa']} />       
                    ))}

                   </Typography>
                   
                 </CardContent>
          
                    <Divider />
          
                    <Grid container spacing={1} style={{marginTop:"10px"}} >
                    <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
                    <div>
          
                    <div className="container">
                    <div className="progressbar-container">
                        <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                            <div className="progressbar-liquid"></div>
                        </div>
                        <span className="progress">
                        {(listChapas[i]['descricaoChapa'] !== null &&
                          <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>)
                          ||
                          <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "abstenção") || (progressUni === 0 && "Nenhuma abstenção") || ("abstenções")}</b>
                        }
                        </span>
                    </div>
                    </div>
          
                    </div>
                    </Grid>
          
                    </Grid>
                    <Box sx={{p: 1, textAlign: 'right' }}></Box>
                    </Card>
                    <br/>
                    </div>
                    </Grid>

            </>

    )
          
   }


   const progressTBranco = ((countVotosBranco/votositem)*100);


(votoBranco === "SIM" &&

   chapas.push(
    <>
           <Grid className='conteudo-resultado-voto-nulo-painel conteudo-resultado conteudo-resultado-indiv' item xs={12} sm={3.5} >
  <div>
  <Card>
  
    <Scrollbar>  
      <Stack spacing={1} sx={{ p: 2, pr: 0 }} style={{alignItems: "center"}}>
        <Typography variant="body2" noWrap>
          <Grid item xs={11} style={{fontSize:"13px"}}>
          <Typography>
          <h5>Voto em Branco</h5>
          </Typography>                   
          </Grid>
        </Typography>
      </Stack>
    </Scrollbar>
    
    <Divider />
  
    <Grid container spacing={1} style={{marginTop:"10px"}} >
    <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
    <div>
  
    <div className="container">
    <div className="progressbar-container">
        <div className="progressbar-complete" style={{width: `${progressTBranco}%`}}>
            <div className="progressbar-liquid"></div>
        </div>
        <span className="progress">
        <b>{(countVotosBranco > 0 && countVotosBranco) || ("")} {(countVotosBranco === 1 && "voto em branco") || (countVotosBranco === 0 && "Nenhum voto em branco") || ("votos em branco")}</b>
        </span>
    </div>
    </div>
  
    </div>
    </Grid>
  
    </Grid>
    <Box sx={{p: 1, textAlign: 'right' }}></Box>
    </Card>
    <br />
    </div>
    </Grid>
          </>

   )

)

/*
   const progressUniBranco = props.value[0][i]['quantVotoChapa'];
   const progressTBranco = ((progressUniBranco/votositem)*100);



   chapas.push(
    <>
          <Card>
          <Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
            <Stack spacing={1} sx={{ p: 2, pr: 0 }}>
              <Typography variant="body2" noWrap>
                <Grid item xs={11} style={{fontSize:"13px"}}>

                </Grid>
          </Typography>
            </Stack>
          </Scrollbar>

          <Divider />

          <Grid container spacing={1} style={{marginTop:"10px"}} >
          <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
          <div>

          <div className="container">
          <div className="progressbar-container">
              <div className="progressbar-complete" style={{width: `${progressTBranco}%`}}>
                  <div className="progressbar-liquid"></div>
              </div>
              <span className="progress">
              {(listChapas[i]['descricaoChapa'] !== null &&
                <b>{(progressUniBranco > 0 && progressUniBranco) || ("")} {(progressUniBranco === 1 && "voto em branco") || (progressUniBranco === 0 && "Nenhum voto em branco") || ("votos em branco")}</b>)
                }
              </span>
          </div>
          </div>

          </div>
          </Grid>

          </Grid>
          <Box sx={{p: 1, textAlign: 'right' }}></Box>
          </Card>
          <br />
          </>

  )
*/


/* tirar esse campo pra colocar resultado da consulta

   const horarioFinal = props.value[2][0]['horarioFinal'];
   let fim = 0;

if(horarioFinal != null){
   const dataAtual = new Date();
   const dia = dataAtual.getDate();
   const mes = (dataAtual.getMonth() + 1);
   const ano = dataAtual.getFullYear();
   const horas = dataAtual.getHours();
   const minutos = dataAtual.getMinutes();
   const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
   const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
   const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
   const d = moment.duration(ms);
    fim = (Math.floor(d.asHours()*60));
}else{
   fim = 0;
}
*/


return(
  <>


{

(horarioFinal !== null &&
  (StatusReuniao > 0 && 
 
         <Card>
             <CardHeader title="Resultado" />
             <Grid container spacing={2}>
               <Grid item xs={12} style={{textAlign:"center"}}>
                   <Box sx={{p: 8, textAlign: 'center' }}>
                   <Grid item xs={12}>
                   <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
                   </Grid>
                   <br/> 
                   <label style={{fontSize:"13px"}}>
                   Resultado será disponibilizado ao final da votação
                   </label>
                   </Box>
                   <Box sx={{p: 5, textAlign: 'center' }}></Box>
               </Grid>
             </Grid>
         </Card>

    ||

   (chapas))

)

||

(
  <Card>
             <CardHeader title="Resultado" />
             <Grid container spacing={2}>
               <Grid item xs={12} style={{textAlign:"center"}}>
                   <Box sx={{p: 8, textAlign: 'center' }}>
                   <Grid item xs={12}>
                   <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
                   </Grid>
                   <br/> 
                   <label style={{fontSize:"13px"}}>
                   Resultado será disponibilizado ao final da votação
                   </label>
                   </Box>
                   <Box sx={{p: 5, textAlign: 'center' }}></Box>
               </Grid>
             </Grid>
         </Card>
)



}



  </>
)


}







//Resultado candidato INDIVIDUAL
const ResultadosVotacao2 = (props) => {

  const listCandidatos = props.value[0];
  const coutCandidatos = props.value[0].length;

  const votositem = props.value[1][0]['votositem'];
  const horarioFinal = props.value[1][0]['horarioFinal'];
  const StatusReuniao = props.value[1][0]['TimerVotacao'];
  const votoBranco = props.value[1][0]['voto_branco'];
  const countVotosBranco = props.value[1][0]['votosBrancoCandidatoInd']; 
 

  let candidatos = [];

  
  for (let i = 0; i < coutCandidatos; i++) {

    const progressUni = props.value[0][i]['votado'];
    const progressT = ((progressUni/votositem)*100);

    let imgAvatar = "/static/illustrations/candidato/default3.png";
    if(listCandidatos[i]['idCandidato']==129){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
    }else if(listCandidatos[i]['idCandidato']==130){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
    }else if(listCandidatos[i]['idCandidato']==131){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
    }else if(listCandidatos[i]['idCandidato']==132){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
    }else if(listCandidatos[i]['idCandidato']==136){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
    }else if(listCandidatos[i]['idCandidato']==137){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
    }   
    else{
      imgAvatar = "/static/illustrations/candidato/default3.png";
    }

    candidatos.push(
    


<>
<Grid className='conteudo-resultado conteudo-resultado-indiv margin-conteudo-resultado-painel' item xs={12} sm={12} >
    <div>
      <Card>

        <Scrollbar>
          <Stack spacing={1} sx={{ p: 2, pr: 0 }}>
            <Typography variant="body2" noWrap>
              <Grid item xs={11} style={{fontSize:"13px"}}>
             
              <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                 <Avatar 
                  alt={listCandidatos[i]['candidato']}
                  //src="/static/illustrations/candidato/default3.6.png"
                  src={imgAvatar}
                  sx={{ width: 50, height: 50 }}
                  style={{marginLeft: "-1px"}} 
                  />&nbsp;&nbsp;&nbsp;{listCandidatos[i]['candidato']} 
              </Typography>
               
              </Grid>
            </Typography>
          </Stack>
        </Scrollbar>

        <Divider />

        <Grid container spacing={1} style={{marginTop:"10px"}} >
        <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
        <div>

        <div className="container">
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
            <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>
            </span>
        </div>
        </div>

        </div>
        </Grid>

        </Grid>
        <Box sx={{p: 1, textAlign: 'right' }}></Box>
        </Card>
        <br/>
        </div>
        </Grid>
      
        </>

    )
           
   }




   const progressTBranco = ((countVotosBranco/votositem)*100);


(votoBranco === "SIM" &&

candidatos.push(
    <>
  
  <Grid className='conteudo-resultado-voto-nulo-painel conteudo-resultado conteudo-resultado-indiv' item xs={12} sm={12} >
  <div>
  <Card>
  
    <Scrollbar>  
      <Stack spacing={1} sx={{ p: 2, pr: 0 }} style={{alignItems: "center"}}>
        <Typography variant="body2" noWrap>
          <Grid item xs={11} style={{fontSize:"13px"}}>
          <Typography>
          <h5>Voto em Branco</h5>
          </Typography>                   
          </Grid>
        </Typography>
      </Stack>
    </Scrollbar>
    
    <Divider />
  
    <Grid container spacing={1} style={{marginTop:"10px"}} >
    <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
    <div>
  
    <div className="container">
    <div className="progressbar-container">
        <div className="progressbar-complete" style={{width: `${progressTBranco}%`}}>
            <div className="progressbar-liquid"></div>
        </div>
        <span className="progress">
        <b>{(countVotosBranco > 0 && countVotosBranco) || ("")} {(countVotosBranco === 1 && "voto em branco") || (countVotosBranco === 0 && "Nenhum voto em branco") || ("votos em branco")}</b>
        </span>
    </div>
    </div>
  
    </div>
    </Grid>
  
    </Grid>
    <Box sx={{p: 1, textAlign: 'right' }}></Box>
    </Card>
    <br />
    </div>
    </Grid>
   
    </>
         
      

   )

)




return(
  <>

{

  (horarioFinal != null &&
        (StatusReuniao <= 0 &&

        (candidatos)

        ||

        (<Card>
          <CardHeader title="Resultado" />
            <Grid container spacing={2}>
              <Grid item xs={12} style={{textAlign:"center"}}>
                <Box sx={{p: 8, textAlign: 'center' }}>
                <Grid item xs={12}>
                <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
                </Grid>
                <br/> 
                <label style={{fontSize:"13px"}}>
                Resultado será disponibilizado ao final da votação
                </label>
                </Box>
                <Box sx={{p: 5, textAlign: 'center' }}></Box>
              </Grid>
            </Grid>
          </Card>
        )

        )
  )

  ||


(
  <Card>
             <CardHeader title="Resultado" />
             <Grid container spacing={2}>
               <Grid item xs={12} style={{textAlign:"center"}}>
                   <Box sx={{p: 8, textAlign: 'center' }}>
                   <Grid item xs={12}>
                   <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
                   </Grid>
                   <br/> 
                   <label style={{fontSize:"13px"}}>
                   Resultado será disponibilizado ao final da votação
                   </label>
                   </Box>
                   <Box sx={{p: 5, textAlign: 'center' }}></Box>
               </Grid>
             </Grid>
         </Card>
)


}


  </>
)


}





//Resultado candidato INDIVIDUAL / CHAPA 
const ResultadosVotacao3 = (props) => {


let  ListProps = props.value[0];
let  ListCandts = props.value[1];
let  ListCandtsInd = props.value[2];
let  DadosItem = props.value[3];


  const votositem = props.value[3][0]['votositem'];
  const horarioFinal = props.value[3][0]['horarioFinal'];
  const StatusReuniao = props.value[3][0]['TimerVotacao'];


  const { TabPane } = Tabs2;

function callback(key) {
  console.log(key);
}



return(
  <>


{

(horarioFinal != null &&
      (StatusReuniao <= 0 &&

     
<>

<Tabs2 defaultActiveKey="1" onChange={callback}>
    <TabPane tab="Chapa Eleitoral" key="1">
      <br />
     
    <ResultadosVotacao value={[ListProps,ListCandts,DadosItem,props.value[4]]} />

    </TabPane>
    <TabPane tab="Candidatos Individuais" key="2">
 
    <Grid container spacing={1} style={{marginTop:"10px"}} >
    <ResultadosVotacao2 value={[ListCandtsInd,DadosItem]} /> 
    </Grid>

    </TabPane>
  </Tabs2>

</>

        


      ||

      (<Card>
        <CardHeader title="Resultado" />
          <Grid container spacing={2}>
            <Grid item xs={12} style={{textAlign:"center"}}>
              <Box sx={{p: 8, textAlign: 'center' }}>
              <Grid item xs={12}>
              <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
              </Grid>
              <br/> 
              <label style={{fontSize:"13px"}}>
              Resultado será disponibilizado ao final da votação
              </label>
              </Box>
              <Box sx={{p: 5, textAlign: 'center' }}></Box>
            </Grid>
          </Grid>
        </Card>
      )

      )
)

||


(
<Card>
           <CardHeader title="Resultado" />
           <Grid container spacing={2}>
             <Grid item xs={12} style={{textAlign:"center"}}>
                 <Box sx={{p: 8, textAlign: 'center' }}>
                 <Grid item xs={12}>
                 <img src="/static/illustrations/relatorios/pesquisa.svg" style={{display: "initial"}} alt="relatorio" />
                 </Grid>
                 <br/> 
                 <label style={{fontSize:"13px"}}>
                 Resultado será disponibilizado ao final da votação
                 </label>
                 </Box>
                 <Box sx={{p: 5, textAlign: 'center' }}></Box>
             </Grid>
           </Grid>
       </Card>
)


}


  </>
)





}






const ListaCandidatosChapa = (props) => {
//console.log("teste001-->o que tem aqui",props)
  const{
candidato,
idChapa,
idCandidato

  }= props;

  let imgAvatar = "/static/illustrations/candidato/default3.png";
  if(idCandidato==129){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
  }else if(idCandidato==130){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
  }else if(idCandidato==131){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
  }else if(idCandidato==132){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
  }else if(idCandidato==136){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
  }else if(idCandidato==137){
    imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
  }   
  else{
    imgAvatar = "/static/illustrations/candidato/default3.png";
  }


return(
<>



{(idChapa === props.value &&
<Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <b style={{alignItems:"center"}}> 
                  <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                  &nbsp;&nbsp;
                   <Avatar 
                    alt={candidato}
                    //src="/static/illustrations/candidato/default3.1.png"
                    src={imgAvatar}
                    sx={{ width: 30, height:30 }}
                    style={{marginLeft: "-1px"}} 
                    />
                    &nbsp;&nbsp;&nbsp;{candidato}    
                  </Typography>
                  </b>
             </Form.Group>

             
)}


</>
)

}
