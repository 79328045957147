import React, { useContext } from "react";
import { //useNavigate as Redirect, 
 Redirect, Route, useLocation, useHistory } from "react-router-dom";
import Auth from "../contexts/Auth";



const AuthenticatedRoute = ({ component: Component, ...rest}) => {
  const { isAuthenticated } = useContext(Auth);


  return (
    
    <Route
      {...rest}
      render={props => {
        if(isAuthenticated){
        return <Component { ...props} />;
        }
        else{
       
          return <Redirect to={
            {
              pathname: "/Login",
              state: {
                from: props.location
              }
            }
          } />
          
        }
      }}
      />
  )


}











/*
const AuthenticatedRoute = ({ path, component }) => {
    const { isAuthenticated } = useContext(Auth);

    if(isAuthenticated){
      console.log("logado");
    }else{
      console.log("deslogado");
    }


    return isAuthenticated ? (
        <Route exact path={path} component={component} />
    ) : (
        <Redirect to="login" />
    )
}
*/





/*
const AuthenticatedRoute = ({ component: Component, ...rest}) => {
  //const { token } = useContext(StoreContext);
  const { isAuthenticated } = useContext(Auth);

  //console.log({...rest})

  // <Route path="login" element={<Login />} />
  return (
    <Route
      {...rest}
      render={({ location }) => isAuthenticated
        ? <Component {...rest} />
        //: <Redirect to="/Login" />
        : <Redirect
        to={{
          pathname: "/Login",
          state: { from: location }
        }}
      />
      }

   //  element={<Login />}
   //<Navigate to="/404" replace />

    />
  )
}
*/




/*
function AuthenticatedRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useContext(Auth);
//console.log(component);
  return (
    <Route
      {...rest}
      render={({ location }) =>
      isAuthenticated ?  <Component {...rest} /> : 
      <Redirect to="/login" />
  
      }
    />
  );
}

*/




/*
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(Auth);

<Route
{ ...rest}
render={props =>
  isAuthenticated() ? (
    <Component { ...props} />
  ): (
    <Redirect to={{ pathname: "/", state: { from: props.location}}} />
  )
}
/>

}

*/





export default AuthenticatedRoute;