import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import alertcircleoutline from '@iconify/icons-eva/alert-circle-outline';
// material
import { 
  Menu, 
  MenuItem, 
  IconButton, 
  ListItemIcon, 
  ListItemText, 
  Stack,
  TextField, 
} from '@material-ui/core';

import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';

//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
import functions from "../../../constants/serverFunctions";

import {useHistory} from 'react-router-dom';
import {getItem} from '../../services/LocaleStorage';

// ----------------------------------------------------------------------

export default function ItemMoreMenu(props) {
  const token = getItem('token-assembleia-api');
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose2 = () => setShow(false);
  const handleClose3 = () => setShow3(false);
  const handleShow = () => setShow(true);
  const handleShow3 = () => setShow3(true);
  let history = useHistory();


  const LoginSchema = Yup.object().shape({
    titulo: Yup.string().required('campo obrigatória'),
    
   });

  const formik = useFormik({
    initialValues: {
      id: props.value.idItem,
      titulo: props.value.itemPauta,
      
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      ((props.value.itemPauta !== values.titulo 
        
        && 
      
          axios.post(functions.itemPauta.ATUALIZAR_ITENS_PAUTA, values,
            { headers: {"Authorization" : `${token}`}})
          .then((response) => {
            toast.success('Título atualizado com sucesso!');
            window.location.reload(false);
          })
     
      ) ||

      (window.location.reload(false))

      )
  
   


 
            

    }
  });


  //botao apagar item
const apagarItemPauta = (id) => {

  const token = getItem('token-assembleia-api');

  axios.post(functions.itemPauta.EXCLUIR_POR_ID, {id:id},{ headers: {"Authorization" : `${token}`}})
  .then((response) => {
    toast.success('Item apagado!');
    window.location.reload(false);
  });


  /*
  axios.delete(functions.itemPauta.EXCLUIR_POR_ID+"/"+id, {id:id},
  { headers: {"Authorization" : `${token}`}})
     .then((response) => {
    toast.success('Item apagado!');
    console.log(token)
   // window.location.reload(false);
    //  history.go(0);
     });

     */

}



  const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps 
  } = formik;


  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClick={() => setIsOpen(false)}
      >

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleShow}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24}  />
          </ListItemIcon>
          <ListItemText primary="Editar título" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleShow3}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Apagar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        
      </Menu>



      <Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Editar Título</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={3}>

<TextField 
id="titulo" 
variant="standard"
label="Título*"
{...getFieldProps('titulo')}
error={Boolean(touched.titulo && errors.titulo)}
helperText={touched.titulo && errors.titulo}
/>

   <br />
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose2}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>



    <Modal visible style={{ zIndex: 1200}}
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >

    <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Excluir</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>


        <Stack spacing={3}>
        <div>
        <Icon icon={alertcircleoutline} width={70} height={70} color="red"/>
        </div>
        <br /> 
        <b>Essa ação não pode ser desfeita. Isso excluirá permanentemente seu recurso.</b>
        <br />
       
        </Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose3}>
            Cancelar
          </Button2>
          <Button2 className="btn-danger" variant="primary" onClick={(e) => apagarItemPauta(values.id, e)}>Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>



    </>
  );
}
