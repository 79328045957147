import React, {useContext} from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Box
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import DashboardNavbar from '../layouts/dashboardAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardAdm/DashboardSidebar';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import { mockImgAvatar } from '../utils/mockImages';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import functions from "../constants/serverFunctions";
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

import BlockUI from "../components/BlockUI/BlockUI";

import CpfCnpj from "../components/_dashboard/user/cpf-cnpj-mask";

import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/user/filters';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'cpfcnpj', label: 'CPF/CNPJ', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'perfil', label: 'Perfil', alignRight: false },
  { id: '', label: 'Status/Açoes', alignRight: false }
];


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}





export default function User() {

  const token = getItem('token-assembleia-api');
  const IdEntidade = getItem('institutionId');
  const entidade = getItem('institutionName');
  const cnpjEntidade = getItem('institutionCNPJ');
  const IdUser = getItem('userId');
  const [entidades, setEntidades] = useState([]);
  const [listaUsuarioglogal, setListaUsuarioglogal] = useState([]);
  const [entidadesFiltro, setEntidadesFiltro] = useState([]);
  const [CountAdm, setCountAdm] = useState();
  const [CountAdmteste, setCountAdmteste] = useState(31091);
  const [CountCoop, setCountCoop] = useState();
  //const [Bloqueados, setBloqueados] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { setIsAuthenticated } = useContext(Auth);
  
  const [blocking, setblocking] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);


//Consulta Usuarios por Entidade 
useEffect(() => {

  setblocking(true);

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.result === "Token expirado"){
      logout();
      setIsAuthenticated(false);
      toast.warn('Sessão expirada!');
    }else{
            if(res.data.records === 1){

              axios
                .get(functions.entidadeUsuario.BUSCAR_POR_ENTIDADE + "/"+entidade+"/"+cnpjEntidade, { headers: {"Authorization" : `${token}`} })
                .then((res) => {

                  setblocking(false);

                  (res.data.records !== 0 && setEntidades(res.data.ListaUsuarios))  
                  setListaUsuarioglogal(res.data.ListaUsuarios);
                  setCountCoop(res.data.CountCoop)
                  setCountAdm(res.data.CountAd)  
                  
                 
                })
                .catch((err) => {
                });

        }else{
          logout();
          setIsAuthenticated(false);
          toast.error('Token inválido!');
        }  
    }
    
  }); 

  
  }, [IdEntidade,IdUser,entidade,cnpjEntidade,token,setIsAuthenticated,setCountCoop,setCountAdm]);





/*const USERLIST = entidades.map((entidade, index) => ({

  ///id_entidadeUsuario: entidade.IDEntidadeUsuario,
  id_Usuario: entidade.IDUsuario,
  id_PerfilUsuario: entidade.IDPerfil_Cooperado,
  avatarUrl: mockImgAvatar(index + 1),
  name: entidade.Nome_Cooperado,
  email: entidade.Email_Cooperado,
  crm: entidade.CRM_Cooperado,
  cpfcnpj: entidade.CPF_CNPJ_Cooperado,
  status: entidade.Registro_Ativo_EntidadeUsuario,
  perfil: entidade.Perfil_Cooperado,///


  id: entidade.IDUsuario,
  avatarUrl: mockImgAvatar(index + 1),
  name: entidade.nome,
  email: entidade.email,
  crm: entidade.numeroConselho,
  cpfcnpj: entidade.cpf,
  status: entidade.situacao,
  perfil: entidade.Perfil


  }));*/

 /* funcionando
  const USERLIST = entidades.map((entidade, index) => {
    if (!entidade) {
      return null; 
    }
  
    const idUsuario = entidade.IDUsuario !== undefined ? entidade.IDUsuario : null;
  
    return {
      id: idUsuario,
      avatarUrl: mockImgAvatar(index + 1),
      name: entidade.nome,
      email: entidade.email,
      crm: entidade.numeroConselho,
      cpfcnpj: entidade.cpf,
      status: entidade.situacao,
      perfil: entidade.Perfil
    };
  }).filter(entidade => entidade !== null); 
  */

  const USERLIST = entidades.map((entidade, index) => {
    if (!entidade) {
      return null; 
    }
  
    // Use idUsuario se estiver disponível, caso contrário, use numeroConselho
    const idUnico = entidade.IDUsuario !== undefined ? entidade.IDUsuario : entidade.numeroConselho;
  
    return {
      id: idUnico, // Usando idUsuario ou numeroConselho como chave única
      avatarUrl: mockImgAvatar(index + 1),
      name: entidade.nome,
      email: entidade.email,
      crm: entidade.numeroConselho, // Se entidade.IDUsuario não existir, numeroConselho será usado como crm
      cpfcnpj: entidade.cpf,
      status: entidade.situacao,
      perfil: entidade.Perfil
    };
  }).filter(entidade => entidade !== null); 
  







  let listateste = [];

//Consulta blocks da Reuniao
useEffect(() => {
  axios
  .get(functions.entidadeUsuario.BUSCAR_LISTA_BLOCK_POR_ENTIDADE+"/"+IdEntidade+"/"+entidade, { headers: {"Authorization" : ` ${token}`} })
  .then((res) => {
  // (res.data.records !== 0 && setBloqueados(res.data.result.recordset))

 
   for (let index = 0; index < res.data.records; index++) {
      listateste.push(res.data.result.recordset[index]['cpfcnpj'])
   }


    })
    .catch((err) => {
    });

}, [token,IdEntidade,entidade]);


/*
//mapeia a consulta
const LISTABLOCK = Bloqueados.map((ListaB, index) => ({

  IdLista: ListaB.id,
	cpfcnpj: ListaB.cpfcnpj,
	crm: ListaB.crm,
	nome: ListaB.nome
	
  }));
*/








  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  //const [selected, setSelected] = useState(["Afonso de Abreu Fernandes","Alaor Campos Fiuza Júnior"]);
  const [selected, setSelected] = useState(listateste);
  //const [contselected, setcontSelected] = useState();
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const[validaremail, setvalidaremail] = useState('');
  const[validarcpfcnpj, setvalidarcpfcnpj] = useState('');



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      //console.log("adicionou Tudo")

      const newSelecteds = USERLIST.map((n) => n.cpfcnpj);
      newSelecteds.splice(0, CountAdm);
      //newSelecteds.slice(CountAdmteste,CountAdmteste+500);
      const newSelectedsnome = USERLIST.map((n) => n.name);
      newSelectedsnome.splice(0, CountAdm);
      //newSelectedsnome.slice(CountAdmteste,CountAdmteste+500);
      const newSelectedscrm = USERLIST.map((n) => n.crm);
      newSelectedscrm.splice(0, CountAdm);
      //newSelectedscrm.slice(CountAdmteste,CountAdmteste+500);

      //setCountAdmteste(CountAdmteste+500);*/

      setSelected(newSelecteds);



      //levar tudo para insert no banco
      axios.post(functions.entidadeUsuario.INSERIR_BLOQUEIO_TOTAL_ENTIDADE_USUARIO, 
        {
          fk_idEntidade: IdEntidade,
          cpfcnpj: newSelecteds,
          crm: newSelectedscrm,
          nome: newSelectedsnome,
          fk_idUsuario: IdUser
        },
        { headers: {"Authorization" : `${token}`} })
      .then((res) => {
            toast.success('Todos usuários bloqueados para votação!');
       });




      return;
    }else{
      //console.log("removeu Tudo")

      //levar tudo para delete no banco
      axios.post(functions.entidadeUsuario.REMOVER_BLOQUEIO_TOTAL_ENTIDADE_USUARIO, 
        {
          fk_idEntidade: IdEntidade
        },
        { headers: {"Authorization" : `${token}`} })
      .then((res) => {
            toast.success('Todos usuários liberados para votação!');
       });



    }
    setSelected([]);
  };

  /*
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  */


  const handleOpenFilter = () => {
    setOpenFilter(true);
    
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const handleRadioChange = (value) => {

    const filtroCooperado = [];
    if(value === "CPF"){
       for (let index = 0; index < listaUsuarioglogal.length; index++) {
          if (listaUsuarioglogal[index].numeroConselho && listaUsuarioglogal[index].cpf.length <12) {
            filtroCooperado.push(listaUsuarioglogal[index]);
          }
        }
    }else if(value === "CNPJ"){
      for (let index = 0; index < listaUsuarioglogal.length; index++) {
        if (listaUsuarioglogal[index].numeroConselho && listaUsuarioglogal[index].cpf.length >11) {
          filtroCooperado.push(listaUsuarioglogal[index]);
        }
      }
    }else if (value === "ADMINISTRADOR"){
      for (let index = 0; index < listaUsuarioglogal.length; index++) {
        if (listaUsuarioglogal[index].Perfil && listaUsuarioglogal[index].Perfil!=undefined  && listaUsuarioglogal[index].Perfil === "Administrador") {
          filtroCooperado.push(listaUsuarioglogal[index]);
        }
      }
    }else{
      for (let index = 0; index < listaUsuarioglogal.length; index++) {
          filtroCooperado.push(listaUsuarioglogal[index]);
      }
    }
  

    setEntidades(filtroCooperado);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;




 
  const clickBlock = (event, cpfcnpj, nome, crm) => {

    const selectedIndex = selected.indexOf(cpfcnpj);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, cpfcnpj);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

   setSelected(newSelected);



   if(selectedIndex === -1){
    // console.log("marcar-bloquear")
 
    axios.post(functions.entidadeUsuario.INSERIR_BLOQUEIO_ENTIDADE_USUARIO, 
      {
        fk_idEntidade: IdEntidade,
        cpfcnpj: cpfcnpj,
        crm: crm,
        nome: nome,
        fk_idUsuario: IdUser
      },
      { headers: {"Authorization" : `${token}`} })
    .then((res) => {
          toast.success('Usuário bloqueado para votação!');
     });

   }else{
     //console.log("desmarcar-liberar")
 
    axios.post(functions.entidadeUsuario.REMOVER_BLOQUEIO_ENTIDADE_USUARIO, 
      {
        fk_idEntidade: IdEntidade,
        cpfcnpj: cpfcnpj,
        fk_idUsuario: IdUser
      },
      { headers: {"Authorization" : `${token}`} })
    .then((res) => {
          toast.success('Usuário liberado para votação!');
     });

   }


  }; 
 


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LoginSchema = Yup.object().shape({
    nome: Yup.string().required('campo obrigatória'),
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
   // cpf: Yup.string().required('campo obrigatória'),
    senha: Yup.string().required('Senha obrigatória')
   });

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      crm: '',
      cpf: '',
      senha: '',
      fk_idUsuario: IdUser
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

//      console.log(values)

//BUSCAR_POR_CPF_EMAIL

let emailcad = values.email;
let cpfcnpjcad = values.cpf;
if(cpfcnpjcad ===  ""){
  cpfcnpjcad =  '00011122233';
}

axios.get(functions.usuario.BUSCAR_POR_CPF_EMAIL+"/"+emailcad+"/"+cpfcnpjcad, { headers: {"Authorization" : `${token}`} })
.then((resposta) => {

if(resposta.data.email.records >= 1){
  setvalidaremail("E-mail já cadastrado!");
}else{
  setvalidaremail("");
}

if(resposta.data.cpf_cnpj.records >= 1){
  setvalidarcpfcnpj("CPF/CNPJ já cadastrado!");
}else{
  setvalidarcpfcnpj("");
}


if(resposta.data.email.records === 0 && resposta.data.cpf_cnpj.records === 0){
  
  axios.post(functions.usuario.INSERIR_USUARIO, values, { headers: {"Authorization" : `${token}`} })
     .then((res) => {
          axios.post(functions.entidadeUsuario.INSERIR_ENTIDADE_USUARIO, {
            fk_entidade: IdEntidade,
            fk_usuario: res.data.idInsert,
            fk_perfil_usuario: 2
            },
            { headers: {"Authorization" : `${token}`} })
          .then((response) => {
            toast.success('Usuário criado com sucesso!');
            window.location.reload(false);
            });

      });
      
}



});

               

    }
  });



  const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps,
    resetForm 
  } = formik;



  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const [cpfCnpj, setCpfCnpj] = useState("");
  const [setMask] = useState("");




  function formatCpf(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
    const cpf = text.replace(badchars, "");
    return cpf.replace(mask, "$1.$2.$3-$4");

  }

  function formatCnpj(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
    const cnpj = text.replace(badchars, "");
    return cnpj.replace(mask, "$1.$2.$3/$4-$5");

  }




  return (
    <RootStyle>
      
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>


      <Page title="Usuários | ASSEMBLEIACOOP">
      <Container className='conteudo-geral'>
        <Stack  className='titulo-geral' direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>
         
          <Button className="btn-success" 
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill}/>}
            onClick={handleShow}
          >
            Novo usuário
          </Button>

          




      <Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Novo Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>

     
      <Stack spacing={3}>

    
        <TextField 
        id="nome" 
        variant="standard"
        label="Nome Completo*"
        {...getFieldProps('nome')}
        error={Boolean(touched.nome && errors.nome)}
        helperText={touched.nome && errors.nome}
        />


        <TextField 
        style={{marginTop:"10px"}}
        id="email" 
        label="E-mail*" 
        variant="standard"
        {...getFieldProps('email')}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
         />

<Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '20ch' },
      }}
      noValidate
      autoComplete="off"
    >

      <CpfCnpj
      label="CPF / CNPJ"
      id="cpf"
      value={cpfCnpj}
      onChange={(event, type) => {
          setCpfCnpj(event.target.value);
          setMask(type === "CPF");
        }}
      {...getFieldProps('cpf')}
      error={Boolean(touched.cpf && errors.cpf)}
      helperText={touched.cpf && errors.cpf}
        
      />

    </Box>


     <TextField
            fullWidth
            autoComplete="current-senha"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            variant="standard"
            {...getFieldProps('senha')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.senha && errors.senha)}
            helperText={touched.senha && errors.senha}
          /> 
    <br />     

        <label style={{marginTop:"2px",fontSize:"100%",color:"red"}}>
        {validaremail}  
        <br />
        {validarcpfcnpj}
        </label>

        <br />  
 
    </Stack>

    </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>

    
        </Stack>
        

        <Card className='tabela-vt'>
        <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              listaUsuarios={entidades}
              onRadioChange={handleRadioChange} 
            />
          <UserListToolbar
            numSelected={selected.length}//1
            //numSelected={((filteredUsers.length-CountAdm)-selected.length)}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length-CountAdm}//1
                  //rowCount={selected.length}//2
                  //rowCount={selected.length}//3
                  numSelected={selected.length}//1
                  //numSelected={(selected.length)}//2
                 // numSelected={USERLIST.length}//3
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, cpfcnpj, perfil, email, avatarUrl,crm} = row;
                     // const isItemSelected = selected.indexOf(name) !== -1;
                      //const isItemSelected = selected.indexOf(cpfcnpj) !== -1;
                      const isItemSelected = selected.indexOf(cpfcnpj) !== -1;


                     
                      return (

                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                       {/*<TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell>*/}
                          {/*imagem dos avatares */}
                          <TableCell component="th" scope="row" padding="10px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap >
                                {(cpfcnpj !== undefined && 
                                    (
                                      (cpfcnpj.length === 11 && formatCpf(cpfcnpj)) 
                                      || 
                                      (cpfcnpj.length === 10 && formatCpf("0"+cpfcnpj)) 
                                      || 
                                      (formatCnpj(cpfcnpj))
                                    )
                                )
                                ||
                                cpfcnpj
                                }
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{(perfil !== "Administrador" && "Cooperado")|| (perfil)}</TableCell>
                         
                          <TableCell align="center">
                            {(perfil === "Administrador" &&
                             <UserMoreMenu value={row} />
                              )
                              ||
                             (
                              <Label
                              variant="ghost"
                              color={(isItemSelected === true && 'error') || ('success')}
                            >
                              {(isItemSelected === false && 'Liberado') || ('Bloqueado')}
                            </Label>
                             )
                             }
                          </TableCell>

                          <TableCell align="center" padding="checkbox">
                            {(perfil === "Administrador" && <></>)
                            ||
                            (<Checkbox
                              checked={isItemSelected}
                              onChange={(event) => clickBlock(event, cpfcnpj, name, crm)}
                            />)}
                            
                          </TableCell>

                        </TableRow>
                        
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                


                {isUserNotFound && (
                  
                 
                  <TableBody> {/* mensagem Not found */}
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

              </Table>
            </TableContainer>

          

          </Scrollbar>

          <BlockUI blocking={blocking} />

{/*Mostrar quantidade por tela e navegar entre as quantidades*/}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />



        </Card>
      </Container>


    </Page>

  
      </MainStyle>
    </RootStyle>
   
  );

}