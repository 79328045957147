import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <RootStyle title="404 Page Not Found | ASSEMBLEIACOOP">
      <Container className='conteudo-geral'>
        <MotionContainer initial="initial" open>
          <Box  sx={{ maxWidth: 680, margin: 'auto', textAlign: 'center' }}>
            <motion.div className='titulo-geral' variants={varBounceIn}>
              <h3>              Desculpe, página não encontrada! 
              </h3>
            </motion.div>
            <Typography variant='p'>
            Não foi possível encontrar a página que você está procurando. Talvez você tenha digitado incorretamente o URL?
            Certifique-se de verificar a ortografia.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/404/14illustration_404.png"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button className='btn-cinza centraliza-btn'  to="/" size="large" variant="contained" component={RouterLink}>
             voltar para tela inicial
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
