import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
import {
  Stack,
  Container,
  Typography,
  TextField,
  Grid,
  Paper,
  Card,
  CardContent,
  Box,
  Button as Button1,
  FormControl,
  InputLabel,
  InputAdornment,
  Input as Input3
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import checkmarkcircle2 from '@iconify/icons-eva/checkmark-circle-fill';
import clock from '@iconify/icons-eva/clock-fill';
import clock2 from '@iconify/icons-eva/clock-outline';
import closecircle from '@iconify/icons-eva/close-circle-fill';
import person from '@iconify/icons-eva/person-fill';
import archiveoutline from '@iconify/icons-eva/archive-outline';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import Button from 'react-bootstrap/Button';
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
import functions from "../constants/serverFunctions";
import Page from '../components/Page';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import { ListGroup, ListGroupItem } from 'reactstrap';
import Label from '../layouts/css/Label';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker2 from "./DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import {InputGroup, 
  InputGroupAddon, 
  InputGroupText, 
  Input, 
  Button as Button2,
  Row, Col 
} from 'reactstrap';
import TimeField from 'react-simple-timefield';
import { Link as RouterLink} from 'react-router-dom';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import TimePicker2 from "./TimePicker";

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


export default function Home() {


    const token = getItem('token-assembleia-api');
    const IdRoom = getItem('IdRoom'); 
    const institution = getItem('institutionName');
    const IdEntidade = getItem('institutionId');
    const IdUser = getItem('userId');
    const [ListPauta, setListPauta] = useState([]);
    const [DadosReuniao, setDadosReuniao] = useState([]);
    const [open, setOpen] = useState(false);
   const { setIsAuthenticated } = useContext(Auth);


//Consulta dados da reuniao
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.records === 1){

        axios
        .get(functions.assembleia.BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_ADM+"/"+institution+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
          .then((res) => {
        (res.data.records !== 0 && setDadosReuniao(res.data.result.recordset))
          })
          .catch((err) => {
          });

    }

  }); 


  const interval =  window.setInterval(() => {

    axios
    .get(functions.assembleia.BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_ADM+"/"+institution+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          //toast.warn('Sessão expirada!');
        }else{
          (res.data.records !== 0 && setDadosReuniao(res.data.result.recordset))
        }
      })
      .catch((err) => {
      });
      
  }, 5000);
  
  return () => clearInterval(interval);

}, [IdEntidade,IdUser,institution,IdRoom,token]);


//Consulta Intens da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        //toast.warn('Sessão expirada!');
      }else{
   (res.data.records !== 0 && setListPauta(res.data.result.recordset))
      }
    })
    .catch((err) => {
    });

    const interval =  window.setInterval(() => {

      axios
      .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_REUNIAO+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
        .then((res) => {
          if(res.data.result === "Token expirado"){
            logout();
            setIsAuthenticated(false);
            //toast.warn('Sessão expirada!');
          }else{
            (res.data.records !== 0 && setListPauta(res.data.result.recordset))
          }
        })
        .catch((err) => {
        });
        
    }, 5000);

    return () => clearInterval(interval);


}, [IdRoom,token]);





//mapeia a lista da pauta
const LISTAPAUTA = ListPauta.map((Lista, index) => ({

  idItem: Lista.idItem,
  itemPauta: Lista.itemPauta,
  assembleia: Lista.assembleia,
  idAssembleia: Lista.idAssembleia,
  entidade: Lista.entidade,
  idEntidade: Lista.idEntidade,
  data: Lista.data,
  primeira_chamada: Lista.primeira_chamada,
  segunda_chamada: Lista.segunda_chamada,
  terceira_chamada: Lista.terceira_chamada,
  tipoItem: Lista.tipoItem,
  configuracao: Lista.configuracao,
  idConfiguracao: Lista.idConfiguracao,
  tipoEleicao: Lista.tipoEleicao,
  quantidade_candidatos:Lista.quantidade_candidatos,
  quantidadeExataCandidatos:Lista.quantidadeExataCandidatos,
  Quant_proposta:Lista.Quant_proposta,
  voto_branco:Lista.voto_branco,
  codigoItem: Lista.codigo,
  horarioInicial: Lista.horarioInicial,
  horarioFinal: Lista.horarioFinal

  }));


//mapeia a dados da reuniao
const LISTADADOS = DadosReuniao.map((Dado, index) => ({
  ID_Assembleia: Dado.ID_Assembleia,
  Titulo_Assembleia: Dado.Titulo_Assembleia,
  Data_Assembleia: Dado.Data_Assembleia,
  Link_Assembleia: Dado.Link_Assembleia,
  Horario_PrimeiraChamada: Dado.Horario_PrimeiraChamada,
  Horario_SegundaChamada: Dado.Horario_SegundaChamada,
  Horario_TerceiraChamada: Dado.Horario_TerceiraChamada,
  Descricao_Assembleia: Dado.Descricao_Assembleia,
  DataHoraInicio: Dado.DataInicio_Assembleia,
  DataHoraFim: Dado.DataFim_Assembleia,
  ID_UsuarioIniciou_Assembleia: Dado.ID_UsuarioIniciou_Assembleia,
  ID_UsuarioEncerrou_Assembleia: Dado.ID_UsuarioEncerrou_Assembleia,
  ID_Entidade_Assembleia: Dado.ID_Entidade_Assembleia,
  Entidade_Assembleia: Dado.Entidade_Assembleia,
  Arquivada: Dado.Assembleia_Arquivada,
  presentes1: Dado.presentes1,
  presentes2: Dado.presentes2,
  presentes3: Dado.presentes3,
  TimerInicio: Dado.TimerInicio,
  TimerFim: Dado.TimerFim

}));




    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const LoginSchema = Yup.object().shape({
     });
  
    const formik = useFormik({
      initialValues: {
        nome: ''
      },
     
      validationSchema: LoginSchema,
      onSubmit: async () => {
      }
    });


    const { 
    
      handleSubmit, 
     
    } = formik;




  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} value={LISTADADOS} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      
        <MainStyle>
        <Page title="Reuniões | ASSEMBLEIACOOP">
          <Container>
   
            <Grid container spacing={1}  >

                      {LISTADADOS.map((pauta, id) => (
                        <DADOSTOPO key={id} {...pauta} />       
                      ))}
              
            </Grid>
            <br />

          </Container>
        </Page>

         
         
<Grid >
    <Card>
      <CardContent>

        <div>
          {LISTADADOS.map((pauta, id) => (
            <TimerDetail key={id} {...pauta} />       
          ))}
        </div>
         
      </CardContent>
   </Card>
</Grid>


<br />

<Grid>
  <Card>
 

<Grid>

<Grid className='conteudo-home'>

<div className='titulo-home-espaco'>
<div className='titulo-novo-item'>
    <h4>Itens da Pauta </h4>
</div>

<div className='btn-novo-item'>
    <Button1 className="btn-success" 
           variant="contained"
           component={RouterLink}
           to="#"
           startIcon={<Icon icon={plusFill} />}
           onClick={handleShow}
           style={{padding:"2px 10px"}}
           >
             Novo Item
          </Button1>
</div>
</div>
</Grid>

</Grid>




<Modal visible style={{ zIndex: 1200}}
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Qual novo item de pauta deseja criar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <Container>
          <Row>
            <Col xs={12} md={6} >


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/proposta-de-negocio.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box className='titulo-votacao espaco-modal' sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Proposta
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Votação de uma ou mais propostas, estilo aclamação.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateProposta"
            variant="contained"
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>
            </Col>


            <Col xs={6} md={6}>


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack 
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/votacao-eletronica.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box className='titulo-votacao espaco-modal' >
            <Typography gutterBottom variant="h6">
             Eleição
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Eleição de candidatura individual ou por chapa eleitoral.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateEleicao"
            variant="contained"
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>

            </Col>
          </Row>

          
        </Container>



        </Modal.Body>


        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
         
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
       
</Modal>









    <Card className="scrollbar scrollbar-primary  mt-3 " style={{  maxHeight: "300px", minHeight: "300px" }}>

    <div >
    {LISTAPAUTA.map((pauta, id) => (
                <ListapautaDetail key={id} {...pauta} />       
        ))}
    </div>

    </Card>
  </Card>
</Grid>


      
        </MainStyle>
    </RootStyle>


  );


}





//Lista dos itens da pauta
const ListapautaDetail = (props) => {

const
  {idItem,
  itemPauta,
  tipoItem,
  horarioInicial,
  horarioFinal}
= props;



  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));



  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdProps', IdPropsSelect);
  
  } 


  return (
    <>


<ListGroup flush>
<RouterLink onClick={(e) => PropsClick(idItem, e)} to={(tipoItem === "Proposta" && "/proposals")||"elections"} style={{textDecoration:"none",color:"#000000"}}>
        <ListGroupItem id={idItem} action style={{border:"1px"}}>
          
  <Grid container spacing={2}>     
        <Grid item xs={7} style={{fontSize:"13px"}}>
          {itemPauta}
        </Grid>  

        <Grid item xs={3} style={{fontSize:"13px"}}>
        <Label
          variant="filled"
          color={(tipoItem === 'Proposta' && 'info') || 'warning'}
          sx={{
            zIndex: 9,
            left: 16,
            position: 'relative',
          }}
        >
          {tipoItem}
        </Label>
        </Grid>

        <Grid item xs={2} style={{fontSize:"12px"}}>
        <Icon icon={(fim < 0 && closecircle) || (horarioInicial === null && clock) || checkmarkcircle2} 
        style={{textDecoration:"none",color:(fim < 0 && 'grey') || (horarioInicial === null && '#0d6efd') || 'green'}}
        />
        <label style={{fontSize: "12px",color:(fim < 0 && 'grey') || (horarioInicial === null && '#0d6efd') || 'green'}}>  
        {(fim < 0 && " Finalizado") || (horarioInicial === null && " Em Breve") || " Liberada"}
        </label>
        </Grid>

  </Grid>      

        </ListGroupItem>
</RouterLink>
</ListGroup>


    </>
  );
};




//Horarios chamadas da reuniao
const TimerDetail = (props) => {

const{
  Horario_PrimeiraChamada,
  Horario_SegundaChamada,
  Horario_TerceiraChamada,
  presentes1,
  presentes2,
  presentes3
}= props;
  
  
const time1 = moment.utc(Horario_PrimeiraChamada).format('HH:mm');
const time2 = moment.utc(Horario_SegundaChamada).format('HH:mm');
const time3 = moment.utc(Horario_TerceiraChamada).format('HH:mm');  
   
  
  
    return (
      <>

<div className='info-home'>
<Grid container spacing={2}>
         
         <Grid item xs={4.2} style={{fontSize:"15px"}}>
           <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', paddingBottom:"10px" }}>
             <Box sx={{ mb: 0.5 }}><b>{"Chamada"}</b></Box>
             <Box sx={{ mb: 0.5 }}>
             <Icon icon={clock2} />
               {" Horário da Chamada"}
              </Box>
             <Box sx={{ mb: 0.5 }}>
             <Icon icon={person} />
             {" Usuários presentes"}
             </Box>
          </Paper>
         </Grid>
        
<Grid item xs={2.6} style={{fontSize:"15px"}}>
     <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', paddingBottom:"10px" }}>
      <Box sx={{ mb: 0.5 }}><b>{"1° Chamada"}</b></Box>
       <Box sx={{ mb: 0.5 }}>{time1}</Box>
       <Box sx={{ mb: 0.5 }}>{presentes1}</Box>  
     </Paper>
</Grid>
<Grid item xs={2.6} style={{fontSize:"15px"}}>
     <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', paddingBottom:"10px" }}>
      <Box sx={{ mb: 0.5 }}><b>{"2° Chamada"}</b></Box>
       <Box sx={{ mb: 0.5 }}>{time2}</Box>
       <Box sx={{ mb: 0.5 }}>{presentes2}</Box>  
     </Paper>
</Grid>
<Grid  item xs={2.6} style={{fontSize:"15px"}}>
     <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', paddingBottom:"10px" }}>
      <Box sx={{ mb: 0.5 }}><b>{"3° Chamada"}</b></Box>
       <Box sx={{ mb: 0.5 }}>{time3}</Box>
       <Box sx={{ mb: 0.5 }}>{presentes3}</Box>  
     </Paper>
</Grid>
  
  </Grid>
  
  </div>
  
      </>
    );
  };





//botao arquivar
const arquivarClick = (id) => {
  const token = getItem('token-assembleia-api');

  axios.post(functions.assembleia.ARQUIVAR_ASSEMBLEIA, 
    {id:id},
    { headers: {"Authorization" : `${token}`} }
    )
    .then((response) => {
   toast.success('Reunião Arquivada!');
   window.location.reload(false);
    });

}


//botao desarquivar
const desarquivarClick = (id) => {
  const token = getItem('token-assembleia-api');

 axios.post(functions.assembleia.DESARQUIVAR_ASSEMBLEIA, 
    {id:id},
    { headers: {"Authorization" : `${token}`} })
    .then((response) => {
      toast.success('Reunião desarquivada!');
      window.location.reload(false);
    });

}




  const DADOSTOPO = (props) => {

    const{
      ID_Assembleia,
      Titulo_Assembleia,
      Data_Assembleia,
      Link_Assembleia,
      Horario_PrimeiraChamada,
      Horario_SegundaChamada,
      Horario_TerceiraChamada,
      Descricao_Assembleia,
      Arquivada,
      TimerInicio,
      TimerFim

    }= props;

    const token = getItem('token-assembleia-api');
      
    const DataReuiao = moment.utc(Data_Assembleia).format('DD/MM/YYYY');

   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const date = Data_Assembleia;
    //const format = "YYYY-MM-DD";
    //const timezone = "America/Sao_Paulo";
    //const dateMoment = moment.tz(date, format, timezone);
    const dateMoment = moment(date).utc().format('YYYY-MM-DD'); 

    const time1 = moment.utc(Horario_PrimeiraChamada).format('HH:mm');
    const time2 = moment.utc(Horario_SegundaChamada).format('HH:mm');
    const time3 = moment.utc(Horario_TerceiraChamada).format('HH:mm');

    const [value1] = useState(time1);
    const [value2] = useState(time2);
    const [value3] = useState(time3);



  const LoginSchema = Yup.object().shape({
    titulo: Yup.string().required('campo obrigatória'),
    data: Yup.date().required('Campo obrigatorio'),

    primeira_chamada: Yup
    .string()
    .required("Campo Obrigatório")
    .test("é maior", "Favor informar horário", function(value) {
     return value !== "00:00";
    }),
    segunda_chamada: Yup
    .string()
    .required("campo obrigatório")
    .test("e maior", "Campo deve ser superior ao anterior", function(value) {
      const { primeira_chamada } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(primeira_chamada, "HH:mm"));
    }),
    terceira_chamada: Yup
    .string()
    .required("campo obrigatório")
    .test("e maior", "Campo deve ser superior ao anterior", function(value) {
      const { segunda_chamada } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(segunda_chamada, "HH:mm"));
    })

   });

  const formik = useFormik({
    initialValues: {
      titulo: Titulo_Assembleia,
      data: dateMoment,
      link_transmissao: Link_Assembleia,
      primeira_chamada: value1,
      segunda_chamada: value2,
      terceira_chamada: value3,
      descricao: Descricao_Assembleia,
      id: ID_Assembleia
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


   axios.post(functions.assembleia.ATUALIZAR_ASSEMBLEIA, 
      values,
      { headers: {"Authorization" : `${token}`} }
      )
      .then((response) => {
        toast.success('Reunião atualizada com sucesso!');
       window.location.reload(false);
      })
      .catch((err) => {
      });         

    }
  });


   
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  const [values2, setValues2] = React.useState({
    amount: ''
  });

  const handleChange = (prop) => (event) => {
    setValues2({ ...values2, [prop]: event.target.value });
  };
      
      
        return (
          <>

          <div className='topo-home'>
            <div className='conteudo-topo-home'>
              <Grid item xs={12} sm={9} style={{paddingTop: "0px"}}>
              <Stack sx={{ mb: 0 }}>
                      <Typography variant="h4" gutterBottom>
                      {Titulo_Assembleia}
                      </Typography>
                      <Typography sx={{fontSize: "15px", color: 'text.secondary' }}>
                      {"Realização: "} {DataReuiao}
                        </Typography>
                        <Typography sx={{fontSize: "15px", color: 'text.secondary' }}>
                        {"Link da Reunião:"}&ensp; <a href={"https://"+Link_Assembleia} target="_blank" style={{color:"blue"}}>{"https://"+Link_Assembleia}</a>
                        </Typography>
                        <Typography sx={{fontSize: "15px", color: 'text.secondary' }}>
                        {"Descrição da Reunião:"}&ensp; {Descricao_Assembleia}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>

                  <Icon icon={(Arquivada === 'SIM' && archiveoutline) || (TimerFim < 0 && closecircle) || (TimerInicio >= 0 && clock) || checkmarkcircle2} 
                  style={{fontSize: "17px", textDecoration:"none",color:(Arquivada === 'SIM' && "#f09b0a") || (TimerFim < 0 && 'grey') || (TimerInicio >= 0 && '#0d6efd') || '#27ab55'}}
                  />
                    <label style={{fontSize: "12px", textDecoration:"none",color:(Arquivada === 'SIM' && "#f09b0a") || (TimerFim < 0 && 'grey') || (TimerInicio >= 0 && '#0d6efd') || '#27ab55'}}>
                      {(Arquivada === 'SIM' && ' Arquivada') || (TimerFim < 0 && ' Encerrada') || (TimerInicio >= 0 && ' Em breve') || ' Liberada'}
                      </label>

                        </Typography>
              </Stack>
              

              </Grid>
            </div>


          <div className='btn-home'> 
            <Button 
            variant={((Arquivada === 'SIM' && "outline-secondary") || (TimerFim < 0 && "outline-secondary") || "outline-success")} 
            onClick={(Arquivada === 'SIM' && ((e) => desarquivarClick(ID_Assembleia, e))) || (TimerFim < 0 && ((e) => arquivarClick(ID_Assembleia, e) )) || handleShow}
            >
              {((Arquivada === 'SIM' && 'Desarquivar') || (TimerFim < 0 && 'Arquivar') || 'Editar')}
            
            </Button>
          </div>

    </div>

 





<Modal 
    visible style={{ zIndex: 1200}}
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
>


  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Editar Reunião</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: 410, overflow: 'scroll'}}>


      <Stack spacing={3}>

        <TextField 
        id="titulo" 
        variant="standard"
        label="Nome da Reunião*"
        {...getFieldProps('titulo')}
        error={Boolean(touched.titulo && errors.titulo)}
        helperText={touched.titulo && errors.titulo}
        />



{((TimerInicio >= 0 && 
<DatePicker2 id="data" name="data" />)
  ||
  (<InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{fontSize:"x-large"}}>
          {<Icon icon={calendarFill} className='cor-icones' />}
          </InputGroupText>
        </InputGroupAddon>
        <Input value={moment(Data_Assembleia).utc().format('DD/MM/YYYY')} 
          placeholder="dd/mm/aaaa*" 
          style={{flex: "0.4 1 auto"}}
          disabled={true}
          />
      </InputGroup>)

  )}
  

  <label style={{marginTop:"0px",fontSize:"75%"}}>
    A data não pode ser anterior que a sua data de hoje.  
  </label> 



{/*
        <TextField 
        style={{marginTop:"10px"}}
        id="link_transmissao" 
        label="Link da Reunião*" 
        variant="standard"
        {...getFieldProps('link_transmissao')}
        error={Boolean(touched.link_transmissao && errors.link_transmissao)}
        helperText={touched.link_transmissao && errors.link_transmissao}
         />
*/}


<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard" style={{width:"150%"}}>
          <InputLabel htmlFor="standard-adornment-amount">Link da Reunião</InputLabel>
          <Input3
            id="link_transmissao" 
            value={values2.amount}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start">https://</InputAdornment>}
            {...getFieldProps('link_transmissao')}
            error={Boolean(touched.link_transmissao && errors.link_transmissao)}
            helperText={touched.link_transmissao && errors.link_transmissao}
          />
           <label style={{marginTop:"0px",fontSize:"75%"}}>
              Exemplo: https://siteexemplo.com.br  
            </label> 
        </FormControl>
      </div>
    </Box>
         

        <label>
           <h5>Defina os horários para chamada</h5>
        </label> 


  <Grid container spacing={1} style={{marginTop:"10px"}} >
    
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>

 { /*    <div>
      {((TimerInicio >= 0 && 
        <TimeField 
        id="primeira_chamada" 
        input={<TextField label="1º Chamada*" variant="standard"/>}  
        {...getFieldProps('primeira_chamada')}
        error={Boolean(touched.primeira_chamada && errors.primeira_chamada)}
        helperText={touched.primeira_chamada && errors.primeira_chamada}   
        />
      )
      ||
      (<InputGroup>
      <label style={{fontSize: "14px"}}>  
          1º Chamada*
        </label>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{fontSize:"x-large"}}>
          {<Icon icon={clock} />}
          </InputGroupText>
        </InputGroupAddon>
        <Input value={time1} 
          placeholder="HH:mm*" 
         disabled={true}
          />
        </InputGroup>)
      )}
      </div>
      */}

      <div>
      <label style={{fontSize: "14px"}}>  
      1º Chamada*
      </label>

      {((TimerInicio >= 0 && 

        <TimePicker2 id="primeira_chamada" name="primeira_chamada" />)
||

          (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time1} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}

      </div>

    </Grid>

    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
    {/* 
      <div>
      {((TimerInicio >= 0 && 
     <TimeField 
     id="segunda_chamada" 
     input={<TextField label="2º Chamada*" variant="standard"/>}  
     {...getFieldProps('segunda_chamada')}
     error={Boolean(touched.segunda_chamada && errors.segunda_chamada)}
     helperText={touched.segunda_chamada && errors.segunda_chamada}   
     />
 )
      ||
      (<InputGroup>
      <label style={{fontSize: "14px"}}>  
          2º Chamada*
        </label>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{fontSize:"x-large"}}>
          {<Icon icon={clock} />}
          </InputGroupText>
        </InputGroupAddon>
        <Input value={time2} 
          placeholder="HH:mm*" 
         disabled={true}
          />
        </InputGroup>)
       
      )}
      </div>
      */}

<div>
        <label style={{fontSize: "14px"}}>  
          2º Chamada*
        </label>
        {((TimerInicio >= 0 && 
      <TimePicker2 id="segunda_chamada" name="segunda_chamada" />)
      ||

      (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time2} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}
      </div>

    </Grid>
   
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
     
     {/* <div>
      {(
        (TimerInicio >= 0 && 
          <TimeField 
          id="terceira_chamada" 
          input={<TextField label="3º Chamada*" variant="standard"/>}  
          {...getFieldProps('terceira_chamada')}
          error={Boolean(touched.terceira_chamada && errors.terceira_chamada)}
          helperText={touched.terceira_chamada && errors.terceira_chamada}   
      />
      )
        ||
        (
        <InputGroup>
        <label style={{fontSize: "14px"}}>  
          3º Chamada*
        </label>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time3} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)
       
      )}
      </div>*/}
      <div>
        <label style={{fontSize: "14px"}}>  
          3º Chamada*
        </label>
        {((TimerInicio >= 0 && 
        <TimePicker2 id="terceira_chamada" name="terceira_chamada" />)
        ||

        (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time3} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}
      </div>

    </Grid>

</Grid>

<br />


        <div class="form-group" style={{marginTop:"10px"}}>
        <label for="descricao">Descrição</label>
        <textarea
          class="form-control"
          id="descricao"
          rows="5"
          {...getFieldProps('descricao')}
         >
         </textarea>
         </div>
 
    </Stack>

    </Modal.Body>
        
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
  

</Modal> 



      
          </>
        );
      };
