import { useRef, useState } from 'react';
import { alpha } from '@material-ui/core/styles';
import { 
  Box, 
  MenuItem, 
  ListItemIcon, 
  ListItemText, 
  IconButton,
  Stack,
  TextField,
  InputAdornment
} from '@material-ui/core';

import MenuPopover from '../../MenuPopover';
import morevertical from '@iconify/icons-eva/more-vertical-outline';
import edit from '@iconify/icons-eva/edit-2-fill';
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import axios from "axios";
import { toast } from 'react-toastify';
import {getItem } from '../../services/LocaleStorage';
import functions from "../../../constants/serverFunctions";
import CpfCnpj from "../../../components/_dashboard/user/cpf-cnpj-mask";

// ----------------------------------------------------------------------

const MENU = [

  {value: 'edit',
    label: 'Editar',
    icon: edit
  }

];


// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {

  const token = getItem('token-assembleia-api');
  const IdUser = getItem('userId');

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose2 = () => setShow(false);
  const handleShow = () => setShow(true);


  const LoginSchema = Yup.object().shape({
    nome: Yup.string().required('campo obrigatória'),
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
   // cpf: Yup.string().required('campo obrigatória'),
   });

  const formik = useFormik({
    initialValues: {
      id: props.value.id,
      nome: props.value.name,
      email: props.value.email,
      crm: '',
      cpf: props.value.cpfcnpj,
      senha: '',
      perfil: 2,
      fk_UsuarioAlter: IdUser
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {
  



    axios.post(functions.usuario.ATUALIZAR_USUARIO, 
      values,
      { headers: {"Authorization" : `${token}`} }
      )
    .then((res) => {

      toast.success('Usuário alterado com sucesso!');
      window.location.reload(false);

         /* ((props.value.id_PerfilUsuario !== values.perfil 
            && 
           axios.post(functions.entidadeUsuario.ATUALIZAR_ENTIDADE_USUARIO, 
            {id: props.value.id_entidadeUsuario,
              fk_perfil_usuario: values.perfil},
              { headers: {"Authorization" : `${token}`} }
              )
            .then((response) => {
              toast.success('Usuário alterado com sucesso!');
              window.location.reload(false);
              })

             ) 
            ||
            (window.location.reload(false))
            )
            */

     
     });
 
            

    }
  });



  const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps 
  } = formik;


  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  const [cpfCnpj, setCpfCnpj] = useState("");
  const [setMask] = useState("");



  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        component={Icon}
        icon={morevertical}
        sx={{
          mr: 1,
          width: 34,
          height: 34,

          
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
       
       
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }} onClick={handleShow}>
          {MENU.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === MENU[0].value}
              onClick={handleClose}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
               
                <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
                }}
                />

              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }} >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>







      <Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Editar usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: 410, overflow: 'scroll'}}>


        <Stack spacing={3}>

<TextField 
id="nome" 
variant="standard"
label="Nome Completo*"
{...getFieldProps('nome')}
error={Boolean(touched.nome && errors.nome)}
helperText={touched.nome && errors.nome}
/>


<TextField 
style={{marginTop:"10px"}}
id="email" 
label="E-mail*" 
variant="standard"
{...getFieldProps('email')}
error={Boolean(touched.email && errors.email)}
helperText={touched.email && errors.email}
 />

 

<Box
component="form"
sx={{
'& > :not(style)': { m: 1, width: '20ch' },
}}
noValidate
autoComplete="off"
>

    <CpfCnpj
      label="CPF / CNPJ"
      id="cpf"
      value={cpfCnpj}
      onChange={(event, type) => {
          setCpfCnpj(event.target.value);
          setMask(type === "CPF");
        }}
       {...getFieldProps('cpf')}
      error={Boolean(touched.cpf && errors.cpf)}
      helperText={touched.cpf && errors.cpf}        
      />

</Box>


<TextField
    fullWidth
    autoComplete="current-senha"
    type={showPassword ? 'text' : 'password'}
    label="Nova Senha"
    variant="standard"
    helperText="Para manter senha atual, favor deixar campo em branco"
    {...getFieldProps('senha')}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleShowPassword} edge="end">
            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
          </IconButton>
        </InputAdornment>
      )
    }}
  /> 
{
/*
<Box
component="form"
sx={{
'& > :not(style)': { m: 1, width: '20ch' },
}}
noValidate
autoComplete="off"
>
<TextField
          id="perfil"
          select
          label="Tipo de Perfil"
          value={currency}
          onChange={handleChange}
          variant="standard"
          {...getFieldProps('perfil')}
        >
          {valselects.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
</TextField>
</Box>
 */}       

</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose2}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>


    </>
  );
}
