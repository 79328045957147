import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-outline';

const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const locale = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}


export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  //const [startDate, setStartDate] = useState(new Date())

  return (

    <DatePicker
      {...field}
      {...props}

      locale={locale}
     
     customInput={
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{fontSize:"x-large"}}>
          {<Icon icon={calendarFill} />}
          </InputGroupText>
        </InputGroupAddon>
        <Input value={
         ((field.value && (moment(field.value).format('DD/MM/yyyy'))) || "")
          } 
          placeholder="dd/mm/aaaa*" 
          style={{flex: "0.4 1 auto"}}
          />
      </InputGroup>
    }

    minDate={new Date()}
     selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, moment(val).format('yyyy-MM-DD'));
      }}
      helperText={"teste"}
    />


  );
};

export default DatePickerField;
