import jwtDecode from 'jwt-decode';
import { getItem, removeItem } from './LocaleStorage';

export function hasAuthenticated() {
  const token = getItem('token-assembleia-api');
  const result = token ? tokenIsValid(token) : false;

  
  if (false === result) {
      removeItem('token-assembleia-api');
  }

  return result;
}



export function logout() {
 // removeItem('token-assembleia-api');
 localStorage.clear();
}


function tokenIsValid(token) {
  const { exp: expiration } = jwtDecode(token);

  if (expiration * 1000 > new Date().getTime()) {
      return true;
  }

  return false;
}