import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
import Page from '../components/Page';

import { 
  Grid, 
  Stack,
  Button as Button1,
  Container,
  Typography, 
  Box,
  Card,
  Divider,
  TextField,
  CardContent,
  IconButton,
  Tooltip,
  Paper
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Link as RouterLink} from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import alertcircleoutline from '@iconify/icons-eva/alert-circle-outline';
import edit from '@iconify/icons-eva/edit-2-fill';
import trash2 from '@iconify/icons-eva/trash-2-outline';
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form, FormikProvider} from 'formik';
import * as Yup from 'yup';
import {Button as Button2} from 'reactstrap';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import functions from "../constants/serverFunctions";
import Scrollbar from '../components/Scrollbar';
import Button from 'react-bootstrap/Button';
import "react-widgets/styles.css";
import TimeField from 'react-simple-timefield';
import moment from 'moment-timezone';
import MyTimer from "./VoteTimer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


export default function DashboardLayout() {

  const token = getItem('token-assembleia-api');
  const IdItem= getItem('IdProps'); 
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const { setIsAuthenticated } = useContext(Auth);
  const [open, setOpen] = useState(false);
  const [ListProps, setListProps] = useState([]);
  const [QuantListProps, setQuantListProps] = useState([]);
  const [QuantVotoProps, setQuantVotoProps] = useState([]);
  const [QuantvotosBranco, setQuantvotosBranco] = useState([]);
  const [DadosItem, setDadosItem] = useState([]);
  const [TimerItem, setTimerItem] = useState();
  const [StatusTimerItem, setStatusTimerItem] = useState();
  
 



// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.records === 1){

  axios
  .get(functions.itemPauta.BUSCAR_ITEM_PROP_PAUTA_REUNIAO_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        //toast.warn('Sessão expirada!');
      }else{
        if(res.data.records !== 0){ 
          setDadosItem(res.data.result.recordset)
          setTimerItem(res.data.result.recordset[0].horarioInicial)
          setStatusTimerItem(res.data.result.recordset[0].TimerVotacao)
          setQuantVotoProps(res.data.result.recordset[0].votositem)
          setQuantvotosBranco(res.data.result.recordset[0].votosBrancoChapa)
         }
      }

       
  
    })
    .catch((err) => {
    });


    }/*else{
      logout();
      setIsAuthenticated(false);
      toast.error('Token inválido!');
    }*/

    }); 




  const interval =  window.setInterval(() => {

      axios
  .get(functions.itemPauta.BUSCAR_ITEM_PROP_PAUTA_REUNIAO_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        //toast.warn('Sessão expirada!');
      }else{
        if(res.data.records !== 0){ 
          setDadosItem(res.data.result.recordset)
          setTimerItem(res.data.result.recordset[0].horarioInicial)
          setStatusTimerItem(res.data.result.recordset[0].TimerVotacao)
          setQuantVotoProps(res.data.result.recordset[0].votositem)
          setQuantvotosBranco(res.data.result.recordset[0].votosBrancoChapa)
         }
      }

  
    })
    .catch((err) => {
    });
        
    }, 5000);

    return () => clearInterval(interval);


    
}, [IdEntidade,IdUser,IdItem,token,setIsAuthenticated]);




//mapeia a consulta
const DADOSITEMSELECT = DadosItem.map((DadoItem, index) => ({

  idItem: DadoItem.idItem,
  itemPauta: DadoItem.itemPauta,
  //idEntidade: DadoItem.idEntidade,
  //entidade: DadoItem.entidade,
  //idAssembleia: DadoItem.idAssembleia,
  //assembleia: DadoItem.assembleia,
  //dataAssembleia: DadoItem.data,
  tipoItem: DadoItem.tipoItem,
  Quant_proposta: DadoItem.Quant_proposta,
  codigo: DadoItem.codigo,
  horarioInicial: DadoItem.horarioInicial,
  horarioFinal: DadoItem.horarioFinal,
  presentes: DadoItem.presentes,
  votositem:DadoItem.votositem,
  TimerInicio: DadoItem.TimerInicio,
  TimerFim: DadoItem.TimerFim,
  TimerVotacao: DadoItem.TimerVotacao,
  //TotalVotos: DadoItem.TotalVotos,
  //countVotosMin: DadoItem.countVotosMin,
  //quantidadeExataCandidatos: DadoItem.quantidadeExataCandidatos,
  //quantidade_candidatos: DadoItem.quantidade_candidatos,
  //tipoEleicao: DadoItem.tipoEleicao,
  //voto_branco: DadoItem.voto_branco,
  //votosBrancoCandidatoInd: DadoItem.votosBrancoCandidatoInd,
  votosBrancoChapa: DadoItem.votosBrancoChapa

  }));









//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
          setIsAuthenticated(false);
         // toast.warn('Sessão Expirada!');
      }else{
        (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
        setQuantListProps(res.data.records)
      }
  
    })
    .catch((err) => {
    });
}, [IdItem,token]);




//mapeia a consulta
const LISTAPROPOSTA = ListProps.map((ListaProp, index) => ({

  //entidade: ListaProp.entidade,
  //idEntidade: ListaProp.idEntidade,
  //assembleia: ListaProp.assembleia,
  //idAssembleia: ListaProp.idAssembleia,
  //data: ListaProp.data,
  //idItem: ListaProp.idItem,
  //itemPauta: ListaProp.itemPauta,
  identificador: ListaProp.identificador,
  idProposta: ListaProp.idProposta,
  descricaoProposta: ListaProp.descricaoProposta,
  //horarioInicial: ListaProp.horarioInicial,
  //horarioFinal: ListaProp.horarioFinal,
  votosSim: ListaProp.votosSim,
  votosNao: ListaProp.votosNao,
  votosNulo: ListaProp.votosNulo,
  quantVotoProp: ListaProp.quantVotoProp,
  //votositem: ListaProp.votositem,


  }));






  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      

      
        <MainStyle>

        
          <Page title="Reuniões | ASSEMBLEIACOOP">
            <Container>
            
                  <Grid container spacing={1}  >
                      {DADOSITEMSELECT.map((dadoitem, id) => (
                        <DADOSTOPO key={id} {...dadoitem} />       
                      ))}
                  </Grid>

            </Container>
          </Page>

        {/*  <Button
                className="btn-cinza alinhamento-btn" 
                component={RouterLink} 
                to="room" >
               <Icon icon={cornerupleft}/>&nbsp; Voltar
            </Button>*/}
            




<Grid container spacing={1} style={{marginTop:"10px"}} >

    <Grid item xs={12} sm={6} style={{paddingTop: "0px", marginLeft: '15px'}}>
          <div>

<Stack
alignItems="center"
spacing={1}
sx={{
  p: 2,
  pt: 0,
  borderRadius: 2,
  position: 'relative',  
}}
>

<BotaoNovaProposta value={TimerItem} />

</Stack>


            {LISTAPROPOSTA.map((proposta, id) => (
              <ListaDetail key={id} {...proposta} value={[QuantListProps,StatusTimerItem,QuantVotoProps]} />       
            ))}
          </div>


          {(StatusTimerItem !== null &&
            StatusTimerItem <= 0 &&
              QuantListProps > 1 &&
          <VotoBrancoDetail value={[QuantvotosBranco,QuantVotoProps]} />
          )}

    </Grid>

    <Grid item xs={12} sm={5} style={{paddingTop: "0px", marginLeft: '15px'}}>
          <div>
            {DADOSITEMSELECT.map((proposta, id) => (
              <StatusDetail key={id} {...proposta} />       
            ))}
          </div>
    </Grid>


</Grid>






        </MainStyle>

    </RootStyle>


  );


}



const DADOSTOPO = (props) => {

  const{
  idItem,
  itemPauta,
  tipoItem,
  Quant_proposta,
  horarioInicial,
  horarioFinal,
  TimerInicio,
  TimerFim,
  TimerVotacao
  }= props;

  const token = getItem('token-assembleia-api');
 
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const EncerrarVotacao = (IdItemPauta) => {

    const token = getItem('token-assembleia-api');

    addItem('IdVotationResult', IdItemPauta);
    addItem('ThemeVoteResult', tipoItem);

        axios.post(functions.votacao.ENCERRAR_VOTACAO, 
          {IdItemPauta: IdItemPauta},
          {headers: {"Authorization" : `${token}`} }
        )
      .then((response) => {
            toast.success('votação encerrada com sucesso!');
            window.open('https://assembleiacoop.coop.br/result_votation', '_blank');
            window.location.reload(false);
      });
     
  
  } 




  const LoginSchema = Yup.object({
    tempoDuracao: Yup
      .string()
      .required("Campo Obrigatório")
      .test("é maior", "Favor informar tempo", function(value) {
      return value !== '00:00';
      })
});


  const formik = useFormik({
    initialValues: {
      tempoDuracao: ''
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      var min = 10000;
      var max = 99999;
      var Coditem = Math.floor(Math.random() * (max - min + 1)) + min;
    
      var timeBase = new Date("2014-03-14T" + values.tempoDuracao + ":00");
      var novahora = timeBase.getHours();
      var novominitu = timeBase.getMinutes();
      var time2 = new Date();
      var outraData = new Date();
      outraData.setHours(time2.getHours() + novahora);
      outraData.setMinutes(time2.getMinutes() + novominitu);
    
      const horaAtual =  moment(time2).format('YYYY-MM-DD HH:mm:ss');
      const horaFim =  moment(outraData).format('YYYY-MM-DD HH:mm:ss');
     

      addItem('IdVotationStart', idItem);
      addItem('ThemeVoteStart', tipoItem);

      
   axios.post(functions.votacao.INICIAR_VOTACAO, {
                  idItem: idItem,
                  codigo: Coditem,
                  //horarioInicial: horaAtual,
                  horarioFinal: horaFim
                }, { headers: {"Authorization" : `${token}`} })
          .then((response) => {
          toast.success('Votação iniciada com sucesso!');
          window.open('https://assembleiacoop.coop.br/votation', '_blank');
          window.location.reload(false);
          })
          .catch((err) => {
          });
 
    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const segundos = dataAtual.getSeconds();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':' + segundos;
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));
*/

return (
<>

<Grid item xs={12} sm={8.5} style={{paddingTop: "0px"}}>
<div className = 'topotitulocandidatos' >
  <Stack sx={{ mb: 3 }}>
    <Typography variant="h4" gutterBottom>
    {itemPauta}
    </Typography>
  </Stack>
  </div>
</Grid>


<Grid item xs={12} sm={3} style={{paddingTop: "0px", textAlign: "-webkit-right"}} >
  <Grid item xs={3} style={{maxWidth:"100%"}}>


{
  (TimerInicio >= 0 && 
    <Button variant="outline-secondary" disabled={true}>Iniciar</Button>
  )
  ||
  (TimerFim < 0 &&
    <Button variant="outline-secondary" disabled={true}>Finalizado</Button>
  )
  ||
  (horarioInicial === null &&
      ((Quant_proposta > 0 && 
        <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
      )
      ||
      ( 
      <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
      ))
  )
  ||
  ((TimerVotacao <= 0 && 
    <Button variant="outline-secondary" disabled={true}>Finalizado</Button>)
    ||
    <Button variant="outline-secondary" onClick={() => EncerrarVotacao(idItem)} disabled={false}>Encerrar</Button>
  )




/*(
  (TimerInicio >= 0 && 
  <Button variant="outline-secondary" disabled={true}>Iniciar</Button>
  ) 
  || 
  (
    ((TimerFim < 0 &&
      (horarioInicial === null && 
        <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>
      ))
      ||
      ((TimerVotacao < 0 && 
        <Button variant="outline-secondary" disabled={true}>Finalizado</Button>)
        ||
        <Button variant="outline-secondary" onClick={() => EncerrarVotacao(idItem)} disabled={false}>Encerrar</Button>
      )
    
    )

    ||

    ""

    
    
  )

)*/
}

  </Grid>
</Grid>








<Modal visible style={{ zIndex: 1500}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
>

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Iniciar Votação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={3}>

<label>
Informe a duração da votação
</label>



   <TimeField 
    id="tempoDuracao" 
    input={<TextField label="Tempo de duração" variant="standard" />}  
    {...getFieldProps('tempoDuracao')}
    error={Boolean(touched.tempoDuracao && errors.tempoDuracao)}
    helperText={touched.tempoDuracao && errors.tempoDuracao}   
    //showSeconds                    
    />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      Exemplo: 00:30 para 30 minutos - tempo Máximo 23h:59m
    </label>

        <br />
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>




</>

);

}






const ListaDetail = (props) => {

  const{
    identificador,
    idProposta,
    descricaoProposta,
    votosSim,
    votosNao,
    votosNulo,
    quantVotoProp
  }= props;

  const QuantListProps = props.value[0];
  const TempoVotacao = props.value[1];
  const votositem = props.value[2];
  //const votosBrancoChapa = props.value[3];

 const token = getItem('token-assembleia-api');

  const progressCon = votosSim;
  const progressDis = votosNao;
  const progressAbs = votosNulo;
  const progressUni = quantVotoProp;
  const totalVotos = progressCon+progressDis+progressAbs;
  const progress1 = ((progressCon/totalVotos)*100);
  const progress2 = ((progressDis/totalVotos)*100);
  const progress3 = ((progressAbs/totalVotos)*100);
  const progressT = ((progressUni/votositem)*100);


 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 const [show3, setShow3] = useState(false);
 const handleClose3 = () => setShow3(false);
 const handleShow3 = () => setShow3(true);



 function deleteProp(index) {

    axios.post(functions.chapaProposta.EXCLUIR_POR_ID+"/"+index,
      {id: index},
      { headers: {"Authorization" : `${token}`} }
      )
    .then((res) => {
          toast.success('Proposta apagada!');
          window.location.reload(false);
     });

}   



const LoginSchema = Yup.object().shape({
identificador: Yup.string().required('campo obrigatória'),
descricao: Yup.string().required('campo obrigatória'),

});

const formik = useFormik({
initialValues: {
  id: idProposta,
  identificador: identificador,
  descricao: descricaoProposta
 
},

validationSchema: LoginSchema,
onSubmit: async () => {


if(values.identificador !== identificador || values.descricao !== descricaoProposta){
 
axios.post(functions.chapaProposta.ATUALIZAR_CHAPA_PROPOSTA, 
  values,
  { headers: {"Authorization" : `${token}`} }
  )
.then((res) => {
      toast.success('Proposta alterada com sucesso!');
      window.location.reload(false);
 });

}else{
handleClose();
}


}
});

const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps} = formik;






//QuantListProps === 1 && 

  return(
    <>


{

(TempoVotacao === null &&

<>

<Card>
<CardContent>
<Grid container spacing={2}>

<Grid item xs={9} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <b> {descricaoProposta} {""} </b>
             </Box>
         </Grid>

        
         <Grid item xs={1} style={{fontSize:"25px"}}></Grid>
    
         <Grid item xs={1} style={{fontSize:"25px"}}>
             <Tooltip title={'Editar'}>   
                 <IconButton>
                   <Icon icon={edit} 
                  onClick={handleShow}
                   />
                 </IconButton>
             </Tooltip>
         </Grid>

         <Grid item xs={1} style={{fontSize:"25px"}}>
           <Tooltip title={'Excluir'}>   
             <IconButton>
               <Icon icon={trash2} 
               onClick={handleShow3}
               />
             </IconButton>
           </Tooltip>  
  </Grid>
            
      </Grid>
 </CardContent>   
 </Card>
      <br />


{/* >>>>>>>>>> MODAL EDITAR PROPOSTA <<<<<<<<<<<<<<<<<< */}

<Modal visible style={{ zIndex: 1500}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Editar Proposta</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{overflow: 'scroll'}}>

        <Stack spacing={3}>
          <TextField 
          id="identificador" 
          variant="standard"
          label="Nome do Proponente*"
          {...getFieldProps('identificador')}
          error={Boolean(touched.identificador && errors.identificador)}
          helperText={touched.identificador && errors.identificador}
          />

          <TextField 
          id="descricao" 
          variant="standard"
          label="Descrição da proposta*"
          {...getFieldProps('descricao')}
          error={Boolean(touched.descricao && errors.descricao)}
          helperText={touched.descricao && errors.descricao}
          />

          <br />
        </Stack>

      </Modal.Body>
      <Modal.Footer>
        <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
        </Button2>
        <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
      </Modal.Footer>
    </Form>
  </FormikProvider>
</Modal>

{/* >>>>>>>>>> FIM MODAL EDITAR PROPOSTA <<<<<<<<<<<<<<<<<< */}


{/* Modal Excluir */}

<Modal visible style={{ zIndex: 1200}}
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={"handleSubmit"}>

      <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Excluir</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll', textAlign:'center'}}>


        <Stack spacing={3}>
        <div>
        <Icon icon={alertcircleoutline} width={70} height={70} color="red"/>
        </div>
        <br /> 
        <b>Essa ação não pode ser desfeita. Isso excluirá permanentemente seu recurso.</b>
        <br />
      
        </Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose3}>
            Cancelar
          </Button2>
          <Button2 className="btn-danger" variant="primary" id={idProposta}
          onClick={() => deleteProp(idProposta)}
          >
            Confirmar
        </Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>
     
</>


)



    ||


    (TempoVotacao > 0 && 
<>
    <Card>
    <CardContent>
      <Grid container spacing={2}>
      <Grid item xs={9} >
       <Box sx={{ mb: 0.5 }}>
         <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
         </Box>
       <Box sx={{ mb: 0.5 }}>
       <b> {descricaoProposta} {""} </b>
       </Box>
        </Grid>
         <Grid item xs={1} style={{fontSize:"25px"}}></Grid> 
      </Grid>
    </CardContent>   
    </Card>
    <br />
</>
    )


    ||


    (TempoVotacao <= 0 &&

      (QuantListProps === 1 && 

        <>
        <Card>
        <Scrollbar >
          <Stack className='titulo-resultado-propostapainel'>
            <Typography variant="body2" noWrap>
            <Grid className='espaco-interno' >
                    <Box sx={{ mb: 0.5 }}>
                      <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
                      </Box>
                    <Box sx={{ mb: 0.5 }}>
                    <b> {descricaoProposta} </b>
                    </Box>
                </Grid>
        </Typography>
          </Stack>
        </Scrollbar>
        
        <Divider />
        
        <Grid container spacing={1} style={{marginTop:"10px"}} >
        <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
        <div>
        
        <div className="container">
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progress1}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
              <b>{(progressCon > 0 && progressCon) || ("")} {(progressCon === 1 && "Concordou") || (progressCon === 0 && "Ninguém concordou") || ("Concordaram")}</b>
            </span>
        </div>
        </div>
        
        <div className="container">
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progress2}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
              <b>{(progressDis > 0 && progressDis) || ("")} {(progressDis === 1 && "Discordou") || (progressDis === 0 && "Ninguém discordou") || ("Discordaram")}</b>
            </span>
        </div>
        </div>
        
        <div className="container">
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progress3}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
              <b>{(progressAbs > 0 && progressAbs) || ("")} {(progressAbs === 1 && "Absteve") || (progressAbs === 0 && "Ninguém se absteve") || ("Abstiveram")}</b>
            </span>
        </div>
        </div>
        
        </div>
        </Grid>
        
        </Grid>
        <Box sx={{p: 1, textAlign: 'right' }}></Box>
        </Card>
        <br />
        </>
      )


      ||

      
      (QuantListProps > 1 && 
      
      
        <>
        <Card>
        {(descricaoProposta !== null &&
         
      
         
         <Scrollbar >
          <Stack className='titulo-resultado-propostapainel'>
            <Typography variant="body2" noWrap>
            <Grid className='espaco-interno' >
                    <Box sx={{ mb: 0.5 }}>
                      <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
                      </Box>
                    <Box sx={{ mb: 0.5 }}>
                    <b> {descricaoProposta} </b>
                    </Box>
                </Grid>
        </Typography>
          </Stack>
        </Scrollbar>
        )
        ||
        (
          <Scrollbar >
          <Stack className='titulo-resultado-propostapainel'>
            <Typography variant="body2" noWrap>
            <Grid className='espaco-interno' >
                    <Box sx={{ mb: 0.5 }}>
                      <div className='titulo-autor'><b>Abstenções</b></div>
                      </Box>
                    <Box sx={{ mb: 0.5 }}>
                    <b> {descricaoProposta} </b>
                    </Box>
                </Grid>
        </Typography>
          </Stack>
        </Scrollbar>

        )
        }
        <Divider />
        
        <Grid container spacing={1} style={{marginTop:"0px"}} >
      <Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
      <div>
      <div className="container" style={{maxWidth:"100%"}}>
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
              <b>{(quantVotoProp > 0 && quantVotoProp) || ("")} {(quantVotoProp === 1 && "voto") || (quantVotoProp === 0 && "Nenhum voto") || ("votos")}</b>
            </span>
        </div>
        </div>
        
        </div>
        </Grid>
        
        </Grid>
        </Card>
        <br/>
        </>

      )
    
  

)




    }


    </>
  )
};






const VotoBrancoDetail = (props) => {

const QuantvotosBranco = props.value[0];
const votositem = props.value[1];


const progressT = ((QuantvotosBranco/votositem)*100);


  return(
    <>

      <Card>
          <Scrollbar >
          <Stack className='titulo-resultado-propostapainel'>
            <Typography variant="body2" noWrap>
            <Grid className='espaco-interno' >
         
                </Grid>
        </Typography>
          </Stack>
        </Scrollbar>

        <Divider />
        
        <Grid container spacing={1} style={{marginTop:"0px"}} >
      <Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
      <div>
      <div className="container" style={{maxWidth:"100%"}}>
        <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                <div className="progressbar-liquid"></div>
            </div>
            <span className="progress">
              <b>{(QuantvotosBranco > 0 && QuantvotosBranco) || ("")} {(QuantvotosBranco === 1 && "abstenção") || (QuantvotosBranco === 0 && "Nenhuma abstenção") || ("abstenções")}</b>
            </span>
        </div>
        </div>
        
        </div>
        </Grid>
        
        </Grid>
        </Card>
        <br/>



    </>
        )


}





  
  


const BotaoNovaProposta = (props) => {

 
  const horarioInicial = props.value;
  const token = getItem('token-assembleia-api');
  const idItem = getItem('IdProps');
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  const LoginSchema = Yup.object().shape({
    identificador: Yup.string().required('campo obrigatória'),
    descricao: Yup.string().required('campo obrigatória'),
    
   });
  
  const formik = useFormik({
    initialValues: {
      idItemPauta: idItem,
      identificador: '',
      descricao: ''
     
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {
  

  
    axios.post(functions.chapaProposta.INSERIR_NOVA_CHAPA_PROPOSTA, 
      values,
      { headers: {"Authorization" : `${token}`} }
      )
    .then((res) => {
          toast.success('Proposta criada com sucesso!');
          window.location.reload(false);
     });
  

  
  
    }
  });
  


   const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps 
  } = formik;
  



  return(

 (horarioInicial === null &&   
    <>



<Button1
fullWidth
variant="outlined"
onClick={handleShow1}
style={{textDecoration:"none",color:"#27ab55"}}
disabled={false}
startIcon={<Icon icon={plusFill} />}
>
Nova Proposta
</Button1>



{/* >>>>>>>>>> MODAL NOVA PROPOSTA <<<<<<<<<<<<<<<<<< */}

<Modal visible style={{ zIndex: 1500}}
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Nova Proposta</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{overflow: 'scroll'}}>

        <Stack spacing={3}>
          <TextField 
          id="identificador" 
          variant="standard"
          label="Nome do Proponente*"
          {...getFieldProps('identificador')}
          error={Boolean(touched.identificador && errors.identificador)}
          helperText={touched.identificador && errors.identificador}
          />


          <TextField 
          id="descricao" 
          variant="standard"
          label="Descrição da proposta*"
          {...getFieldProps('descricao')}
          error={Boolean(touched.descricao && errors.descricao)}
          helperText={touched.descricao && errors.descricao}
          />

          <br />
        </Stack>

      </Modal.Body>
      <Modal.Footer>
        <Button2 variant="secondary" onClick={handleClose1}>
            Cancelar
        </Button2>
        <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
      </Modal.Footer>
    </Form>
  </FormikProvider>
</Modal>

{/* >>>>>>>>>> FIM MODAL NOVA PROPOSTA <<<<<<<<<<<<<<<<<< */}



    </>
    
    )
||

""


  )
}












//Painel status item da reuniao
const StatusDetail = (props) => {

  const{
    idItem,
    tipoItem,
    codigo,
    horarioFinal,
    presentes,
    votositem,
    TimerVotacao
    }= props;


  const time = new Date();
  if (horarioFinal != null){
/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60)*60);

  time.setSeconds(time.getSeconds() + fim); 
 */

  time.setSeconds(time.getSeconds() + (TimerVotacao*60)); 
}



const ResultItem = (idItem) =>{
     
  addItem('IdVotationResult', idItem);
  addItem('ThemeVoteResult', tipoItem);

   window.open('https://assembleiacoop.coop.br/result_votation', '_blank');

} 


const StatusItem = (idItem) =>{
     
  addItem('IdVotationStart', idItem);
  addItem('ThemeVoteStart', tipoItem);

   window.open('https://assembleiacoop.coop.br/votation', '_blank');

} 




  return(
    <>


<Card>
        <CardContent>
          <Grid container spacing={2}>

          <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Tempo"}</h2>
       <h2>
       <Typography>
       {(horarioFinal === null && "-") 
       || 
        (<MyTimer expiryTimestamp={time} />
        )}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>

          <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Código Votação"}</h2>
       <h2>
       <Typography>
       {(codigo === null && "-") || (codigo)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>


          </Grid>
        </CardContent>
      </Card>

<br />
      <Card>
        <CardContent>
          <Grid container spacing={2}>

          <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Presentes"}</h2>
       <h2>
       <Typography>
       {(presentes === null && "-") || (presentes)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>


   <div className='total-presentes-topo'>
   <Grid>
     
       <h2>{"Total de Votos"}</h2>
       <h2>
       <Typography>
       {(votositem === null && "-") || (votositem)}
       </Typography> 
       </h2> 
     
   </Grid>
  </div>
   
 

  <Grid item xs={12} style={{textAlign:"center"}}>
    <Button1
      variant="outlined"
      component={RouterLink} 
      to="UserVote"
      style={{textDecoration:"none",color:"#27ab55"}}
    >
      Visualizar Lista
    </Button1>
  </Grid>

          </Grid>
        </CardContent>
   </Card>
<br/>



{(horarioFinal !== null &&  

<Card>
        <CardContent>
          <Grid container spacing={2}>
   
   <Grid item xs={3}>
   <img src="/static/illustrations/itempauta/resultados.png" alt="relatorio" /> 
   </Grid>

  {(TimerVotacao <= 0 &&
  <Grid item xs={9} style={{textAlign:"center"}}>
    <label style={{fontSize:"13px"}}>Disponibilizar Painel de Resultados.</label>
    <br/>
  <br/>
    <Button1
      variant="outlined"
      onClick={(e) => ResultItem(idItem, e)}
      className='btn-abrir'
    >
      Abrir
    </Button1>
  </Grid>
)
||
(
  <Grid item xs={9} style={{textAlign:"center"}}>
  <label style={{fontSize:"13px"}}>Acompanhar status da votação.</label>
  <br/>
<br/>
  <Button1
    variant="outlined"
    onClick={(e) => StatusItem(idItem, e)}
    style={{textDecoration:"none",color:"#27ab55",width:"60%"}}
  >
    Abrir
  </Button1>
</Grid>
)
}

          </Grid>
        </CardContent>
   </Card>
)
||

(
""
)
}


    </>
  )


}