import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import bookopen from '@iconify/icons-eva/book-open-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Reuniões',
    path: '/meetings',
    icon: getIcon(calendarFill)
  },
  {
    title: 'Usuários',
    path: '/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Manual',
    path: 'manual',
    icon: getIcon(bookopen)
  },
  
];

export default sidebarConfig;
