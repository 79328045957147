import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Toolbar
} from '@material-ui/core';
//
import Scrollbar from '../../../Scrollbar';
import ColorManyPicker from '../../../ColorManyPicker';

import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------



const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' }
];

export const FILTER_CATEGORY_OPTIONS = ['CPF', 'CNPJ', 'ADMINISTRADOR'];

// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};

export default function ShopFilterSidebar({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  numSelected,
  formik,
  onRadioChange 
}) {

  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onRadioChange(selectedValue);
  };

  const handleResetFilter = () => {
    setSelectedOption('');
    const radioGroup = document.querySelector('input[name="tipoAcesso"]');
    if (radioGroup) {
      radioGroup.checked = false;
    }
    onRadioChange('');

};
  

  const { values, getFieldProps, handleChange } = formik;

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >

       <Button
        disableRipple
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}
      >
        Filtro&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: 'none', overflow: 'hidden' }
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Filtro usuarios
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
               
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Tipo acesso
                  </Typography>
                  <RadioGroup {...getFieldProps('tipoAcesso')}
                  value={selectedOption}
                  >
                    {FILTER_CATEGORY_OPTIONS.map((item) => (
                      <FormControlLabel 
                      key={item} 
                      value={item} 
                      control={<Radio />} 
                      label={item}  
                      onChange={handleRadioChange}/>
                    ))}
                  </RadioGroup>
                </div>


              
                
              </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                //onClick={onResetFilter}
                //onChange={handleRadioChange}
                onClick={handleResetFilter}
                startIcon={<Icon icon={roundClearAll} 
                />}
              >
                Limpar filtro
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
