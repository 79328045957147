import { styled } from '@material-ui/core/styles';
import { Card, Stack, Container, Typography} from '@material-ui/core';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { ListEntidades } from '../components/_dashboard/entidades';
import "../layouts/css/scrollbar.css";
import axios from "axios";
import functions from "../constants/serverFunctions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItem, getItem } from '../components/services/LocaleStorage';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));

// ----------------------------------------------------------------------


Login.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};




export default function Login(props) {

  const token = getItem('token-assembleia-api');
  const tagId = getItem('tagId');
  const PerfilAcesso = getItem('userNivel');  

  // Receber a string
let EntidadeString = localStorage.getItem('userEntidades');
let [entidadesStore2] = useState(JSON.parse(EntidadeString));


let entidadesStore = "";

  //Consulta entidades bloqueadas.
  useEffect(() => {
    axios
    .get(functions.entidadeUsuario.BUSCAR_LISTA_BLOCK_POR_USUARIO+"/"+tagId, { headers: {"Authorization": `${token}`} })
    .then((res) => {

      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        toast.warn('Sessão expirada!');
      }else{
          if(res.data.records !== 0){
            for (let index = 0; index < res.data.records; index++) {
              entidadesStore = entidadesStore2.filter((item) => item.sigla !== res.data.result.recordset[index]['sigla']);
            }
          }else{
            entidadesStore = entidadesStore2;
          }

          setEntidades(entidadesStore);
          setLoading(false);
      }


        })
    .catch((err) => {
      });
  }, [tagId,token]);






  const { setIsAuthenticated } = useContext(Auth);
  const [selected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [entidades, setEntidades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredEntidades, setFilteredEntidades] = useState([]);


  //ConsultarNome
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setSearch(event.target.value);
  };



  
  useEffect(() => {  
    setFilteredEntidades(  
      entidades.filter((entidades) =>
        entidades.sigla.toLowerCase().includes(search.toLowerCase())
      )
    )

  }, [search, entidades]);


  if (loading) {
    return <p>carregando...</p>;
  }


 

  const handleLogout = () => {
    logout();
    setIsAuthenticated(false);
    toast.info('Acesso encerrado!');
    
  }
  


  return (
    <RootStyle title="Entidades | ASSEMBLEIACOOP">
       <AuthLayout>
        <button className="btn btn-danger" onClick={handleLogout}>Sair</button>
      </AuthLayout>
   

      <Container maxWidth="sm">
      <div className = 'titulo-instituicao' >
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
            Selecione sua Instituição
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
            Para continuar é necessário escolher a sua Instituição.
              </Typography>
          </Stack>

          
          <div className = 'busca' >

          <ListEntidades
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          </div>


<Card className='scrollbar scrollbar-primary mt-4 mx-auto entidades'>

  {filteredEntidades.map((entidade, id) => (

    ((PerfilAcesso === "cooperado" &&

              <EntidadeDetail key={id} {...entidade} value={PerfilAcesso} />  )
     ||
     
              <EntidadeDetailAdmin key={id} {...entidade} value={PerfilAcesso} />

     ) 


      ))}

</Card>

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
             </Typography>
          </MHidden>
        </ContentStyle>
        </div>
      </Container>
    </RootStyle>
  );

  

}



const EntidadeDetail = (props) => {

  
  const { sigla, sqlServerId } = props;

  const handleClick = (sigla) => {
    addItem('institutionName', sigla);
    addItem('institutionId', sqlServerId);
  }




  return (
    <>
       <div className='lista-entidade-inst'>
      <ListGroup flush>
        <ListGroupItem tag="a" href="/rooms" onClick={(e) => handleClick(sigla, e)} id={sqlServerId} action>{sigla}</ListGroupItem>
      </ListGroup>
      </div>


<ToastContainer />
    </>
  );
};



const EntidadeDetailAdmin = (props) => {

  const { sigla, id, CNPJ } = props;


  const handleClick = (sigla) => {
    addItem('institutionName', sigla);
    addItem('institutionId', id);
    addItem('institutionCNPJ', CNPJ);
  }

  return (
    <>

    <div className='lista-entidade-inst'>
      <ListGroup flush>
        <ListGroupItem tag="a" href="/meetings" onClick={(e) => handleClick(sigla, e)} id={id} action>{sigla}</ListGroupItem>
      </ListGroup>
      </div>

<ToastContainer />
    </>
  );
};