const URL_SERVICE = 'https://backend.assembleiacoop.coop.br';


const rotas =  {    
    ASSEMBLEIA: `${URL_SERVICE}/assembleia`,
    CANDIDATOS: `${URL_SERVICE}/candidatos`,
    CHAPAS_PROPOSTAS: `${URL_SERVICE}/chapasPropostas`,
    CONFIGURACAO_ELEICAO: `${URL_SERVICE}/configuracaoEleicao`,
    ENTIDADE: `${URL_SERVICE}/entidade`,
    ENTIDADE_USUARIO: `${URL_SERVICE}/entidadeUsuario`,
    ITENS_PAUTA: `${URL_SERVICE}/itensPauta`,
    PRESENCA: `${URL_SERVICE}/presencas`,
    USUARIOS: `${URL_SERVICE}/usuarios`,
    VOTO: `${URL_SERVICE}/votos`,
    VOTACAO: `${URL_SERVICE}/votacoes`,
   
};

export default rotas;
