import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  Paper,
  Divider,
  CardContent,
  Tab,
  Tabs,
  Avatar,
  AvatarGroup,
} from '@material-ui/core';
import Label from '../layouts/css/Label';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect, useContext} from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
import Scrollbar from '../components/Scrollbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import { Link as RouterLink} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// ----------------------------------------------------------------------


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));

// ----------------------------------------------------------------------



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}






Resultados.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};




export default function Resultados() {

  const token = getItem('token-assembleia-api');
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const { setIsAuthenticated } = useContext(Auth);

  const [DadosItem, setDadosItem] = useState([]);
  const [ListProps, setListProps] = useState([]);
  const [QuantListProps, setQuantListProps] = useState([]);
  const [ListChapas, setListChapas] = useState([]);
  const [ListCandts, setListCandts] = useState([]);
  const [ListCandtsInd, setListCandtsInd] = useState([]);
  const [QuantCandInd, setQuantCandInd] = useState([]);
  const [setQuantListChapas] = useState([]);
  const [AbasTipoEleicao, setAbasTipoEleicao] = useState([]);
  const [loading] = useState(false);
  const [TipoItemResult, setTipoItemResult] = useState(getItem('ThemeVoteResult'));
  const ThemeVoteResult = getItem('ThemeVoteResult');
  const IdItem = getItem('IdProps'); 
  const [StatusTimerItem, setStatusTimerItem] = useState();
  const [QuantVotoProps, setQuantVotoProps] = useState([]);
  const [QuantvotosBranco, setQuantvotosBranco] = useState([]);



  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };







// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.result === "Token expirado"){
      logout();
      setIsAuthenticated(false);
      toast.warn('Sessão expirada!');
    }else{
      
      if(res.data.records === 1){

        axios
        .get(functions.itemPauta.BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
          .then((res) => {
         (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
         setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])

         setStatusTimerItem(res.data.result.recordset[0].TimerVotacao)
         setQuantVotoProps(res.data.result.recordset[0].votositem)
         setQuantvotosBranco(res.data.result.recordset[0].votosBrancoChapa)

          })
          .catch((err) => {
          });

      
        }else{
          logout();
          setIsAuthenticated(false);
          toast.error('Token inválido!');
        }
        
    }

    

  }); 


  const interval =  window.setInterval(() => {

    axios
    .get(functions.itemPauta.BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
      .then((res) => {
     (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
     setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])
      })
      .catch((err) => {
      });

  }, 5000);

  return () => clearInterval(interval);
  

}, [IdEntidade,IdUser,IdItem,token,setIsAuthenticated]);




//mapeia a consulta
const DADOSITEMSELECT = DadosItem.map((DadoItem, index) => ({

  idItem: DadoItem.idItem,
  itemPauta: DadoItem.itemPauta,
  idEntidade: DadoItem.idEntidade,
  entidade: DadoItem.entidade,
  idAssembleia: DadoItem.idAssembleia,
  assembleia: DadoItem.assembleia,
  dataAssembleia: DadoItem.data,
  tipoItem: DadoItem.tipoItem,
  DataInicio_Assembleia: DadoItem.DataInicio_Assembleia,
  DataFim_Assembleia: DadoItem.DataFim_Assembleia,
  Quant_proposta: DadoItem.Quant_proposta,
  codigo: DadoItem.codigo,
  horarioInicial: DadoItem.horarioInicial,
  horarioFinal: DadoItem.horarioFinal,
  presentes: DadoItem.presentes,
  voto_branco: DadoItem.voto_branco,
  votositem:DadoItem.votositem

  }));




//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_RESULT_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
   setQuantListProps(res.data.records)
    })
    .catch((err) => {
    });


    if(TipoItemResult === "Proposta"){
    
      const interval =  window.setInterval(() => {
  
        axios
        .get(functions.chapaProposta.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_RESULT_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
          .then((res) => {
         (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
         setQuantListProps(res.data.records)
          })
          .catch((err) => {
          });
          
      }, 5000);
  
      return () => clearInterval(interval);
      }


}, [IdItem,token]);




//mapeia a consulta
const LISTAPROPOSTA = ListProps.map((ListaProp, index) => ({

  entidade: ListaProp.entidade,
  idEntidade: ListaProp.idEntidade,
  assembleia: ListaProp.assembleia,
  idAssembleia: ListaProp.idAssembleia,
  data: ListaProp.data,
  idItem: ListaProp.idItem,
  itemPauta: ListaProp.itemPauta,
  identificador: ListaProp.identificador,
  idProposta: ListaProp.idProposta,
  descricaoProposta: ListaProp.descricaoProposta,
  horarioInicial: ListaProp.horarioInicial,
  horarioFinal: ListaProp.horarioFinal,
  votosSim: ListaProp.votosSim,
  votosNao: ListaProp.votosNao,
  votosNulo: ListaProp.votosNulo,
  quantVotoProp: ListaProp.quantVotoProp,
  votositem: ListaProp.votositem

  }));




//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListChapas(res.data.result.recordset)))
   setQuantListChapas(res.data.records)
    })
    .catch((err) => {
    });


    if(TipoItemResult === "Eleição"){
    
      const interval =  window.setInterval(() => {
  
        axios
        .get(functions.chapaProposta.BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
          .then((res) => {
         (res.data.records !== 0 && (setListChapas(res.data.result.recordset)))
         setQuantListChapas(res.data.records)
          })
          .catch((err) => {
          });
          
      }, 5000);
  
      return () => clearInterval(interval);
      }


}, [IdItem,token,setQuantListChapas]);



//mapeia a consulta
/*const LISTACHAPA = ListChapas.map((ListaChapa, index) => ({

  entidade: ListaChapa.entidade, 
  idEntidade: ListaChapa.idEntidade, 
  assembleia: ListaChapa.assembleia, 
  idAssembleia: ListaChapa.idAssembleia, 
  data: ListaChapa.data, 
  itemPauta: ListaChapa.itemPauta, 
  idItem: ListaChapa.idItem, 
  tipo: ListaChapa.tipo, 
  Chapa: ListaChapa.Chapa, 
  idChapa: ListaChapa.idChapa, 
  descricaoChapa: ListaChapa.descricaoChapa, 
  quantCandidatos: ListaChapa.quantCandidatos,
  horarioInicial: ListaChapa.horarioInicial, 
  horarioFinal: ListaChapa.horarioFinal

  }));
*/



//Consulta lista de candidatos chapa do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_CHAPA_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
   (res.data.records !== 0 && (setListCandts(res.data.result.recordset)))
    })
    .catch((err) => {
    });


    if(TipoItemResult === "Proposta"){
    
      const interval =  window.setInterval(() => {
  
        axios
        .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_CHAPA_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
          .then((res) => {
         (res.data.records !== 0 && (setListCandts(res.data.result.recordset)))
          })
          .catch((err) => {
          });
          
      }, 5000);
  
      return () => clearInterval(interval);
      }


}, [IdItem,token]);


//mapeia a consulta candidatos da chapas da eleicao
const LISTACANDSCHAPA = ListCandts.map((ListCandt, index) => ({

  Chapa: ListCandt.Chapa,
  assembleia: ListCandt.assembleia,
  candidato: ListCandt.candidato,
  cargo: ListCandt.cargo,
  data: ListCandt.data,
  descricaoCanditado: ListCandt.descricaoCanditado,
  descricaoChapa: ListCandt.descricaoChapa,
  entidade: ListCandt.entidade,
  idAssembleia: ListCandt.idAssembleia,
  idCandidato: ListCandt.idCandidato,
  idChapa: ListCandt.idChapa,
  idEntidade: ListCandt.idEntidade,
  idItem: ListCandt.idItem,
  itemPauta: ListCandt.itemPauta,
  tipo: ListCandt.tipo,
  tipoCandidato: ListCandt.tipoCandidato
  
  
    }));



  //Consulta lista de candidatos INDIVIDUAL do item da Pauta da Reuniao
  useEffect(() => {
    axios
    .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
      .then((res) => {
     (res.data.records !== 0 && (setListCandtsInd(res.data.result.recordset)))
     setQuantCandInd(res.data.records)
      })
      .catch((err) => {
      });


      if(TipoItemResult === "Eleição"){
    
        const interval =  window.setInterval(() => {
    
          axios
          .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_ITEM+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
            .then((res) => {
          (res.data.records !== 0 && (setListCandtsInd(res.data.result.recordset)))
          setQuantCandInd(res.data.records)
            })
            .catch((err) => {
            });
            
        }, 5000);
    
        return () => clearInterval(interval);
        }


  }, [IdItem,token,setQuantCandInd]);
  
  






  



  if (loading) {
    return <p>carregando...</p>;
  }


  const closerwindow = () => {
    window.close(); 
  }


 


  return (
    <RootStyle title="Resultado Votação | ASSEMBLEIACOOP" >
       <AuthLayout>
          <RouterLink onClick={closerwindow} style={{textDecoration:"none",color:"#000000"}}>  
            <Button variant="secondary" >Voltar</Button> 
          </RouterLink>
       </AuthLayout>
       



      <Container maxWidth="sm" style={{marginLeft: "initial",maxWidth:"100%"}}>
        <ContentStyle style={{maxWidth:"80%"}}>
          <Stack>

          <br />
                  <div >
                    {DADOSITEMSELECT.map((proposta, id) => (
                       <StatusDetail key={id} {...proposta} />       
                    ))}
                  </div>

                
                  {(ThemeVoteResult === "Proposta" 
                    &&

                  <div>
                    {LISTAPROPOSTA.map((proposta, id) => (
                      <ListaDetail key={id} {...proposta} value={[QuantListProps,StatusTimerItem,QuantVotoProps]} />       
                    ))}


                  {(//StatusTimerItem <= 0 &&
                      QuantListProps > 1 &&
                         <VotoBrancoDetail value={[QuantvotosBranco,QuantVotoProps]} />
                  )}


                  </div>
                  )


                  ||

(

(AbasTipoEleicao === "Chapa Eleitoral" &&

(<Box sx={{ width: '100%' }}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
    </Tabs>
  </Box>


      <TabPanel value={value} index={0}>
       <Grid container spacing={1} style={{marginTop:"10px"}} >
          <ResultadoVotacao1 value={[ListChapas,DadosItem,ListCandts,LISTACANDSCHAPA]}/>

           
                    {/*LISTACHAPA.map((chapa, id) => (
                      <ListaChapas key={id} {...chapa} value={[DadosItem,ListCandts]} />       
                    ))*/}
                  


      </Grid>
      </TabPanel>


</Box>
    
)
)


||


(AbasTipoEleicao === "Candidato Individual" &&


(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Candidatos Individuais" {...a11yProps(0)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
      <Grid container spacing={1} style={{marginTop:"10px"}} >
         <ResultadoVotacao2 value={[ListCandtsInd,DadosItem]}/>
      </Grid>
      </TabPanel>

</Box>)


)



||


(AbasTipoEleicao === "Ambos" &&


(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
          <Tab label="Candidatos Individuais" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
       <Grid container spacing={1} style={{marginTop:"10px"}} >
            <ResultadoVotacao1 value={[ListChapas,DadosItem,ListCandts, LISTACANDSCHAPA]}/>
      </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
      <Grid container spacing={1} style={{marginTop:"10px"}} >
           <ResultadoVotacao2 value={[ListCandtsInd,DadosItem]}/>
      </Grid>
      </TabPanel>

</Box>)


)


                  )

                  }


          </Stack>
   
        </ContentStyle>
      </Container>
      
    </RootStyle>
  );

  
}



const StatusDetail = (props) => {

  const{
    itemPauta,
    tipoItem,
    presentes,
    votositem
    }= props;



  return(
    <>


<br />
<Card> 
     <CardContent>
       <Grid container spacing={2}>
      
    <Grid item xs={7.5}>
     <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'left' }}>
       <Box sx={{ mb: 0.5 }}>
         
  <Label
  variant="filled"
  color={(tipoItem === 'Proposta' && 'info') || 'warning'}
  sx={{
    zIndex: 9,
    top: 5,
    left: 13,
    position: 'relative',
  }}
  >
  {tipoItem}
  </Label>
  
  </Box>
  
  <Typography variant="h6" sx={{
  zIndex: 9,
  top: 12,
  left: 13,
  position: 'relative',
  maxWidth: '93%'
  }}
  className = 'titulo-resultados'
  >
    {itemPauta}
  </Typography>
     </Paper>
   </Grid>

  <div className='total-de-votos-topo'>
   <Grid>
     
       <h2>{"Presentes"}</h2>
       <h2>
       <Typography>
       {(presentes === null && "-") || (presentes)}
       </Typography> 
       </h2> 
     
   </Grid>

  </div>


  <div className='total-de-votos-topo'>

   <Grid>
     
     <h2>{"Total de Votos"}</h2>
     <h2>
     <Typography>
     {(votositem === null && "-") || (votositem)}
     </Typography> 
     </h2> 
   
 </Grid>

  </div>


       </Grid>
     </CardContent>
   </Card>
<br />



    </>
  )


}




  const ListaDetail = (props) => {

    const{
      identificador,
      descricaoProposta,
      votosSim,
      votosNao,
      votosNulo,
      quantVotoProp,
     // votositem
    }= props;
  
    //const QuantListProps = props.value[];
    const QuantListProps = props.value[0];
    const TempoVotacao = props.value[1];
    const votositem = props.value[2];


  const progressCon = votosSim;
  const progressDis = votosNao;
  const progressAbs = votosNulo;
  const progressUni = quantVotoProp;

  const totalVotos = progressCon+progressDis+progressAbs;

  const progress1 = ((progressCon/totalVotos)*100);
  const progress2 = ((progressDis/totalVotos)*100);
  const progress3 = ((progressAbs/totalVotos)*100);

  const progressT = ((progressUni/votositem)*100);
    
   




  return(

    <>

{(QuantListProps === 1 && 


  <>
<Card>
  <CardContent>
    <Grid container spacing={2}>
          <Grid item xs={9} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <b> {descricaoProposta} </b>
             </Box>
         </Grid>

    </Grid>
  </CardContent>   
</Card>
<br/>



<Card>
<Scrollbar>
  <Stack className='titulo-resultado-proposta' >
    <Typography variant="body2" noWrap>
       <Grid item xs={9} style={{fontSize:"16px"}}>
         <Box sx={{ mb: 0.5 }}>
          <b> Concordaram </b>
         </Box>
       </Grid>
 </Typography>
  </Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
    <div className="progressbar-complete" style={{width: `${progress1}%`}}>
        <div className="progressbar-liquid"></div>
    </div>
    <span className="progress">
      <b>{(progressCon > 0 && progressCon) || ("")} {(progressCon === 1 && "Voto") || (progressCon === 0 && "Nenhum voto") || ("Votos")}</b>
    </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />



<Card>
<Scrollbar>
  <Stack className='titulo-resultado-proposta-di'>
    <Typography variant="body2" noWrap>
       <Grid item xs={9} style={{fontSize:"16px"}}>
         <Box sx={{ mb: 0.5 }}>
          <b> Discordaram </b>
         </Box>
       </Grid>
 </Typography>
  </Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
    <div className="progressbar-complete" style={{width: `${progress2}%`}}>
        <div className="progressbar-liquid"></div>
    </div>
    <span className="progress">
      <b>{(progressDis > 0 && progressDis) || ("")} {(progressDis === 1 && "Voto") || (progressDis === 0 && "Nenhum voto") || ("Votos")}</b>
    </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />



<Card>
<Scrollbar>
  <Stack className='titulo-resultado-proposta-ab'>
    <Typography variant="body2" noWrap>
       <Grid item xs={9} style={{fontSize:"16px"}}>
         <Box sx={{ mb: 0.5 }}>
          <b> Abstiveram </b>
         </Box>
       </Grid>
 </Typography>
  </Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
    <div className="progressbar-complete" style={{width: `${progress3}%`}}>
        <div className="progressbar-liquid"></div>
    </div>
    <span className="progress">
      <b>{(progressAbs > 0 && progressAbs) || ("")} {(progressAbs === 1 && "Voto") || (progressAbs === 0 && "Nenhum voto") || ("Votos")}</b>
    </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />
</>
)



||



(//lista propostas > 1
  <>
  <Card>
  {(descricaoProposta !== null &&
   

   
  <Scrollbar >
    <Stack>
      <Typography variant="body2" noWrap>
      <Grid className='espaco-interno' >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <b> {descricaoProposta} </b>
             </Box>
         </Grid>
   </Typography>
    </Stack>
  </Scrollbar>
  )
  ||
  (
    <Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
  <Stack spacing={3} sx={{ p: 1, pr: 0 }}>
    <Typography variant="body2" noWrap>
       <Grid item xs={9} style={{fontSize:"16px"}}>
         <Box sx={{ mb: 0.5 }}>
          <b> Abstenções </b>
         </Box>
       </Grid>
 </Typography>
  </Stack>
</Scrollbar>
  )
  }
  <Divider />
  
  <Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
  <div className="progressbar-container">
      <div className="progressbar-complete" style={{width: `${progressT}%`}}>
          <div className="progressbar-liquid"></div>
      </div>
      <span className="progress">
        <b>{(quantVotoProp > 0 && quantVotoProp) || ("")} {(quantVotoProp === 1 && "voto") || (quantVotoProp === 0 && "Nenhum voto") || ("votos")}</b>
      </span>
  </div>
  </div>
  
  </div>
  </Grid>
  
  </Grid>
  </Card>
  <br/>
  </>
  )


  


}


    </>

  );


}






//teste trazer chapas de outro jeito - tratar o erro


const ListaChapas = (props) => {

  const{
    entidade, 
    idEntidade,
    assembleia,
    idAssembleia,
    data,
    itemPauta,
    idItem,
    tipo,
    Chapa,
    idChapa,
    descricaoChapa,
    quantCandidatos,
    horarioInicial,
    horarioFinal,
    quantVotoChapa
  }=props;

  let votositem = props.value[0]['votositem'];
  let listCandidatos = props.value[1];



    const progressUni = quantVotoChapa;
    const progressT = ((progressUni/votositem)*100);




    let users = [];

          for(let j = 0; j < quantCandidatos; j++){
           
            users.push(
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <b style={{alignItems:"center"}}> 
                  <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                  &nbsp;&nbsp;
                   <Avatar 
                    alt={listCandidatos[j]['candidato']}
                    src="/static/illustrations/candidato/default3.png"
                    sx={{ width: 30, height:30 }}
                    style={{marginLeft: "-1px"}} 
                    />
                    &nbsp;&nbsp;&nbsp;{listCandidatos[j]['candidato']}     
                  </Typography>
                  </b>
               </Form.Group> 
               )
         
          }




  /*

  const QuantListProps = props.value;


const progressCon = votosSim;
const progressDis = votosNao;
const progressAbs = votosNulo;
const progressUni = quantVotoProp;

const totalVotos = progressCon+progressDis+progressAbs;

const progress1 = ((progressCon/totalVotos)*100);
const progress2 = ((progressDis/totalVotos)*100);
const progress3 = ((progressAbs/totalVotos)*100);

const progressT = ((progressUni/votositem)*100);
  
*/ 




return(

  <>

<Grid item xs={12} sm={4} style={{paddingTop: "0px", marginLeft: '15px'}}>
              <div>
                <Card>
                  
              <CardContent style={{textAlign:"center"}}>
              <Typography variant="body2" color="text.secondary">
                 {users}
                 </Typography>
                 <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                  {descricaoChapa}
                 </Typography>
               </CardContent>
        
                  <Divider />
        
                  <Grid container spacing={1} style={{marginTop:"10px"}} >
                  <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
                  <div>
        
                  <div className="container">
                  <div className="progressbar-container">
                      <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                          <div className="progressbar-liquid"></div>
                      </div>
                      <span className="progress">
                      <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>
                      </span>
                  </div>
                  </div>
        
                  </div>
                  </Grid>
        
                  </Grid>
                  <Box sx={{p: 1, textAlign: 'right' }}></Box>
                  </Card>
                  <br />
                  </div>
                  </Grid>







{/*
{(QuantListProps === 1 && 


<>
<Card>
<CardContent>
  <Grid container spacing={2}>
      <Grid item xs={12} style={{fontSize:"13px"}}>
            <Box sx={{ mb: 0.5 }}>
              {" Proposto por: "}
              <b> {identificador} </b>
              </Box>
            <Box sx={{ mb: 0.5 }}>
            <b> {descricaoProposta} </b>
            </Box>
        </Grid>
    
  </Grid>
</CardContent>   
</Card>
<br/>



<Card>
<Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
<Stack spacing={3} sx={{ p: 1, pr: 0 }}>
  <Typography variant="body2" noWrap>
     <Grid item xs={9} style={{fontSize:"16px"}}>
       <Box sx={{ mb: 0.5 }}>
        <b> Concordaram </b>
       </Box>
     </Grid>
</Typography>
</Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
  <div className="progressbar-complete" style={{width: `${progress1}%`}}>
      <div className="progressbar-liquid"></div>
  </div>
  <span className="progress">
    <b>{(progressCon > 0 && progressCon) || ("")} {(progressCon === 1 && "Voto") || (progressCon === 0 && "Nenhum voto") || ("Votos")}</b>
  </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />



<Card>
<Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
<Stack spacing={3} sx={{ p: 1, pr: 0 }}>
  <Typography variant="body2" noWrap>
     <Grid item xs={9} style={{fontSize:"16px"}}>
       <Box sx={{ mb: 0.5 }}>
        <b> Discordaram </b>
       </Box>
     </Grid>
</Typography>
</Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
  <div className="progressbar-complete" style={{width: `${progress2}%`}}>
      <div className="progressbar-liquid"></div>
  </div>
  <span className="progress">
    <b>{(progressDis > 0 && progressDis) || ("")} {(progressDis === 1 && "Voto") || (progressDis === 0 && "Nenhum voto") || ("Votos")}</b>
  </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />



<Card>
<Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
<Stack spacing={3} sx={{ p: 1, pr: 0 }}>
  <Typography variant="body2" noWrap>
     <Grid item xs={9} style={{fontSize:"16px"}}>
       <Box sx={{ mb: 0.5 }}>
        <b> Abstiveram </b>
       </Box>
     </Grid>
</Typography>
</Stack>
</Scrollbar>

<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
  <div className="progressbar-complete" style={{width: `${progress3}%`}}>
      <div className="progressbar-liquid"></div>
  </div>
  <span className="progress">
    <b>{(progressAbs > 0 && progressAbs) || ("")} {(progressAbs === 1 && "Voto") || (progressAbs === 0 && "Nenhum voto") || ("Votos")}</b>
  </span>
</div>
</div>
</div>
</Grid>
</Grid>
</Card>
<br />
</>
)



||



(
<>
<Card>
{(descricaoProposta !== null &&
 
<Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
  <Stack spacing={3} sx={{ p: 2, pr: 0 }}>
    <Typography variant="body2" noWrap>
       <Grid item xs={9} style={{fontSize:"13px"}}>
         <Box sx={{ mb: 0.5 }}>
          {" Proposto por: "}
          <b> {identificador} </b>
         </Box>
         <Box sx={{ mb: 0.5 }}>
          <b> {descricaoProposta} </b>
         </Box>
       </Grid>
 </Typography>
  </Stack>
</Scrollbar>
)
||
(
  <Scrollbar style={{marginTop:"5px", marginLeft:"5px"}}>
<Stack spacing={3} sx={{ p: 1, pr: 0 }}>
  <Typography variant="body2" noWrap>
     <Grid item xs={9} style={{fontSize:"16px"}}>
       <Box sx={{ mb: 0.5 }}>
        <b> Abstenções </b>
       </Box>
     </Grid>
</Typography>
</Stack>
</Scrollbar>
)
}
<Divider />

<Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
<div className="progressbar-container">
    <div className="progressbar-complete" style={{width: `${progressT}%`}}>
        <div className="progressbar-liquid"></div>
    </div>
    <span className="progress">
      <b>{(quantVotoProp > 0 && quantVotoProp) || ("")} {(quantVotoProp === 1 && "voto") || (quantVotoProp === 0 && "Nenhum voto") || ("votos")}</b>
    </span>
</div>
</div>

</div>
</Grid>

</Grid>
</Card>
<br/>
</>
)





}

*/}


  </>

);


}










const ResultadoVotacao01 = (props) => {


const listChapas = props.value[0];
const coutChapa = props.value[0].length;

const votositem = props.value[1][0]['votositem'];
const votoBranco = props.value[1][0]['voto_branco'];
const countVotosBranco = props.value[1][0]['votosBrancoChapa']; 

const listCandidatos = props.value[2];




   let chapas = []
   let users = []
   
   
   for (let i = 0; i < coutChapa; i++) {

    const progressUni = props.value[0][i]['quantVotoChapa'];
    const progressT = ((progressUni/votositem)*100);
    const coutCandidatos = props.value[0][i]['quantCandidatos'];

          users = []
          for(let j = 0; j < coutCandidatos; j++){
           
            users.push(
              /*<Avatar 
                alt={"c"}
                src="/static/illustrations/candidato/default3.png"
                sx={{ width: 30, height: 30 }}
                style={{marginLeft: "-1px"}} 
                />*/
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <b style={{alignItems:"center"}}> 
                  <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                  &nbsp;&nbsp;
                   <Avatar 
                    alt={"listCandidatos[j]['candidato']"}
                    src="/static/illustrations/candidato/default3.png"
                    sx={{ width: 30, height:30 }}
                    style={{marginLeft: "-1px"}} 
                    />
                    &nbsp;&nbsp;&nbsp;{"listCandidatos[j]['candidato']"}     
                  </Typography>
                  </b>
             </Form.Group> )

          }


chapas.push(
            <>
          <Grid item xs={12} sm={4} style={{paddingTop: "0px", marginLeft: '15px'}}>
              <div>
                <Card>
                  
              <CardContent style={{textAlign:"center"}}>
              <Typography variant="body2" color="text.secondary">
                 { /*<AvatarGroup max={6} style={{display: "inline-flex"}}>{users}</AvatarGroup>*/}
                 {users}
                 </Typography>
                 <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                  {listChapas[i]['descricaoChapa']}
                 </Typography>
               </CardContent>
        
                  <Divider />
        
                  <Grid container spacing={1} style={{marginTop:"10px"}} >
                  <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
                  <div>
        
                  <div className="container">
                  <div className="progressbar-container">
                      <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                          <div className="progressbar-liquid"></div>
                      </div>
                      <span className="progress">
                      {(listChapas[i]['descricaoChapa'] !== null &&
                        <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>)
                        ||
                        <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "abstenção") || (progressUni === 0 && "Nenhuma abstenção") || ("abstenções")}</b>
                      }
                      </span>
                  </div>
                  </div>
        
                  </div>
                  </Grid>
        
                  </Grid>
                  <Box sx={{p: 1, textAlign: 'right' }}></Box>
                  </Card>
                  </div>
                  </Grid>
                 
                  </>
        
          )

   }  



return(
<>

{chapas}

</>
)

}






// Voto branco proposta

const VotoBrancoDetail = (props) => {

  const QuantvotosBranco = props.value[0];
  const votositem = props.value[1];

  const progressT = ((QuantvotosBranco/votositem)*100);



  return(
    <>

<Card>
  <Scrollbar >
    <Stack className='titulo-resultado-propostapainel'>
      <Typography variant="body2" noWrap>

   </Typography>
    </Stack>
  </Scrollbar>

  <Divider />
  
  <Grid container spacing={1} style={{marginTop:"0px"}} >
<Grid item xs={12} sm={12} style={{paddingTop: "0px", marginLeft: '0px'}}>
<div>
<div className="container" style={{maxWidth:"100%"}}>
  <div className="progressbar-container">
      <div className="progressbar-complete" style={{width: `${progressT}%`}}>
          <div className="progressbar-liquid"></div>
      </div>
      <span className="progress">
      <b>{(QuantvotosBranco > 0 && QuantvotosBranco) || ("")} {(QuantvotosBranco === 1 && "abstenção") || (QuantvotosBranco === 0 && "Nenhuma abstenção") || ("abstenções")}</b>
      </span>
  </div>
  </div>
  
  </div>
  </Grid>
  
  </Grid>
  </Card>
  <br/>


    </>
  )




}












// layout CHAPA
const ResultadoVotacao1 = (props) => {


  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;
  
  const votositem = props.value[1][0]['votositem'];
  const votoBranco = props.value[1][0]['voto_branco'];
  const countVotosBranco = props.value[1][0]['votosBrancoChapa']; 
  
  const listCandidatos = props.value[2];

  
  
  
     let chapas = []
     let users = []
     
     
     for (let i = 0; i < coutChapa; i++) {
  
      const progressUni = props.value[0][i]['quantVotoChapa'];
      const progressT = ((progressUni/votositem)*100);
      const coutCandidatos = props.value[0][i]['quantCandidatos'];
  
            users = []
            for(let j = 0; j < coutCandidatos; j++){
  
                  users.push(
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <b style={{alignItems:"center"}}> 
                      <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                      &nbsp;&nbsp;
                       <Avatar 
                       // alt={listCandidatos[j]['candidato']}
                        src="/static/illustrations/candidato/default3.png"
                        sx={{ width: 30, height:30 }}
                        style={{marginLeft: "-1px"}} 
                        />
                        &nbsp;&nbsp;&nbsp;{"listCandidatos[j]['candidato']"}    
                      </Typography>
                      </b>
                 </Form.Group> )
  
            }
  
  
  chapas.push(
              <>
            <Grid className='conteudo-resultado' item xs={12} sm={3.5} style={{paddingTop: "0px", marginLeft: '15px'}}>
                <div>
                  <Card>
                    
                <CardContent style={{textAlign:"center"}}>
                <Typography>
                    <h5>{listChapas[i]['descricaoChapa']}</h5>
                </Typography>

                  <Typography variant="body2" color="text.secondary">
                   { /*<AvatarGroup max={6} style={{display: "inline-flex"}}>{users}</AvatarGroup>*/}
                   {
                   //users
                   }
                   {props.value[3].map((candidatos, id) => (
                      <ListaCandidatosChapa key={id} {...candidatos} value={listChapas[i]['idChapa']} />       
                    ))}

                   </Typography>
                   
                 </CardContent>
          
                    <Divider />
          
                    <Grid container spacing={1} style={{marginTop:"10px"}} >
                    <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
                    <div>
          
                    <div className="container">
                    <div className="progressbar-container">
                        <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                            <div className="progressbar-liquid"></div>
                        </div>
                        <span className="progress">
                        {(listChapas[i]['descricaoChapa'] !== null &&
                          <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>)
                          ||
                          <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "abstenção") || (progressUni === 0 && "Nenhuma abstenção") || ("abstenções")}</b>
                        }
                        </span>
                    </div>
                    </div>
          
                    </div>
                    </Grid>
          
                    </Grid>
                    <Box sx={{p: 1, textAlign: 'right' }}></Box>
                    </Card>
                    <br/>
                    </div>
                    </Grid>
                  
                    </>
          
            )
  
     }  
  
  
     const progressTBranco = ((countVotosBranco/votositem)*100);
  
  
     (votoBranco === "SIM" &&
  
  
  chapas.push(
    <>
  
  <Grid className='conteudo-resultado-voto-nulo conteudo-resultado' item xs={12} sm={3.5} >
      <div>
        <Card>
  
      <Scrollbar>   
        
      <Stack spacing={1} sx={{ p: 2, pr: 0 }} style={{alignItems: "center"}}>
        <Typography variant="body2" noWrap>
          <Grid item xs={12} style={{fontSize:"13px"}}>
          <Typography >
          <h5>Voto em Branco</h5>
          </Typography>                   
          </Grid>
        </Typography>
      </Stack>
    </Scrollbar>
          
  
  
          <Divider />
  
          <Grid container spacing={1} style={{marginTop:"10px"}} >
          <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
          <div>
  
          <div className="container">
          <div className="progressbar-container">
              <div className="progressbar-complete" style={{width: `${progressTBranco}%`}}>
                  <div className="progressbar-liquid"></div>
              </div>
              <span className="progress">
              <b>{(countVotosBranco > 0 && countVotosBranco) || ("")} {(countVotosBranco === 1 && "voto em branco") || (countVotosBranco === 0 && "Nenhum voto em branco") || ("votos em branco")}</b>
              </span>
          </div>
          </div>
  
          </div>
          </Grid>
  
          </Grid>
          <Box sx={{p: 1, textAlign: 'right' }}></Box>
          </Card>
          <br />
          </div>
          </Grid>
         
          </>
  
  )
     
     )
  
  
  
  return(
  <>
  
  {chapas}
  
  </>
  )
  
  }
  
  
  
  
  // layout CANDIDATOS INDIVIDUAIS
  const ResultadoVotacao2 = (props) => {
  
    const listCandidatos = props.value[0];
    const coutCandidatos = props.value[0].length;
  
    const votositem = props.value[1][0]['votositem'];
    const votoBranco = props.value[1][0]['voto_branco'];
    const countVotosBranco = props.value[1][0]['votosBrancoCandidatoInd']; 
  
  
    let candidatos = [];
  
  
    for (let i = 0; i < coutCandidatos; i++) {
  
      const progressUni = props.value[0][i]['votado'];
      const progressT = ((progressUni/votositem)*100);
  
  
      candidatos.push(
        <>
      <Grid className='conteudo-resultado conteudo-resultado-indiv margin-conteudo-resultado' item xs={12} sm={3.5} >
          <div>
            <Card>
  
           {/*   
          <CardContent style={{textAlign:"center"}}>
  
             <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px"}}>
                        <Avatar 
                        alt={listCandidatos[i]['candidato']}
                        src="/static/illustrations/candidato/default3.png"
                        sx={{ width: 50, height: 50 }}
                        style={{marginLeft: "-1px"}} 
                        />&nbsp;&nbsp;&nbsp;{listCandidatos[i]['candidato']} 
             </Typography>
           </CardContent>
           */}
  
              <Scrollbar>
                <Stack spacing={1} sx={{ p: 2, pr: 0 }}>
                  <Typography variant="body2" noWrap>
                    <Grid item xs={11} style={{fontSize:"13px"}}>
                   
                    <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                       <Avatar 
                        alt={listCandidatos[i]['candidato']}
                        src="/static/illustrations/candidato/default3.png"
                        sx={{ width: 50, height: 50 }}
                        style={{marginLeft: "-1px"}} 
                        />&nbsp;&nbsp;&nbsp;{listCandidatos[i]['candidato']} 
                    </Typography>
                     
                    </Grid>
                  </Typography>
                </Stack>
              </Scrollbar>
    
              <Divider />
    
              <Grid container spacing={1} style={{marginTop:"10px"}} >
              <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
              <div>
    
              <div className="container">
              <div className="progressbar-container">
                  <div className="progressbar-complete" style={{width: `${progressT}%`}}>
                      <div className="progressbar-liquid"></div>
                  </div>
                  <span className="progress">
                  <b>{(progressUni > 0 && progressUni) || ("")} {(progressUni === 1 && "voto") || (progressUni === 0 && "Nenhum voto") || ("votos")}</b>
                  </span>
              </div>
              </div>
    
              </div>
              </Grid>
    
              </Grid>
              <Box sx={{p: 1, textAlign: 'right' }}></Box>
              </Card>
              <br/>
              </div>
              </Grid>
            
              </>
    
      )
  
  }  
  
  
  const progressTBranco = ((countVotosBranco/votositem)*100);
  
  
  (votoBranco === "SIM" &&
  
  
  candidatos.push(
  <>
  
  <Grid className='conteudo-resultado-voto-nulo conteudo-resultado conteudo-resultado-indiv' item xs={12} sm={3.5} >
  <div>
  <Card>
  
    <Scrollbar>  
      <Stack spacing={1} sx={{ p: 2, pr: 0 }} style={{alignItems: "center"}}>
        <Typography variant="body2" noWrap>
          <Grid item xs={11} style={{fontSize:"13px"}}>
          <Typography>
          <h5>Voto em Branco</h5>
          </Typography>                   
          </Grid>
        </Typography>
      </Stack>
    </Scrollbar>
    
    <Divider />
  
    <Grid container spacing={1} style={{marginTop:"10px"}} >
    <Grid item xs={12} sm={11} style={{paddingTop: "0px", marginLeft: '15px'}}>
    <div>
  
    <div className="container">
    <div className="progressbar-container">
        <div className="progressbar-complete" style={{width: `${progressTBranco}%`}}>
            <div className="progressbar-liquid"></div>
        </div>
        <span className="progress">
        <b>{(countVotosBranco > 0 && countVotosBranco) || ("")} {(countVotosBranco === 1 && "voto em branco") || (countVotosBranco === 0 && "Nenhum voto em branco") || ("votos em branco")}</b>
        </span>
    </div>
    </div>
  
    </div>
    </Grid>
  
    </Grid>
    <Box sx={{p: 1, textAlign: 'right' }}></Box>
    </Card>
    <br />
    </div>
    </Grid>
   
    </>
  
  )
  
  )
  
  
    
    return(
    <>
    
    {candidatos}
    
    </>
    )
    
    }
    




    const ListaCandidatosChapa = (props) => {

      const{
Chapa,
assembleia,
candidato,
cargo,
data,
descricaoCanditado,
descricaoChapa,
entidade,
idAssembleia,
idCandidato,
idChapa,
idEntidade,
idItem,
itemPauta,
tipo,
tipoCandidato
      }= props;


return(
  <>

{(idChapa === props.value &&
<Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <b style={{alignItems:"center"}}> 
                      <Typography gutterBottom variant="h5" component="div" style={{fontSize:"14px",display:"flex", alignItems:"center"}}>
                      &nbsp;&nbsp;
                       <Avatar 
                        alt={candidato}
                        src="/static/illustrations/candidato/default3.png"
                        sx={{ width: 30, height:30 }}
                        style={{marginLeft: "-1px"}} 
                        />
                        &nbsp;&nbsp;&nbsp;{candidato}    
                      </Typography>
                      </b>
                 </Form.Group>

                 
)}


  </>
)

    }



