import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Stack, Container, Typography, Button } from '@material-ui/core';
// layouts
import BotaoVoltarLayout from '../layouts/BotaoVoltarLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RecuperarSenhaForm } from '../components/authentication/recuperarSenha';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 448,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function recuperarSenha() {
  return (
    
    <RootStyle title = "Recuperação de senha | ASSEMBLEIACOOP" >

<BotaoVoltarLayout />

        <div className = 'fundotelalogin ' >
        <Container maxWidth = "sm" >
        <div className = 'logo-fencom' >
        <img src = "/static/logo.png"
        alt = "recuperação de senha" / >
        </div>

        <ContentStyle >

        <Stack sx = {
            { mb: 5 } } >
        <Typography variant = "h4"
        gutterBottom >
        Recuperação de Senha </Typography> <
        Typography sx = {
            { color: 'text.secondary' } } >
        </Typography> 
        </Stack>

        <RecuperarSenhaForm / >

        
        <MHidden width = "smUp" >
        <Typography variant = "body2"
        align = "center"
        sx = {
            { mt: 3 } } >
        </Typography> 
        </MHidden>


        </ContentStyle> 
        </Container>

        </div>


        <div className = 'arealaterallogin ' >
        <MHidden width = "mdDown" >
        <br/><br/>
        <h1> Produtos Fencom </h1> 
        <RouterLink to = {
            { pathname: "https://fencom.coop.br/sasc/" } }
        target = "_blank" >
        SASC </RouterLink>   
        
        <br/> <br/>

        <RouterLink to = {
            { pathname: "http://inss.fencom.com.br:8085/#/" } }
        target = "_blank" >
        INSS Cooperado 
        </RouterLink> 
        
        <br/> <br/>
        
        <RouterLink to = {
            { pathname: "https://fencom.coop.br/sasc-connect/" } }
        target = "_blank" >
        SASC Connect </RouterLink>
        
        <br/> <br/>

        <RouterLink to = {
            { pathname: "https://sasccm.coop.br/" } }
        target = "_blank" >
        SASC CM+
        </RouterLink>

        <br/> <br/>

        <h1> Suporte Fencom </h1> 
        <RouterLink to = {
            { pathname: "https://fencom.desk.ms/?LoginTicket" } }
        target = "_blank" >
        Desckmanger 
        </RouterLink>
        
        
          <br /><br />
          

        <h1> Baixe nossos aplicativos : </h1>

        <div className = 'icones-app' >
        <a href = "" > < img src = "/static/icone-google.png"
        alt = "login" /> </a> </div>

        <div className = 'icones-app' >
        <a href = "" > 
        <img src = "/static/icone-apple.png" alt = "login" /> </a> </div>

        </MHidden>

        </div> 
        </RootStyle>



  );
}
