import { styled } from '@material-ui/core/styles';
import { Card, 
  Stack, 
  Container, 
  Typography, 
  Box,
  Grid,
  TextField,
  CardContent,
  Button,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@material-ui/core';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import React, { useState, useEffect, useContext} from "react";
import axios from "axios";
import { useFormik, Form, FormikProvider} from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "../layouts/css/scrollbar.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import {Button as Button2} from 'reactstrap';
import { getItem} from '../components/services/LocaleStorage';
import functions from "../constants/serverFunctions";
import { IMaskInput } from 'react-imask';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import { Icon } from '@iconify/react';
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0),
  
}));


// ----------------------------------------------------------------------




VoteParticipante.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});



export default function VoteParticipante() {

  const token = getItem('token-assembleia-api');
  const IdItem = getItem('IdPropVote');
  const [ListProps, setListProps] = useState([]);
  const [QuantListProps, setQuantListProps] = useState([]);
  const [DadosItem, setDadosItem] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);




// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.itemPauta.BUSCAR_ITEM_PAUTA_REUNIAO_POR_ID_VOTACAOPROPOSTA_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
        toast.warn('Sessão expirada!');
      }else{
        (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
      }

    })
    .catch((err) => {
    });
}, [IdItem,token]);



//mapeia a consulta
const DADOSITEMSELECT = DadosItem.map((DadoItem, index) => ({

  idItem: DadoItem.idItem,
  itemPauta: DadoItem.itemPauta,
  codigoVotacao: DadoItem.codigo,
  Timerfim:DadoItem.Timerfim,
  statusVotacao: DadoItem.statusVotacao

  }));



  
//Consulta proposta do item 
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_VOTACAOPROPOSTA_COOP+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {

      if(res.data.result === "Token expirado"){
        logout();
        setIsAuthenticated(false);
     //   toast.warn('Sessão expirada!');
      }else{
        (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
        setQuantListProps(res.data.records)
      }

    })
    .catch((err) => {
    });
}, [token, IdItem]);



//mapeia a consulta
const LISTAPROPOSTA = ListProps.map((ListaProp, index) => ({

  idItem: ListaProp.idItem,
  identificador: ListaProp.identificador,
  idProposta: ListaProp.idProposta,
  descricaoProposta: ListaProp.descricaoProposta,
  codigoVotacao: ListaProp.codigoVotacao,
  Timerfim: ListaProp.Timerfim

  }));






  return (
    <RootStyle title="Votação Proposta | ASSEMBLEIACOOP">
       <AuthLayout>
           <Button
                className="btn btn-cinza" 
                component={RouterLink} 
                to="room"
                
              >
               <Icon icon={cornerupleft}/>&nbsp; Voltar
            </Button>
       </AuthLayout>


      <Container maxWidth="sm" style={{paddingLeft: "0px",paddingRight: "100px"}}>
        <ContentStyle>
          <Stack>


{
(QuantListProps > 1 && 
<div >
{DADOSITEMSELECT.map((dadoItem, id) => (
<ChapasMultiDetail key={id} {...dadoItem} value={LISTAPROPOSTA} />  
))}
</div>

)

|| 
(
<div >
{LISTAPROPOSTA.map((lista, id) => (
<ChapasDetail key={id} {...lista} value={QuantListProps} />  
))}
</div>

)
}

          </Stack>
 

        </ContentStyle>
      </Container>
      
    </RootStyle>
  );

  
}






const ChapasDetail = (props) => {

  const history = useHistory();

  const{
    idItem,
    identificador,
    idProposta,
    descricaoProposta,
    codigoVotacao
  }= props;


  const IdUser = getItem('userId');
  const token = getItem('token-assembleia-api');
  const TitleVote = getItem('TitleVotation');
  const cpfcnpj = getItem('tagId');

  const { setIsAuthenticated } = useContext(Auth);

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState('Selecione uma das opções acima');

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();

    handleShow();

  };





  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  const LoginSchema = Yup.object({

    codigovotacao: Yup
    .number()
    .required("Campo Obrigatório")
    .test("é maior", 'Código incorreto', function(value) {
     return value === codigoVotacao;
    })

    });



  const formik = useFormik({
    initialValues: {
      codigovotacao: ''
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {




axios.get(functions.votacao.VERIFICAR_VOTACAO+"/"+idItem+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
.then((retorno) => {

  if(retorno.data.result === "Token expirado"){
    logout();
    setIsAuthenticated(false);
    toast.warn('Sessão expirada!');
  }else{

 if(retorno.data.result.recordset[0]['tempoVotacao'] >= 0){
       if(retorno.data.result.recordset[0]['voto'] === "NÃO"){
                 
            axios.post(functions.votacao.INSERIR_VOTACAO_PROPOSTA_UNI, 
              {
                idItempauta: idItem,
                idChapa: idProposta,
                opcao: value,
                cpfcnpj: cpfcnpj
              },
              { headers: {"Authorization" : `${token}`} })
            .then((res) => {
                axios.post(functions.voto.INSERIR_VOTO, {
                  idItemPauta: idItem,
                  idUsuario: IdUser,
                  cpfcnpj: cpfcnpj
                  },
                  { headers: {"Authorization" : `${token}`} })
                .then((response) => {
                  toast.success('Votação realizada com sucesso!');
                  history.replace('/room');
                  });

            });
       
       }else{
       toast.error('Voto já realizado!');
       history.replace('/room');
       }

 }else{
   toast.error('Votação finalizada!');
   history.replace('/room');
 }


}




});

    
 

    }
  });




  const { errors, touched, //values, 
    isSubmitting, handleSubmit, getFieldProps } = formik;



  return(
<>

<div className='titulo-votacao'>
  <h2>{TitleVote}</h2>
</div>


<Card>
<CardContent>
<Grid container spacing={2}>
         
         <Grid item xs={9} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
               
               
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <b> {descricaoProposta} </b>
             </Box>
         </Grid>

         <Grid item xs={1} style={{fontSize:"25px"}}></Grid>
      
      </Grid>
 </CardContent>   
 </Card>
<br />

<form onSubmit={handleSubmit2}>
      <FormControl
        sx={{ m: 3 }}
        component="fieldset"
        error={error}
        variant="standard"
        style={{display: "contents"}}
      >
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          value={value}
          onChange={handleRadioChange}
        >

<Card>
<CardContent style={{paddingBottom:"10px",padding:"12px"}}>
<Grid container spacing={2}>    
         <Grid item xs={12} style={{fontSize:"13px"}}>
             <Box sx={{ mb: 0 }}>
             <FormControlLabel value="1" control={<Radio />} label="Concordo" />
               </Box>
         </Grid>    
      </Grid>
 </CardContent>   
 </Card>
 <br /> 
 <Card>
 <CardContent style={{paddingBottom:"10px",padding:"12px"}}>
<Grid container spacing={2}>    
         <Grid item xs={12} style={{fontSize:"13px"}}>
             <Box sx={{ mb: 0 }}>
             <FormControlLabel value="2" control={<Radio />} label="Discordo" />
               </Box>
         </Grid>    
      </Grid>
 </CardContent>   
 </Card>
 <br /> 
 <Card>
 <CardContent style={{paddingBottom:"10px",padding:"12px"}}>
<Grid container spacing={2}>    
         <Grid item xs={12} style={{fontSize:"13px"}}>
             <Box sx={{ mb: 0 }}>
             <FormControlLabel value="3" control={<Radio />} label="Me abstenho" />
               </Box>
         </Grid>    
      </Grid>
 </CardContent>   
 </Card>
 <br />          
        
         
        
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <Button 
              color="secondary"
              variant="outlined"
              sx={{ mr: 1 }}
              to="room"
              component={RouterLink}
            >
              Cancelar
            </Button>
 
        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="contained" disabled={error}>
        Confirmar
        </Button>
      </FormControl>
    </form>


<div className='modal-vote-election'>

<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" className='titulo-votacao-md' noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Código de votação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={2}>

<label>
Para confirmar seu voto, digite o código <u style={{color:"green",fontSize:"20px"}}>{codigoVotacao} </u> no campo abaixo
</label>
<br />



        <TextField 
        id="codigovotacao" 
        variant="standard"
        label="Código*"
        type="number"
        inputComponent={TextMaskCustom}
        {...getFieldProps('codigovotacao')}
        error={Boolean(touched.codigovotacao && errors.codigovotacao)}
        helperText={touched.codigovotacao && errors.codigovotacao}
        style={{width: "30%"}}
        />
        <br />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      <br/>
      <br />
      Ao confirmar o código, seu voto será computado.
    </label>
        
</Stack>

        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>

</div>


</>
  )


}












//Multiplas chapas
const ChapasMultiDetail = (props) => {

  const{
    idItem,
    itemPauta,
    codigoVotacao,
    statusVotacao
  }= props;

  const history = useHistory();

  const IdUser = getItem('userId');
  const token = getItem('token-assembleia-api');
 // const TitleVote = getItem('TitleVotation');
  const cpfcnpj = getItem('tagId');

  const { setIsAuthenticated } = useContext(Auth);

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState('Selecione uma das opções acima');

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();

    handleShow();

  };




  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  const LoginSchema = Yup.object({

    codigovotacao: Yup
    .number()
    .required("Campo Obrigatório")
    .test("é maior", 'Código incorreto', function(value) {
     return value === codigoVotacao;
    })

    });



  const formik = useFormik({
    initialValues: {
      codigovotacao: ''
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


   let escolha = (value === "0" && 3 || "0");
   let idProposta = (value === "0" && 0 || value);


      //console.log("IDItem: "+idItem)
     // console.log("IdChapa: "+idProposta)
    //  console.log("Opcao: "+escolha)
    //  console.log("IDUser: "+IdUser)

   

    

axios.get(functions.votacao.VERIFICAR_VOTACAO+"/"+idItem+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
.then((retorno) => {

  if(retorno.data.result === "Token expirado"){
    logout();
    setIsAuthenticated(false);
    toast.warn('Sessão expirada!');
  }else{

      if(retorno.data.result.recordset[0]['tempoVotacao'] >= 0){
          if(retorno.data.result.recordset[0]['voto'] === "NÃO"){
                 
            axios.post(functions.votacao.INSERIR_VOTACAO_PROPOSTA_UNI, 
              {
                idItempauta: idItem,
                idChapa: idProposta,
                opcao: escolha,
                cpfcnpj: cpfcnpj
              },
              { headers: {"Authorization" : `${token}`} })
            .then((res) => {
                axios.post(functions.voto.INSERIR_VOTO, {
                  idItemPauta: idItem,
                  idUsuario: IdUser,
                  cpfcnpj: cpfcnpj
                  },
                  { headers: {"Authorization" : `${token}`} })
                .then((response) => {
                  toast.success('Votação realizada com sucesso!');
                  history.replace('/room');
                  });

            });
       
       }else{
       toast.error('Voto já realizado!');
       history.replace('/room');
       }

    }else{
      toast.error('Votação finalizada!');
      history.replace('/room');
    }

  }


});


 
 

    }
  });




  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  return(
    <>

<div className='titulo-votacao'>
  <h2>{itemPauta}</h2>
</div>




<form onSubmit={handleSubmit2}>
      <FormControl
        sx={{ m: 3 }}
        component="fieldset"
        error={error}
        variant="standard"
        style={{display: "contents"}}
      >
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          value={value}
          onChange={handleRadioChange}
        >

{
/*
 <Card>
<CardContent>
<Grid container spacing={2}>
         <Grid item xs={12} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {"identificador"}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <FormControlLabel value="1" control={<Radio />} label="descricaoProposta" />
             </Box>
         </Grid>
</Grid>
 </CardContent>   
 </Card>
<br />

<Card>
<CardContent>
<Grid container spacing={2}>
         <Grid item xs={12} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'></div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <FormControlLabel value="3" control={<Radio />} label="Me abstenho" />
             </Box>
         </Grid>
</Grid>
 </CardContent>   
 </Card>
<br />
*/
}


{props.value.map((lista, id) => (
<ListaChapasDetail key={id} {...lista} />  
))}

        


<Card>
<CardContent>
<Grid container spacing={2}>
         <Grid item xs={12} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'></div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <FormControlLabel value="0" control={<Radio />} label="Me abstenho" />
             </Box>
         </Grid>
</Grid>
 </CardContent>   
 </Card>
<br />

        
         
        
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <Button 
              color="secondary"
              variant="outlined"
              sx={{ mr: 1 }}
              to="room"
              component={RouterLink}
            >
              Cancelar
            </Button>
 
        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="contained" disabled={error}>
        Confirmar
        </Button>
      </FormControl>
    </form>










<div className='modal-vote-election'>

<Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" className='titulo-votacao-md' noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Código de votação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={2}>

<label>
Para confirmar seu voto, digite o código <u style={{color:"green",fontSize:"20px"}}>{codigoVotacao} </u> no campo abaixo
</label>
<br />



        <TextField 
        id="codigovotacao" 
        variant="standard"
        label="Código*"
        type="number"
        inputComponent={TextMaskCustom}
        {...getFieldProps('codigovotacao')}
        error={Boolean(touched.codigovotacao && errors.codigovotacao)}
        helperText={touched.codigovotacao && errors.codigovotacao}
        style={{width: "30%"}}
        />
        <br />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      <br/>
      <br />
      Ao confirmar o código, seu voto será computado.
    </label>
        
</Stack>

        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Confirmar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>

</div>



    </>
        )


}




const ListaChapasDetail = (props) => {

  const{
    identificador,
    idProposta,
    descricaoProposta
  }= props;



  return(
    <>

<Card>
<CardContent>
<Grid container spacing={2}>
         <Grid item xs={12} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {identificador}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <FormControlLabel value={idProposta} control={<Radio />} label={descricaoProposta} />
             </Box>
         </Grid>
</Grid>
 </CardContent>   
 </Card>
<br />


    </>
        )

}

