import { Icon } from '@iconify/react';
import grid from '@iconify/icons-eva/grid-fill';
import filetext from '@iconify/icons-eva/file-text-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import bookopen from '@iconify/icons-eva/book-open-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Início',
    path: '/home',
    icon: getIcon(grid)
  },
  {
    title: 'Pauta',
    path: '/schedule',
    icon: getIcon(filetext)
  },
  {
    title: 'Lista de Presença',
    path: '/checkin',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Manual',
    path: 'manual',
    icon: getIcon(bookopen)
  },
  
];

export default sidebarConfig;
