import styled from 'styled-components';


export const TableContainer = styled.section`
 
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: inherit;


    th {
      color: #6e7ba9;
      font-weight: normal;
      padding: 20px 32px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

  

     
    
      tr:hover {
        background-color: #e0e0e0;
      }





  

    td {
      padding: 20px 32px;
      border: 0;
      background: rgba(229, 227, 227, 0.483);
      font-size: 16px;
      font-weight: normal;
      color: #000000;

      &.title {
        color: #363f5f;
      }

      &.Open {
        color: #12a454;
      }

      &.Process {
        color: #3385ff;
      }

      &.Close {
        color: #e83f5b;
      }

      &.Arquivada {
        color: #f09b0a;
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }

    
    
  }
`;