import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  //OutlinedInput,
  InputAdornment,
  Box,
  Input as Input3
} from '@material-ui/core';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import { Link as RouterLink, useHistory} from 'react-router-dom';
//Dashboard
import DashboardNavbar from '../layouts/dashboardAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardAdm/DashboardSidebar';
import Page from '../components/Page';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import {TableContainer} from "../layouts/css/styles.js";
import checkmarkcircle2 from '@iconify/icons-eva/checkmark-circle-fill';
import clock from '@iconify/icons-eva/clock-fill';
import closecircle from '@iconify/icons-eva/close-circle-fill';
import edit from '@iconify/icons-eva/edit-2-fill';
import folder from '@iconify/icons-eva/folder-fill';
import folderoutline from '@iconify/icons-eva/folder-outline';
import archiveoutline from '@iconify/icons-eva/archive-outline';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker2 from "./DatePicker";
import TimePicker2 from "./TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import moment from 'moment-timezone';

import {InputGroup, 
        InputGroupAddon, 
        InputGroupText, 
        Input, 
        Button as Button2 
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import functions from "../constants/serverFunctions";
import TimeField from 'react-simple-timefield';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

import { Input as Input2 } from 'antd';
import 'antd/dist/antd.css';

//import FormControl from '@mui/material/FormControl';
//import InputLabel from '@mui/material/InputLabel';

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


export default function DashboardLayout( props) {

  const token = getItem('token-assembleia-api');
  const entidade = getItem('institutionName');
  const IdEntidade = getItem('institutionId'); 
  const IdUser = getItem('userId'); 
  const [Meetings, setMeetings] = useState([]);
  const {setIsAuthenticated } = useContext(Auth);
  const history = useHistory();



//Consulta Reunioes 
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

      if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          toast.warn('Sessão expirada!');
      }else{
            if(res.data.records === 1){
                axios
                .get(functions.assembleia.BUSCAR_LISTA_ASSEMBLEIA_POR_ENTIDADE_ADM+"/"+entidade, { headers: {"Authorization" : `${token}`} })
                .then((res) => {
                (res.data.records !== 0 && setMeetings(res.data.result.recordset))
                })
                .catch((err) => {});
            }else{
                logout();
                setIsAuthenticated(false);
                toast.error('Token inválido!');
            } 
      }

       
  });        

  const interval =  window.setInterval(() => {
    axios
    .get(functions.assembleia.BUSCAR_LISTA_ASSEMBLEIA_POR_ENTIDADE_ADM+"/"+entidade, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
        }else{
          (res.data.records !== 0 && setMeetings(res.data.result.recordset))
        }
      })
      .catch((err) => {
      });
    }, 5000);
    return () => clearInterval(interval);

}, [IdEntidade,IdUser,entidade,token,setIsAuthenticated]);




//mapeia a consulta 
const MEETINGLIST = Meetings.map((Meeting, index) => ({

  /*
  id: Meeting.ID_Assembleia,
  titulo: Meeting.Titulo_Assembleia,
  DataReuniao: Meeting.Data_Assembleia,
  Link_Assembleia: Meeting.Link_Assembleia,
  Hora1: Meeting.Horario_PrimeiraChamada,
  Hora2: Meeting.Horario_SegundaChamada,
  Hora3: Meeting.Horario_TerceiraChamada,
  Descricao: Meeting.Descricao_Assembleia,
  DataHoraInicio: Meeting.DataInicio_Assembleia,
  DataHoraFim: Meeting.DataFim_Assembleia,
  ID_UsuarioIniciou: Meeting.ID_UsuarioIniciou_Assembleia,
  ID_UsuarioEncerrou: Meeting.ID_UsuarioEncerrou_Assembleia,
  ID_Entidade: Meeting.ID_Entidade_Assembleia,
  Entidade: Meeting.Entidade_Assembleia,
  Arquivada:Meeting.Assembleia_Arquivada
  */

  id: Meeting.ID_Assembleia,
  titulo: Meeting.Titulo_Assembleia,
  DataReuniao: Meeting.Data_Assembleia,
  Link_Assembleia: Meeting.Link_Assembleia,
  Hora1: Meeting.Horario_PrimeiraChamada,
  Hora2: Meeting.Horario_SegundaChamada,
  Hora3: Meeting.Horario_TerceiraChamada,
  Descricao: Meeting.Descricao_Assembleia,
  TimerInicio: Meeting.TimerInicio,
  TimerFim: Meeting.TimerFim,
  ID_Entidade: Meeting.ID_Entidade_Assembleia,
  Entidade: Meeting.Entidade_Assembleia,
  Arquivada: Meeting.Assembleia_Arquivada

  }));

    

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  

//validar campos
  const LoginSchema = Yup.object().shape({
    titulo: Yup.string().required('campo obrigatória'),
    data: Yup.date().required('Campo obrigatorio'),
    //link_transmissao: Yup.string().required('campo obrigatória'),


    primeira_chamada: Yup
    .string()
    .required("Campo Obrigatório")
    .test("é maior", "Favor informar horário", function(value) {
     return value !== "00:00";
    }),
    segunda_chamada: Yup
    .string()
    .required("campo obrigatório")
    .test("e maior", "Campo deve ser superior ao anterior", function(value) {
      const { primeira_chamada } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(primeira_chamada, "HH:mm"));
    }),
    terceira_chamada: Yup
    .string()
    .required("campo obrigatório")
    .test("e maior", "Campo deve ser superior ao anterior", function(value) {
      const { segunda_chamada } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(segunda_chamada, "HH:mm"));
    })


    
   });

  const formik = useFormik({
    initialValues: {
      titulo: '',
      data: '',
      link_transmissao: '',
      primeira_chamada: '',
      segunda_chamada: '',
      terceira_chamada: '',
      descricao: '',
      fk_entidade: IdEntidade,
      fk_usuario: IdUser
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


   axios.post(functions.assembleia.INSERIR_ASSEMBLEIA, values,{ headers: {"Authorization" : `${token}`}})
      .then((response) => {
        //toast.success('Reunião criada com sucesso!');
       // window.location.reload(false);
        toast.success('Reniào criada com sucesso!');
        //history.replace('/Meetings');
        history.go(0)
      });
 
               

    }
  });




  const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps 
  } = formik;




  const [values2, setValues2] = React.useState({
    amount: ''
  });

  const handleChange = (prop) => (event) => {
    setValues2({ ...values2, [prop]: event.target.value });
  };



  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      
      <MainStyle>

      <Page title="Reuniões | ASSEMBLEIACOOP">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{marginBottom: "0px"}}>
        <div className='conteudo-topo-agenda'>
        
        <div className="titulo-resultados">
        <h3>Reuniões</h3>
        </div>

          <Button className="btn-success btn-linhamento" 
           variant="contained"
           component={RouterLink}
           to="#"
           startIcon={<Icon icon={plusFill} />}
           onClick={handleShow}
           >
             Criar Reunião
          </Button>

          </div>


          {/*Botao de teste */}






      <Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Criar Reunião</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: 480, overflow: 'scroll'}}>


      <Stack spacing={3}>

        <TextField 
        id="titulo"
        variant="standard"
        label="Nome da Reunião*"
        {...getFieldProps('titulo')}
        error={Boolean(touched.titulo && errors.titulo)}
        helperText={touched.titulo && errors.titulo}
        />


        <DatePicker2 id="data" name="data" />
        <label style={{marginTop:"2px",fontSize:"75%"}}>
          A data não pode ser anterior que a sua data de hoje.  
        </label>

{/*
        <TextField 
        style={{marginTop:"10px"}}
        id="link_transmissao" 
        label="Link da Reunião*" 
        variant="standard"
        {...getFieldProps('link_transmissao')}
        error={Boolean(touched.link_transmissao && errors.link_transmissao)}
        helperText={touched.link_transmissao && errors.link_transmissao}
         />

<label style={{marginTop:"0px",fontSize:"75%"}}>
    Exemplo: https://siteexemplo.com.br  
  </label> 

        <br />
*/}


{/*
        <Input2 
        addonBefore="https://" 
        id="link_transmissao" 
        placeholder="Link da Reunião"
        {...getFieldProps('link_transmissao')}
        error={Boolean(touched.link_transmissao && errors.link_transmissao)}
        helperText={touched.link_transmissao && errors.link_transmissao}
        />

        <label style={{marginTop:"0px",fontSize:"75%"}}>
    Exemplo: https://siteexemplo.com.br  
        </label> 

        <br />
*/}


        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        {/*
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Link da Reunião</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={values2.amount}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start">https://</InputAdornment>}
            label="Amount"
          />
        </FormControl>
       */ }
        
        <FormControl fullWidth sx={{ m: 1 }} variant="standard" style={{width:"150%"}}>
          <InputLabel htmlFor="standard-adornment-amount">Link da Reunião</InputLabel>
          <Input3
            id="link_transmissao" 
            value={values2.amount}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start">https://</InputAdornment>}
            {...getFieldProps('link_transmissao')}
            error={Boolean(touched.link_transmissao && errors.link_transmissao)}
            helperText={touched.link_transmissao && errors.link_transmissao}
          />
           <label style={{marginTop:"0px",fontSize:"75%"}}>
              Exemplo: https://siteexemplo.com.br  
            </label> 
        </FormControl>
      </div>
    </Box>

         

        <label>
           <h5>Defina os horários para chamada</h5>
        </label> 


  <Grid container spacing={1} style={{marginTop:"10px"}} >
    
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
     <div>
      <TimeField 
          id="primeira_chamada" 
          input={<TextField label="1º Chamada*" variant="standard"/>}  
          {...getFieldProps('primeira_chamada')}
          error={Boolean(touched.primeira_chamada && errors.primeira_chamada)}
          helperText={touched.primeira_chamada && errors.primeira_chamada}   
      />
      </div>
    </Grid>

    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
      <div>
       <TimeField 
          id="segunda_chamada" 
          input={<TextField label="2º Chamada*" variant="standard"/>}  
          {...getFieldProps('segunda_chamada')}
          error={Boolean(touched.segunda_chamada && errors.segunda_chamada)}
          helperText={touched.segunda_chamada && errors.segunda_chamada}   
      />
      </div>
    </Grid>
   
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
      <div>
       <TimeField 
          id="terceira_chamada" 
          input={<TextField label="3º Chamada*" variant="standard"/>}  
          {...getFieldProps('terceira_chamada')}
          error={Boolean(touched.terceira_chamada && errors.terceira_chamada)}
          helperText={touched.terceira_chamada && errors.terceira_chamada}   
      />
      </div>
    </Grid>

</Grid>

        <br />


        <div class="form-group" style={{marginTop:"10px"}}>
        <label for="descricao">Descrição</label>
        <textarea
          class="form-control"
          id="descricao"
          rows="5"
          {...getFieldProps('descricao')}
         >
         </textarea>
         </div>
 
    </Stack>


    </Modal.Body>


        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
       

</Modal>


        </Stack>

       
       
        <TableContainer className='tabela-reunioes'>
          <table>
            <thead>
                <th>Nome</th>
                <th>Data</th>
                <th>Status</th>
                <th></th>
            </thead>

            <tbody>

            {MEETINGLIST.map((Meeting, id) => (
              <EntidadeDetail key={id} {...Meeting} />  
              ))}

            </tbody>
          </table>
        </TableContainer>

          
        
        </Container>
        </Page>
        <ToastContainer />
      </MainStyle>
    </RootStyle>
    


  );


}


//botao arquivar
const arquivarClick = (id) => {
  const token = getItem('token-assembleia-api');

    axios.post(functions.assembleia.ARQUIVAR_ASSEMBLEIA, 
      {id:id},
      { headers: {"Authorization" : `${token}`} }
      )
      .then((response) => {
     toast.success('Reunião Arquivada!');
     window.location.reload(false);
      });

}


//botao desarquivar
const desarquivarClick = (id) => {
  const token = getItem('token-assembleia-api');

   axios.post(functions.assembleia.DESARQUIVAR_ASSEMBLEIA, 
    {id:id},
    { headers: {"Authorization" : `${token}`} }
    )
      .then((response) => {
        toast.success('Reunião desarquivada!');
        window.location.reload(false);
      });

}




const EntidadeDetail = (props) => {

  const { id, 
          titulo, 
          DataReuniao, 
          Link_Assembleia, 
          Hora1, 
          Hora2, 
          Hora3, 
          Descricao, 
          TimerInicio,
          TimerFim,
          Arquivada
         } = props;
  
   const token = getItem('token-assembleia-api');   


    const RoomClick = (IdRoomSelect) =>{
     
        addItem('IdRoom', IdRoomSelect);
        addItem('DscRoom', titulo);  

    }  



    const date = DataReuniao;
    //const format = "YYYY-MM-DD";
    //const timezone = "America/Sao_Paulo";
    //const dateMoment = moment.tz(date, format, timezone);
    const dateMoment = moment(date).utc().format('YYYY-MM-DD'); 


    const time1 = moment.utc(Hora1).format('HH:mm');
    const time2 = moment.utc(Hora2).format('HH:mm');
    const time3 = moment.utc(Hora3).format('HH:mm');
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [value1] = useState(time1);
    const [value2] = useState(time2);
    const [value3] = useState(time3);




  const LoginSchema = Yup.object().shape({
    titulo: Yup.string().required('campo obrigatória'),
    data: Yup.date().required('Campo obrigatorio'),
    //link_transmissao: Yup.string().required('campo obrigatória'),
    primeira_chamada: Yup.string().required('campo obrigatória'),
    segunda_chamada: Yup.string().required('campo obrigatória'),
    terceira_chamada: Yup.string().required('campo obrigatória'),
    //descricao: Yup.string().required('campo obrigatória')
   });

  const formik = useFormik({
    initialValues: {
      titulo: titulo,
      data: dateMoment,
      link_transmissao: Link_Assembleia,
      primeira_chamada: value1,
      segunda_chamada: value2,
      terceira_chamada: value3,
      descricao: Descricao,
      id: id
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {


axios.post(functions.assembleia.ATUALIZAR_ASSEMBLEIA, 
        values,
        { headers: {"Authorization" : `${token}`} }
        )
      .then((response) => {
          toast.success('Reunião atualizada com sucesso!');
          window.location.reload(false);
      })
      .catch((err) => {
        });         

    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  const [values2, setValues2] = React.useState({
    amount: ''
  });

  const handleChange = (prop) => (event) => {
    setValues2({ ...values2, [prop]: event.target.value });
  };



    return (
      <>
  
        <tr id={id} style={{cursor:"pointer"}}>
          <td onClick={(e) => RoomClick(id, e)} >
            <RouterLink to="/home" style={{textDecoration:"none",color:"#000000"}}>
            {titulo}
            </RouterLink>
          </td>

          <td onClick={(e) => RoomClick(id, e)}> 
          <RouterLink to="/home" style={{textDecoration:"none",color:"#000000"}}>
          <Moment format="DD/MM/YYYY">
            {dateMoment}
            </Moment>
            {' às '} 
              {moment.utc(Hora1).format('HH:mm:ss')}
              </RouterLink>
            </td>
         
  
          <td onClick={(e) => RoomClick(id, e)} className={(Arquivada === 'SIM' && 'Arquivada') || (TimerFim < 0 && 'Close') || (TimerInicio >= 0 && 'Process') || 'Open'}>
           <RouterLink to="/home" style={{textDecoration:"none",color:(Arquivada === 'SIM' && "#f09b0a") || (TimerFim < 0 && 'grey') || (TimerInicio >= 0 && '#0d6efd') || '#27ab55'}}>
             <Icon icon={(Arquivada === 'SIM' && archiveoutline) || (TimerFim < 0 && closecircle) || (TimerInicio >= 0 && clock) || checkmarkcircle2} />
            {(Arquivada === 'SIM' && ' Arquivada') || (TimerFim < 0 && ' Encerrada') || (TimerInicio >= 0 && ' Em breve') || ' Liberada'}
            </RouterLink>
             </td>
  
          <td>
          <Tooltip title={(Arquivada === 'SIM' && 'Desarquivar Reunião') || (TimerFim < 0 && 'Arquivar Reunião') || 'Editar Reunião'}>   
          <IconButton>
          <Icon icon={(Arquivada === 'SIM' && folderoutline) || (TimerFim < 0 && folder) || edit} onClick={(Arquivada === 'SIM' && ((e) => desarquivarClick(id, e))) || (TimerFim < 0 && ((e) => arquivarClick(id, e) )) || handleShow} />
          </IconButton>
          </Tooltip>
          </td>
  
      </tr>







<Modal 
    visible style={{ zIndex: 1200}}
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
>


  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Editar Reunião</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: 480, overflow: 'scroll'}}>


      <Stack spacing={3}>

        <TextField 
        id="titulo" 
        variant="standard"
        label="Nome da Reunião*"
        {...getFieldProps('titulo')}
        error={Boolean(touched.titulo && errors.titulo)}
        helperText={touched.titulo && errors.titulo}
        />



{((TimerInicio >= 0 && 
<DatePicker2 id="data" name="data" />)
  ||
  (<InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{fontSize:"x-large"}}>
          {<Icon icon={calendarFill} className='cor-icones' />}
          </InputGroupText>
        </InputGroupAddon>
        <Input value={moment(DataReuniao).utc().format('DD/MM/YYYY')} 
          placeholder="dd/mm/aaaa*" 
          style={{flex: "0.4 1 auto"}}
          disabled={true}
          />
      </InputGroup>)

  )}
  

  <label style={{marginTop:"0px",fontSize:"75%"}}>
    A data não pode ser anterior que a sua data de hoje.  
  </label> 

 
 

{
/*
        <TextField 
        style={{marginTop:"10px"}}
        id="link_transmissao" 
        label="Link da Reunião*" 
        variant="standard"
        {...getFieldProps('link_transmissao')}
        error={Boolean(touched.link_transmissao && errors.link_transmissao)}
        helperText={touched.link_transmissao && errors.link_transmissao}
         />

<label style={{marginTop:"0px",fontSize:"75%"}}>
    Exemplo: https://siteexemplo.com.br  
  </label> 
*/}

<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>  
        <FormControl fullWidth sx={{ m: 1 }} variant="standard" style={{width:"150%"}}>
          <InputLabel htmlFor="standard-adornment-amount">Link da Reunião</InputLabel>
          <Input3
            id="link_transmissao" 
            value={values2.amount}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start">https://</InputAdornment>}
            {...getFieldProps('link_transmissao')}
            error={Boolean(touched.link_transmissao && errors.link_transmissao)}
            helperText={touched.link_transmissao && errors.link_transmissao}
          />
           <label style={{marginTop:"0px",fontSize:"75%"}}>
              Exemplo: https://siteexemplo.com.br  
            </label> 
        </FormControl>
      </div>
    </Box>


         

        <label>
           <h5>Defina os horários para chamada</h5>
        </label> 


  <Grid container spacing={1} style={{marginTop:"10px"}} >
    
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
     <div>
      <label style={{fontSize: "14px"}}>  
      1º Chamada*
      </label>

      {((TimerInicio >= 0 && 

        <TimePicker2 id="primeira_chamada" name="primeira_chamada" />)
||

          (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time1} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}

      </div>
    </Grid>

    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
      <div>
        <label style={{fontSize: "14px"}}>  
          2º Chamada*
        </label>
        {((TimerInicio >= 0 && 
      <TimePicker2 id="segunda_chamada" name="segunda_chamada" />)
      ||

      (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time2} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}
      </div>
    </Grid>
   
    <Grid item xs={12} sm={3.5} style={{paddingTop: "0px"}}>
      <div>
        <label style={{fontSize: "14px"}}>  
          3º Chamada*
        </label>
        {((TimerInicio >= 0 && 
        <TimePicker2 id="terceira_chamada" name="terceira_chamada" />)
        ||

        (<InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} className='cor-icone-relogio' />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={time3} 
            placeholder="HH:mm*" 
           disabled={true}
            />
          </InputGroup>)

      )}
      </div>
    </Grid>

</Grid>

<br />


        <div class="form-group" style={{marginTop:"10px"}}>
        <label for="descricao">Descrição</label>
        <textarea
          class="form-control"
          id="descricao"
          rows="5"
          {...getFieldProps('descricao')}
         >
         </textarea>
         </div>
 
    </Stack>

    </Modal.Body>
        
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
  

</Modal> 


      </>
    );


}