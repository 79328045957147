import * as Yup from 'yup';
import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useHistory} from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
//import InputMask from 'react-input-mask';
//import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import {
  Link,
  Stack,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
//Notificacao
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Autenticar
import { recuperarSenha } from "./RecuperarSenhaAuth";
import Auth from "../../contexts/Auth";
import { number } from 'joi';

// ----------------------------------------------------------------------

export default function RecuperarSenhaForm( ) {

  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

  const [Entidades, setEntidades] = useState();
  const [Perfil, setPerfil] = useState();

  let history = useHistory();

  const LoginSchema = Yup.object().shape({
   emailUsuario: Yup.string().required('Campo obrigatório')
  });

  const formik = useFormik({
    initialValues: {
      emailUsuario: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      //const cpfCnpjValue = formik.values.emailUsuario.trim();
      const value = formik.values.emailUsuario.trim();

      if (value.match(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/) || (value.length ==11 && /^\d+$/.test(value))) {
        //history.push('https://app.fencom.coop.br/recuperarAcesso.php');
        window.open('https://app.fencom.coop.br/recuperarAcesso.php', '_blank');
      } else if (value.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/) || (value.length ==14 && /^\d+$/.test(value))) {
        window.open('https://app.fencom.coop.br/recuperarAcesso.php', '_blank');
      } else if (value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        try {
          const response = await recuperarSenha(values);
          toast.success('Nova senha enviado para o e-mail');
        
        } catch (error) {
        
        }
        
      } else {
        toast.error("Dados incorretos",{autoClose:3000});
      }




    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldTouched } = formik;

  useEffect(() => {
    if (isAuthenticated) {
     history.push({
           pathname: `/Instituicao`,
           state: { entidades: Entidades,nivelPerfil: Perfil  },
         });
    }
  }, [history, isAuthenticated,Entidades,Perfil]);

  const [cpfCnpj, setCpfCnpj] = useState("");
  
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
         {/*} <CpfCnpj
            fullWidth
            placeholder="CPF / CNPJ / E-mail"
            value={formik.values.emailUsuario}
            id={"emailUsuario"}
            name={"emailUsuario"}
            onChange={(event) => {
              formik.setFieldValue('emailUsuario', event.target.value);
              setCpfCnpj(event.target.value);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.emailUsuario && errors.emailUsuario)}
            helperText={touched.emailUsuario && errors.emailUsuario ? errors.emailUsuario : ""}
            style={{
              border: touched.emailUsuario && errors.emailUsuario ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: 4,
              padding: '10px 12px',
              fontSize: 16,
              marginTop: -8, // Ajuste o marginTop para se alinhar com o TextField superior
            }}
          />
          {touched.emailUsuario && !formik.values.emailUsuario && (
            <Typography variant="body2" color="error"  style={{ marginTop: -1 }}>
              Campo obrigatório
            </Typography>
          )}*/}
           <TextField
            fullWidth
            autoComplete="emailUsuario"
            type="text"
            label="CPF / CNPJ / E-mail"
            {...getFieldProps('emailUsuario')}
            error={Boolean(touched.emailUsuario && errors.emailUsuario)}
            helperText={touched.emailUsuario && errors.emailUsuario}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
         
        </Stack>

        <LoadingButton
          fullWidth 
          className = 'btn-login'
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Recuperar senha
        </LoadingButton>
      </Form>

      <ToastContainer />
    </FormikProvider>
  );
}

