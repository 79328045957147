import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHome/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHome/DashboardSidebar';
import Page from '../components/Page';
import { 
  Grid, 
  Stack,
  Button as Button1,
  Container,
  Typography, 
  Box,
  Card,
  CardContent,
  Tab,
  Tabs,
  CardActionArea,
  CardActions,
  Avatar,
  AvatarGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink} from 'react-router-dom';
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal} from 'antd';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import functions from "../constants/serverFunctions";
import "react-widgets/styles.css";
import moment from 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.css';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




//vertical
function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{paddingTop:"5px"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}






export default function Candidates() {

  const token = getItem('token-assembleia-api');
  const IdItem = getItem('IdPropsVoteItem'); 
  const TipoItem = getItem('ThemePropsVoteItem'); 
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [open, setOpen] = useState(false);
  const [DadosItem, setDadosItem] = useState([]);
  const [AbasTipoEleicao, setAbasTipoEleicao] = useState([]);
  const [QuantCandInd, setQuantCandInd] = useState([]);
  const [ListProps, setListProps] = useState([]);
  const [ListCandts, setListCandts] = useState([]);
  const [ListCandtsInd, setListCandtsInd] = useState([]);
  const [ListPropostas, setListPropostas] = useState([]);
  const [CountProps, setCountProps] = useState([]);
  const [TimerVt, setTimerVt] = useState();
  const { setIsAuthenticated } = useContext(Auth);
 
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





// Consulta Dados do item da Pauta da Reuniao
useEffect(() => {
axios
.get(functions.itemPauta.BUSCAR_ITEM_PAUTA_POR_REUNIAO_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
  .then((res) => {
 
      if(res.data.result === "Token expirado"){
        logout();
          setIsAuthenticated(false);
          toast.warn('Sessão Expirada!');
      }else{
        (res.data.records !== 0 && setDadosItem(res.data.result.recordset))
        setAbasTipoEleicao(res.data.result.recordset[0]['tipoEleicao'])
        setTimerVt(res.data.result.recordset[0]['statusVotacao'])
      }
   
  })
  .catch((err) => {
  });

}, [IdUser,IdItem,token,setIsAuthenticated]);




//mapeia a consulta
const DADOSITEMSELECT = DadosItem.map((DadoItem, index) => ({

itemPauta: DadoItem.itemPauta

}));






//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_CHAPAS_ELEICAO_POR_IDITEM_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
        (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
        //setQuantListProps(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token]);


//Consulta lista de candidatos do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_POR_CHAPA_POR_IDITEM_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
        (res.data.records !== 0 && (setListCandts(res.data.result.recordset)))
    })
    .catch((err) => {
    });
}, [IdItem,token]);


//Consulta lista de candidatos INDIVIDUAL do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.candidato.BUSCAR_LISTA_CANDIDATOS_INDIVIDUAL_POR_IDITEM_COOP+"/"+IdItem, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
        (res.data.records !== 0 && (setListCandtsInd(res.data.result.recordset)))
        setQuantCandInd(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token]);


//Consulta proposta do item da Pauta da Reuniao
useEffect(() => {
  axios
  .get(functions.chapaProposta.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_COOP+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      (res.data.records !== 0 && (setListPropostas(res.data.result.recordset)))
      setCountProps(res.data.records)
    })
    .catch((err) => {
    });
}, [IdItem,token]);








  return(

    <RootStyle>
    <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
    <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
    

    
      <MainStyle >
        <Page title="Eleição | ASSEMBLEIACOOP">
            <Container>
                <Grid container spacing={1}  >
                      {DADOSITEMSELECT.map((dadoitem, id) => (
                        <DADOSTOPO key={id} {...dadoitem} />       
                      ))}
                      </Grid>
            </Container>
        </Page>




{

(TipoItem === "Proposta" && 

<Box sx={{ width: '100%' }}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Propostas" {...a11yProps(0)} />
    </Tabs>
  </Box>
      <TabPanel value={value} index={0}>
<Grid container spacing={1} style={{marginTop:"10px"}} >
<Grid item xs={12} sm={6} style={{paddingTop: "0px", marginLeft: '15px'}}>
      <div>
          <ListaPropostasDetail value={[ListPropostas,DadosItem,TimerVt]} />
      </div>
</Grid>
</Grid>
      </TabPanel>
  </Box>



)

||

(AbasTipoEleicao === "Chapa Eleitoral" &&

(<Box sx={{ width: '100%' }}>
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
    </Tabs>
  </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500 }} >
            <ListaChapaDetail value={[ListProps,ListCandts,DadosItem]}/>
        </Box>
      </TabPanel>
  </Box>
    
)
)



||


(AbasTipoEleicao === "Candidato Individual" &&


(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Candidatos Individuais" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <ListaIndividualDetail value={[ListCandtsInd,DadosItem]}/>   
        </Grid>
      </TabPanel>

</Box>)


)



||


(AbasTipoEleicao === "Ambos" &&

(<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Chapa Eleitoral" {...a11yProps(0)} />
          <Tab label="Candidatos Individuais" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500 }} >
            <ListaChapaDetail value={[ListProps,ListCandts,DadosItem]}/>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
      <Grid container spacing={2}>
        <ListaIndividualDetail value={[ListCandtsInd,DadosItem]}/>   
      </Grid>
      </TabPanel>
      
</Box>)

)



                  
}








      </MainStyle>
    </RootStyle>


  )



}






// >>>>>>>>>>>>>>>>>>> Dados do item - Topo Pagina <<<<<<<<<<<<<<<<<<<<<<<<
const DADOSTOPO = (props) => {

  const {itemPauta} = props;

 
return (
<>

<Grid item xs={12} sm={8.5} style={{paddingTop: "0px"}}>
<div className = 'topotitulocandidatos' >
  <Stack sx={{ mb: 3 }}>
    <Typography variant="h4" gutterBottom>
    {itemPauta}
    </Typography>
  </Stack>
</div>
</Grid>

</>

);

}





// >>>>>>>>>>>>>>>>>>> FIM Dados do item - Topo Pagina <<<<<<<<<<<<<<<<<<<<<<<<




// >>>>>>>>>>>>>>>>>>> Lista Chapas do Item(eleicao) <<<<<<<<<<<<<<<<<<<<<<<<
const ListaChapaDetail = (props) => {


  const IdItem= getItem('IdProps'); 
  const token = getItem('token-assembleia-api');

  const listChapas = props.value[0];
  const coutChapa = props.value[0].length;
  const listCandidatos = props.value[1];
  const coutCandidatos = props.value[1].length;
  const dadosDoItem = props.value[2];
  const statusReuniao = dadosDoItem[0]['statusVotacao'];
  

  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [nomeModal, setNomeModal] = useState("");
  const [idnomeModal, setIdNomeModal] = useState("");
  const [imgModal, setImgModal] = useState("");
  const [cargoModal, setCargoModal] = useState("");
  const [dscModal, setDscModal] = useState("");


  const showModal = (nome,id,cargo,dsc) => { 
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    if(id==129){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
    }else if(id==130){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
    }else if(id==131){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
    }else if(id==132){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
    }else if(id==136){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
    }else if(id==137){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
    }   
    else{
      imgAvatar = "/static/illustrations/candidato/default3.png";
    }

    setIsModalVisible(true);
    setNomeModal(nome);
    setIdNomeModal(id);
    setImgModal(imgAvatar);
    setCargoModal(cargo);
    setDscModal(dsc);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [value2, setValue2] = React.useState(0);
  const handleChange2 = (event2, newValue2) => {
    setValue2(newValue2);
  };


  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdPropVote', IdPropsSelect);
    addItem('ThemeVotation', dadosDoItem[0]['tipoItem']);
    addItem('TitleVotation', dadosDoItem[0]['itemPauta']);
  
  } 



  let chapas = []
  let users = []
  let candidatos = []
  let listacandidatos = []



  for (let i = 0; i < coutChapa; i++) {
    users = []
    listacandidatos = []
    for(let j = 0; j < coutCandidatos; j++){
        if(listCandidatos[j]['idChapa'] === listChapas[i]['idChapa']){
          let imgAvatar = "/static/illustrations/candidato/default3.png";
          if(listCandidatos[j]['idCandidato']==129){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
          }else if(listCandidatos[j]['idCandidato']==130){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
          }else if(listCandidatos[j]['idCandidato']==131){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
          }else if(listCandidatos[j]['idCandidato']==132){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
          }else if(listCandidatos[j]['idCandidato']==136){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
          }else if(listCandidatos[j]['idCandidato']==137){
            imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
          }   
          else{
            imgAvatar = "/static/illustrations/candidato/default3.png";
          }

          users.push(<Avatar 
                        alt={listCandidatos[j]['candidato']}
                        //src="/static/illustrations/candidato/default3.png"
                        src={imgAvatar}
                        sx={{ width: 30, height: 30 }}
                        style={{marginLeft: "-1px"}} 
                        />)


listacandidatos.push(
      
    <Grid item xs={4}>
      <Card sx={{ maxWidth: 180 }} 
      onClick={() => showModal(listCandidatos[j]['candidato'],listCandidatos[j]['idCandidato'],listCandidatos[j]['cargo'],listCandidatos[j]['descricaoCanditado'])}
      >
         <CardActionArea style={{textAlign:"center"}}>
            <Stack direction="row" spacing={2}  style={{paddingLeft:"20%",paddingTop:"10%"}}>
              <Avatar 
                alt="Remy Sharp" 
                //src="/static/illustrations/candidato/default3.png"
                src={imgAvatar}
                sx={{ width: 100, height: 100 }} />
            </Stack>
           <CardContent style={{textAlign:"center"}}>
             <Typography gutterBottom variant="h5" component="div" className = 'nome-candidatos'>
             {listCandidatos[j]['candidato']}
             </Typography>
             <Typography variant="body2" color="text.secondary">
           { (listCandidatos[j]['cargo'] !== null && (listCandidatos[j]['cargo'])) || ("-") }
             </Typography>
           </CardContent>
         </CardActionArea>
       </Card>

    </Grid>

      )

   }
 }
 
chapas.push(
          <Tab label={
            <Grid item xs={12} style={{textAlign:"left",width: "100%"}}>
              <Card sx={{ maxWidth: 350 }}>
                  <CardActionArea >
                    <CardContent style={{padding:"15px"}}>
                      <Typography variant="body2" color="text.secondary" className = 'titulos-chapas'>
                      {listChapas[i]['descricaoChapa']}
                      </Typography>
                    </CardContent>
                    <CardActions>
                     {(listChapas[i]['quantCandidatos'] > 0 && (<AvatarGroup max={6}>{users}</AvatarGroup>))
                     ||
                     (<></>)
                     }  
                    </CardActions>
                 </CardActionArea>
             
              </Card>
            </Grid>
                    }
              {...a11yProps2(i)} />    
            )



candidatos.push(
            <TabPanel2 value={value2} index={i}>
                <Grid container spacing={2} >
                 
                 {listacandidatos}

                </Grid>
        
            </TabPanel2>

          )


    
}






return(
  <>


<Grid container spacing={1}>     
 <Grid item xs={12} sm={12} style={{paddingTop: "0px"}}>
  <div>
    <CardContent style={{padding:"10px"}}>
      <Grid container spacing={2}>

        <Grid item xs={4} style={{textAlign:"left"}}>
          <Card>
          
            <Grid item xs={12} style={{textAlign:"center"}} className = 'titulo-chapas'>

                       <br/>CHAPAS        
                 
            </Grid>
  
          <Box sx={{p: 1, textAlign: 'center' }}></Box>
  
          <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value2}
          onChange={handleChange2}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
          >
                  {chapas}
                 
          </Tabs>

        <Box sx={{p: 5, textAlign: 'center' }}></Box>
        </Card>
       </Grid>

    <div className='conteudo-candidatos'>
      <Grid>
          {candidatos}

        {(statusReuniao !== null &&
        statusReuniao >= 0 &&
        <Button1 className='btn-vt'
        variant="contained"
        onClick={(e) => PropsClick(dadosDoItem[0]['idItem'], e)} 
        component={RouterLink} 
        to={(dadosDoItem[0]['tipoItem'] === "Proposta" && "/voteproposal")||("voteelection")} 
         >
        Votar
        </Button1>
        )}
      </Grid>

      <Grid>
<Grid>

<Button1
className="btn btn-voltar " 
component={RouterLink} 
to="room"
style={{textDecoration:"none",color:"#ffffff"}}
>
<Icon icon={cornerupleft}/>&nbsp;
  Voltar
</Button1>

    </Grid><br />
<div className = 'topotitulocandidatos' >
  <Stack sx={{ mb: 3 }}>
    <Typography variant="h4" gutterBottom>
    {//itemPauta
    }
    </Typography>
  </Stack>
</div>
</Grid>

</div>


      </Grid>
    </CardContent>
  </div>
 </Grid>

</Grid>







{/* Modal dos detalhes do candidato */}      
<div className='titulo-modal'>
<Modal 
      title="Dados do Candidato" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={null}
     
      style={{textAlign:"center" }}
      >

            <CardContent style={{textAlign:"center"}}>
              <Stack direction="row" spacing={2} style={{display: "inline-table"}}>
                <Avatar 
                  alt="Remy Sharp" 
                  //src="/static/illustrations/candidato/default3.png"
                  src={imgModal}
                  sx={{ width: 100, height: 100 }} />
              </Stack>
            </CardContent>

            <Typography variant="body2" >
            <b>Nome: </b>{nomeModal}
             </Typography>
             <Typography variant="body2" >
           <b>Cargo: </b>{(cargoModal !== null && cargoModal) || ("-") }
             </Typography>

            <hr />
            <b>Descrição</b><br /><br /> 

            {dscModal}

            <br />
       
      </Modal>


      </div>


  </>
)

}







// >>>>>>>>>>>>>>>>>>> Lista candidatos individual do Item(eleicao) <<<<<<<<<<<<<<<<<<<<<<<<
const ListaIndividualDetail = (props) => {

  //const IdItem = getItem('IdProps'); 

  const listCandidatos = props.value[0];
  const coutCandidatos = props.value[0].length;
  const dadoItem = props.value[1][0]
  const tipoItem = props.value[1][0]['tipoItem']
  const statusReuniao = dadoItem['statusVotacao']
  //console.log(statusReuniao)

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [nomeModal2, setNomeModal2] = useState("");
  const [idnomeModal2, setIdNomeModal2] = useState("");
  const [imgModal2, setImgModal2] = useState("");
  const [cargoModal2, setCargoModal2] = useState("");
  const [dscModal2, setDscModal2] = useState("");


  const showModal = (nome,id,cargo,dsc) => { 
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    if(id==129){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
    }else if(id==130){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
    }else if(id==131){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
    }else if(id==132){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
    }else if(id==136){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
    }else if(id==137){
      imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
    }   
    else{
      imgAvatar = "/static/illustrations/candidato/default3.png";
    }

    setIsModalVisible(true);
    setNomeModal2(nome);
    setIdNomeModal2(id);
    setImgModal2(imgAvatar);
    setCargoModal2(cargo);
    setDscModal2(dsc);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdPropVote', IdPropsSelect);
    addItem('ThemeVotation', tipoItem);
    addItem('TitleVotation', dadoItem['itemPauta']);
  
  } 


    let listacandidatos = []
    let imgAvatar = "/static/illustrations/candidato/default3.png";
    for(let j = 0; j < coutCandidatos; j++){
      if(listCandidatos[j]['idCandidato']==129){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/carlos_eduardo_de_castro_areal.png"
      }else if(listCandidatos[j]['idCandidato']==130){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/cristiano_pinheiro_campos_matos.png"
      }else if(listCandidatos[j]['idCandidato']==131){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/jeissy_conceicao_andrade_de_paula.png"
      }else if(listCandidatos[j]['idCandidato']==132){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/leonardo_aurelio_oliveira_teixeira.png"
      }else if(listCandidatos[j]['idCandidato']==136){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/marco_aurelio_campos_peixoto.png"
      }else if(listCandidatos[j]['idCandidato']==137){
        imgAvatar = "/static/candidatos/fencom/meetings/31/107/rafael_fuchs_lazarini.png"
      }   
      else{
        imgAvatar = "/static/illustrations/candidato/default3.png";
      }


      listacandidatos.push(
            
        <Grid item xs={3}>
          <Card sx={{ maxWidth: 180 }} 
          onClick={() => showModal(listCandidatos[j]['candidato'],listCandidatos[j]['idCandidato'],listCandidatos[j]['cargo'],listCandidatos[j]['descricaoCanditado'])}
          >
             <CardActionArea style={{textAlign:"center"}}>
                <Stack direction="row" spacing={2}  style={{paddingLeft:"20%",paddingTop:"10%"}}>
                  <Avatar 
                    alt="Remy Sharp" 
                    //src="/static/illustrations/candidato/default3.png"
                    src={imgAvatar}
                    sx={{ width: 100, height: 100 }} />
                </Stack>
               <CardContent style={{textAlign:"center"}}>
                 <Typography gutterBottom variant="h5" component="div" className = 'nome-candidatos'>
                 {listCandidatos[j]['candidato']}
                 </Typography>
                 <Typography variant="body2" color="text.secondary">
               {( listCandidatos[j]['cargo'] !== null && (listCandidatos[j]['cargo'])) || ("-" )}
                 </Typography>
               </CardContent>
             </CardActionArea>
           </Card>
           <br />
         </Grid>
    
          )

  }     



return(
  <>

    {listacandidatos}



<div className='conteudo-candidatos'>
      <Grid>

    {(statusReuniao !== null &&
      statusReuniao >= 0 &&
        <Button1 className='btn-vt'
        variant="contained"
        onClick={(e) => PropsClick(dadoItem['idItem'], e)} 
        component={RouterLink} 
        to={(tipoItem === "Proposta" && "/voteproposal")||("voteelection")} 
         >
        Votar
        </Button1>
        )
        ||
        ""
        }

      </Grid>

      <Grid>
<Grid>

<Button1
className="btn btn-voltar " 
component={RouterLink} 
to="room"
style={{textDecoration:"none",color:"#ffffff"}}
>
<Icon icon={cornerupleft}/>&nbsp;
  Voltar
</Button1>

</Grid>

</Grid>

</div>


{/* Modal dos detalhes do candidato */}      
<Modal 
      title="Dados do Candidato" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={null}
      style={{textAlign:"center" }}
      >

            <CardContent style={{textAlign:"center"}}>
              <Stack direction="row" spacing={2} style={{display: "inline-table"}}>
                <Avatar 
                  alt="Remy Sharp" 
                  //src="/static/illustrations/candidato/default3.png"
                  src={imgModal2}
                  sx={{ width: 100, height: 100 }} />
              </Stack>
            </CardContent>

            <Typography variant="body2" >
            <b>Nome: </b>{nomeModal2}
             </Typography>
             <Typography variant="body2" >
           <b>Cargo: </b>{(cargoModal2 !== null && cargoModal2) || ("-") }
             </Typography>

            <hr />
            <b>Descrição</b><br /><br /> 

            {dscModal2}

            <br />
       
      </Modal>


  </>
)

}



const ListaPropostasDetail = (props) => {

  const propostas = props.value[0];
  const coutPropostas = props.value[0].length;
  const statusReuniao = props.value[2];


  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdPropVote', IdPropsSelect);
    addItem('ThemeVotation', props.value[1]['tipoItem']);
    addItem('TitleVotation', props.value[1]['itemPauta']);
  
  } 


  let listaPropostas = []
  for(let j = 0; j < coutPropostas; j++){

    listaPropostas.push(


<>
<Card>
<CardContent>
<Grid container spacing={2}>

         <Grid item xs={9} >
             <Box sx={{ mb: 0.5 }}>
               <div className='titulo-autor'><b>{" Proposto por: "}</b> {propostas[j]['identificador']}</div>
               </Box>
             <Box sx={{ mb: 0.5 }}>
             <b> {propostas[j]['descricaoProposta']} {""} </b>
             </Box>
         </Grid>

         <Grid item xs={1} style={{fontSize:"25px"}}></Grid>
            
      </Grid>
 </CardContent>   
 </Card>
      <br />

</>

        )

}   




return(
  <>



{listaPropostas}
<div className='conteudo-chapas'>
      <Grid>
      
        {(statusReuniao !== null &&
        statusReuniao >= 0 &&
        <Button1 className='btn-vt'
        variant="contained"
        onClick={(e) => PropsClick(props.value[1]['idItem'], e)} 
        component={RouterLink} 
        to={(props.value[1]['tipoItem'] === "Proposta" && "/voteproposal")||("voteelection")} 
         >
        Votar
        </Button1>
        )}
      </Grid>

      <Grid>
<Grid>

<Button1
className="btn btn-voltar " 
component={RouterLink} 
to="room"
style={{textDecoration:"none",color:"#ffffff"}}
>
<Icon icon={cornerupleft}/>&nbsp;
  Voltar
</Button1>

    </Grid><br />
<div className = 'topotitulocandidatos' >
  <Stack sx={{ mb: 3 }}>
    <Typography variant="h4" gutterBottom>
    {//itemPauta
    }
    </Typography>
  </Stack>
</div>
</Grid>

</div>

  </>
)


}