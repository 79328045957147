import Login from './pages/Login';
import RecuperarSenha from './pages/RecuperarSenha';
import Instituicao from './pages/Instituicao';
import Rooms from './pages/Rooms';
import Room from './pages/Room';
import Candidates from './pages/Candidates';
import VoteProposal from './pages/VoteProposal';
import VoteElection from './pages/VoteElection';
import NotFound from './pages/Page404';
import Meetings from './pages/Meetings';
import User from './pages/User';
import Home from './pages/Home';
import Schedule from './pages/Schedule';
import Proposals from './pages/Proposals';
import Elections from './pages/Elections';
import Votation from './pages/Votation';
import CreateProposta from './pages/Create-Proposta';
import CreateEleicao from './pages/Create-Eleicao';
import UserVote from './pages/UserVote';
import UserVoteEleicao from './pages/UserVoteEleicao';
import Checkin from './pages/Checkin';
import Result_Votation from './pages/Result_Votation';
import Result_Proposal from './pages/Result_Proposal';
//import Result_Votation from './pages/Result_Votation';


import {Route, Switch, Redirect} from 'react-router-dom';

import React, { useState } from "react";
import { hasAuthenticated } from './components/services/AuthApi'
import Auth from "./components/contexts/Auth";
import AuthenticatedRoute from './components/services/AuthenticatedRoute';

// ----------------------------------------------------------------------

export default function Router() {

const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated());

return (

  <Auth.Provider value={{isAuthenticated, setIsAuthenticated}} >
      <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/recuperarSenha" component={RecuperarSenha} />
            <Route exact path="/404" component={NotFound} />
            <AuthenticatedRoute path="/Instituicao" component={Instituicao} />
            <AuthenticatedRoute path="/rooms" component={Rooms} />
            <AuthenticatedRoute path="/room" component={Room} />
            <AuthenticatedRoute path="/candidates" component={Candidates} />
            <AuthenticatedRoute path="/voteProposal" component={VoteProposal} />
            <AuthenticatedRoute path="/voteElection" component={VoteElection} />
            <AuthenticatedRoute path="/meetings" component={Meetings} />
            <AuthenticatedRoute path="/user" component={User} />
            <AuthenticatedRoute path="/home" component={Home} />
            <AuthenticatedRoute path="/schedule" component={Schedule} />
            <AuthenticatedRoute path="/proposals" component={Proposals} />
            <AuthenticatedRoute path="/elections" component={Elections} />
            <AuthenticatedRoute path="/votation" component={Votation} />
            <AuthenticatedRoute path="/result_Votation" component={Result_Votation} />
            <AuthenticatedRoute path="/result_Proposal" component={Result_Proposal} />
            <AuthenticatedRoute path="/CreateProposta" component={CreateProposta} />
            <AuthenticatedRoute path="/CreateEleicao" component={CreateEleicao} />
            <AuthenticatedRoute path="/userVote" component={UserVote} />
            <AuthenticatedRoute path="/userVoteEleicao" component={UserVoteEleicao} />
            <AuthenticatedRoute path="/checkin" component={Checkin} />
            <Redirect to="/404" />
            
      </Switch>
  </Auth.Provider>
  

);


}
