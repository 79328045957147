import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import ptBR from 'date-fns/locale/pt-BR';

import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { Icon } from '@iconify/react';
import clock from '@iconify/icons-eva/clock-outline';


export const TimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  

  return (
    <DatePicker

      {...field}
      {...props}

      customInput={
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{fontSize:"x-large"}}>
            {<Icon icon={clock} />}
            </InputGroupText>
          </InputGroupAddon>
          <Input value={
           ((field.value && field.value) || "")
          } 
            placeholder="HH:mm*" 
           
            />
        </InputGroup>
      }

      selected={null}
      onChange={val => {
        setFieldValue(field.name, moment(val).format('HH:mm'));
      }}
      locale={ptBR}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      dateFormat="HH:mm"

    />
  );
};

export default TimePickerField;
