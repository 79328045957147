import React from 'react';
import { useTimer } from 'react-timer-hook';
//notificacao
//import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';


export const MyTimer = ({ expiryTimestamp }) => {

  const {
    seconds,
    minutes,
    hours,
   
    
  } = useTimer({ expiryTimestamp, onExpire: () =>  console.warn('onExpire called')});


  const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  

  return (

    <div>
   
     <span>{hourTime}</span>:<span>{minuteTime}</span>:<span>{secondTime}</span>
   
   </div>
   
    
  );
};

export default MyTimer;
