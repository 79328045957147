import React, { useState, useEffect,useContext } from 'react';
import { filter } from 'lodash';

import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Button as Button1,
} from '@material-ui/core';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListCkeckin, UserListToolbar } from '../components/_dashboard/user';
import { getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import moment from 'moment-timezone';
import functions from "../constants/serverFunctions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import download from '@iconify/icons-eva/download-fill';
import { Icon } from '@iconify/react';
import Button from 'react-bootstrap/Button';
import './index.css';

import listapresencaPDF from '../relatorios/listapresenca';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'cpfcnpj', label: 'CPF/CNPJ', alignRight: false },
  { id: 'horarioCheckin', label: 'Data e Hora do Registro', alignRight: false },
  { id: '' }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}




export default function ListPresenca() {
 
  const token = getItem('token-assembleia-api');
  const IdReuniao = getItem('IdRoom');
  const institution = getItem('institutionName');
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [ListCheckin, setListCheckin] = useState([]);
  const [DadosReuniao, setDadosReuniao] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);


//Consulta Usuarios por Entidade 
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

   /* if(res.data.result === "Token expirado"){
      logout();
      setIsAuthenticated(false);
      toast.warn('Sessão expirada!');
    }else{*/

    if(res.data.records === 1){

      axios
        .get(functions.presenca.BUSCAR_LISTA_PRESENCA_POR_REUNIAO + "/"+IdReuniao, { headers: {"Authorization" : `${token}`} })
        .then((res) => {
          (res.data.records !== 0 && setListCheckin(res.data.result.recordset))
        })
        .catch((err) => {
        });

    /*}else{
      logout();
      setIsAuthenticated(false);
      toast.error('Token inválido!');
    }  */

}   
    
  }); 

  const interval =  window.setInterval(() => {

    axios
    .get(functions.presenca.BUSCAR_LISTA_PRESENCA_POR_REUNIAO + "/"+IdReuniao, { headers: {"Authorization" : `${token}`} })
    .then((res) => {
          if(res.data.result === "Token expirado"){
            logout();
            setIsAuthenticated(false);
            toast.warn('Sessão expirada!');
          }else{
            (res.data.records !== 0 && setListCheckin(res.data.result.recordset))
          }
    })
    .catch((err) => {
    });
  
  }, 5000);

  return () => clearInterval(interval);

  }, [IdEntidade,IdUser,IdReuniao,setIsAuthenticated,token]);
//





const USERLIST = ListCheckin.map((listCheck, index) => ({

dataAssembleia: listCheck.dataAssembleia,
horarioCheckin: listCheck.dataPresenca,
idAssembleia: listCheck.idAssembleia,
idUsuario: listCheck.IdUsuarioSasc,
name: listCheck.nome,
cpfcnpj: listCheck.cpfcnpj


  
  }));


/*
//Consulta dados da Reuniao
useEffect(() => {
  axios
  .get(functions.assembleia.BUSCAR_LISTA_PROPOSTAS_POR_IDITEM_ADM+"/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      if(res.data.result === "Token expirado"){
        logout();
          setIsAuthenticated(false);
         // toast.warn('Sessão Expirada!');
      }else{
        (res.data.records !== 0 && (setListProps(res.data.result.recordset)))
        setQuantListProps(res.data.records)
      }
  
    })
    .catch((err) => {
    });
}, [IdItem,token]);

*/


//Consulta dados da reuniao
useEffect(() => {
axios
.get(functions.assembleia.BUSCAR_ASSEMBLEIA_POR_ENTIDADE_IDREUNIAO_CHECKIN_ADM+"/"+institution+"/"+IdReuniao, { headers: {"Authorization" : `${token}`} })
.then((res) => {
  (res.data.records !== 0 && setDadosReuniao(res.data.result.recordset))
})
.catch((err) => {
});
}, [institution,IdReuniao,token]);






  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
/*
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;




  function formatCpf(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
    //const cpf = new String(text).replace(badchars, "");
    const cpf = text.replace(badchars, "");
    return cpf.replace(mask, "$1.$2.$3-$4");

  }

  function formatCnpj(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
    //const cnpj = new String(text).replace(badchars, "");
    const cnpj = text.replace(badchars, "");
    return cnpj.replace(mask, "$1.$2.$3/$4-$5");

  }
  
  



  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      
      <MainStyle>


<Page title="Lista de Presença | ASSEMBLEIACOOP">
<Container className='conteudo-geral'>
  {/*<Stack className='titulo-geral' direction="row" alignItems="center" justifyContent="space-between" mb={5}>
    <Typography variant="h4" gutterBottom>
    Lista de Presença
    </Typography>
    

    <Button1 className="btn-success" 
      variant="contained"
      startIcon={<Icon icon={download} />}
      onClick={(e) => listapresencaPDF(ListCheckin,DadosReuniao)}
      >
        Baixar Lista
    </Button1>

  </Stack>*/}

<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{marginBottom: "0px"}}>
        <div className='conteudo-topo-agenda'>
        
        <div className="titulo-resultados">
        <h3> Lista de Presença</h3>
        </div>

        <Button1 className="btn-success btn-linhamento" 
      variant="contained"
      startIcon={<Icon icon={download} />}
      onClick={(e) => listapresencaPDF(ListCheckin,DadosReuniao)}
      >
        Baixar Lista
    </Button1>

          </div>
          </Stack>
<br/>



  <Card>
    <UserListToolbar
      numSelected={selected.length}
      filterName={filterName}
      onFilterName={handleFilterByName}
    />

    <Scrollbar className='tabela-vt'>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListCkeckin
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={USERLIST.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { id, name, horarioCheckin, cpfcnpj, avatarUrl } = row;
                //const isItemSelected = selected.indexOf(name) !== -1;
                const dataCheckin =  moment(horarioCheckin).format('DD/MM/YYYY');
                const horaCheckin =  moment.utc(horarioCheckin).format('HH:mm:ss');

    

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                  >
                    <TableCell >
                     
                    </TableCell>
                    {/*imagem dos avatares */}
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={avatarUrl} />
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    
                    <TableCell align="left">{(cpfcnpj.length === 11 && formatCpf(cpfcnpj)) || (cpfcnpj.length === 10 && formatCpf("0"+cpfcnpj)) || (formatCnpj(cpfcnpj))}</TableCell>
                    <TableCell align="left">{dataCheckin + ' às ' + horaCheckin}</TableCell>
                    <TableCell align="right"></TableCell>

                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>


          {isUserNotFound && (
            
           
            <TableBody> {/* mensagem Not found */}
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}

        </Table>
      </TableContainer>
    </Scrollbar>



{/*Mostrar quantidade por tela e navegar entre as quantidades*/}
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={USERLIST.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />



  </Card>
</Container>
</Page>
 
</MainStyle>
    </RootStyle>


  );


}

