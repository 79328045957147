import { Icon } from '@iconify/react';
import { useRef, useState, useContext } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import unlock from '@iconify/icons-eva/unlock-fill';
//import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import arrowiosdownward from '@iconify/icons-eva/arrow-ios-downward-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton, Stack, TextField, InputAdornment } from '@material-ui/core';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';

import { getItem } from '../../components/services/LocaleStorage';
import Auth from "../../components/contexts/Auth";
import { logout } from "../../components/authentication/login/LoginAuth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import {Button as Button2} from 'reactstrap';
import functions from "../../constants/serverFunctions";
import {useHistory} from 'react-router-dom';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Trocar Entidade',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Alterar Senha',
    icon: unlock,
    //linkTo: '/'
  },
 // {
 //   label: 'Conta',
 //   icon: personFill,
 //   linkTo: '#'
 // },
  /*{
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }*/
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { setIsAuthenticated } = useContext(Auth);
  const userName = getItem('userName');
  const institutionName = getItem('institutionName');
  const token = getItem('token-assembleia-api');
  const IdUser = getItem('userId'); 
  const history = useHistory();



  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleLogout = () => {
    logout();
    setIsAuthenticated(false);
    toast.info('Acesso encerrado!');
    
  }




  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
 // const handleShow2 = () => setShow2(true);

 const handleShow2 = () => {
  setOpen(false);
  setShow2(true);
};


  

//validar campos
  const LoginSchema = Yup.object().shape({
    senha: Yup.string().required('Favor informar nova senha'),

    confsenha: Yup
    .string()
    .required("Favor confirmar nova senha")
    .test("e igual", "confirmação de senha diferente", function(value) {
      const { senha } = this.parent;
     return value === senha;
    }),




    
   });

  const formik = useFormik({
    initialValues: {
      senha: '',
      id: IdUser
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

//      console.log(values)
     

   axios.post(functions.usuario.ALTERAR_SENHA_USUARIO, values,{ headers: {"Authorization" : `${token}`}})
      .then((response) => {
          toast.success('Senha alterada com sucesso!');
         // setShow2(false);
         history.go(0);
      });
 
              

    }
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleShowPassword1 = () => {
    setShowPassword1((show) => !show);
  };
  const handleShowPassword2 = () => {
    setShowPassword2((show) => !show);
  };


  const { 
    errors, 
    touched, 
    values, 
    isSubmitting, 
    handleSubmit, 
    getFieldProps 
  } = formik;

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 70,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >


            <Box
              component={Icon}
              icon={arrowiosdownward}
              sx={{
                mr: 1,
                width: 24,
                height: 24
              }}
            />



        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {/*{account.displayName}*/}
           {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {institutionName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          (option.label !== "Alterar Senha" &&
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
          ||
          <MenuItem
          key={option.label}
          to={option.linkTo}
          component={RouterLink}
          //onClick={handleClose}
          onClick={handleShow2}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={option.icon}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />

          {option.label}
        </MenuItem>
        )
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout} style={{color:"red"}}>
          SAIR
          </Button>
        </Box>
      </MenuPopover>
      <ToastContainer />


{/*Modal alterar senha */}




<Modal visible style={{ zIndex: 1200}}
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Alterar senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>


      <Stack spacing={3}>

{/*
        <TextField 
        id="senha"
        variant="standard"
        label="Nova senha*"
        {...getFieldProps('senha')}
        error={Boolean(touched.senha && errors.senha)}
        helperText={touched.senha && errors.senha}
        />

        <TextField 
        id="confsenha"
        variant="standard"
        label="Confirmar nova senha*"
        {...getFieldProps('confsenha')}
        error={Boolean(touched.confsenha && errors.confsenha)}
        helperText={touched.confsenha && errors.confsenha}
        />
*/}

<TextField
    fullWidth
    autoComplete="current-senha"
    type={showPassword1 ? 'text' : 'password'}
    label="Nova Senha"
    variant="standard"
    //helperText="Favor informar nova senha"
    {...getFieldProps('senha')}
    error={Boolean(touched.senha && errors.senha)}
    helperText={touched.senha && errors.senha}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleShowPassword1} edge="end">
            <Icon icon={showPassword1 ? eyeFill : eyeOffFill} />
          </IconButton>
        </InputAdornment>
      )
    }}
  /> 



<TextField
    fullWidth
    autoComplete="current-senha"
    type={showPassword2 ? 'text' : 'password'}
    label="Confirmar nova Senha"
    variant="standard"
    //helperText="Favor confirmar nova senha"
    {...getFieldProps('confsenha')}
    error={Boolean(touched.confsenha && errors.confsenha)}
    helperText={touched.confsenha && errors.confsenha}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleShowPassword2} edge="end">
            <Icon icon={showPassword2 ? eyeFill : eyeOffFill} />
          </IconButton>
        </InputAdornment>
      )
    }}
  /> 

<br/>
 
    </Stack>


    </Modal.Body>


        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose2}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
       
</Modal>



    </>
  );
}
