import React, { useState, useEffect, useContext } from 'react';
import { styled} from '@material-ui/core/styles';
import {
  Stack,
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardHeader,
  Divider
} from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import Scrollbar from '../components/Scrollbar';
import { Icon } from '@iconify/react';
import { Link as RouterLink} from 'react-router-dom';
//Dashboard
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import Page from '../components/Page';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import checkmarkcircle2 from '@iconify/icons-eva/checkmark-circle-fill';
import clock from '@iconify/icons-eva/clock-fill';
import closecircle from '@iconify/icons-eva/close-circle-fill';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import functions from "../constants/serverFunctions";
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


export default function PAGEROOMS( props) {

  const token = getItem('token-assembleia-api');
  const entidade = getItem('institutionName');
  const [Meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated } = useContext(Auth);




//Consulta Reunioes 
useEffect(() => {

  axios
  .get(functions.assembleia.BUSCAR_ASSEMBLEIA_ENTIDADE_COOP+"/"+entidade, { headers: {"Authorization" : `${token}`} })
    .then((res) => {

        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
         // toast.warn('Sessão expirada!');
        }else{
          (res.data.records !== 0 && setMeetings(res.data.result.recordset))
          setLoading(false);
        }

    })
    .catch((err) => {
    });

 

const interval = window.setInterval(() => {

  axios
  .get(functions.assembleia.BUSCAR_ASSEMBLEIA_ENTIDADE_COOP+"/"+entidade, { headers: {"Authorization" : `${token}`} })
    .then((res) => {

          if(res.data.result === "Token expirado"){
            logout();
            setIsAuthenticated(false);
            toast.warn('Sessão expirada!');
          }else{
            (res.data.records !== 0 && setMeetings(res.data.result.recordset))
            setLoading(false);
          }

    })
    .catch((err) => {
    });
    
  }, 5000);

return () => clearInterval(interval);
  

}, [token,entidade]);




//mapeia a consulta 
const MEETINGLIST = Meetings.map((Meeting, index) => ({

  id: Meeting.ID_Assembleia,
  titulo: Meeting.Titulo_Assembleia,
  DataReuniao: Meeting.Data_Assembleia,
  //Link_Assembleia: Meeting.Link_Assembleia,
  Hora1: Meeting.Horario_PrimeiraChamada,
  //Hora2: Meeting.Horario_SegundaChamada,
  //Hora3: Meeting.Horario_TerceiraChamada,
  //Descricao: Meeting.Descricao_Assembleia,
  //DataHoraInicio: Meeting.DataInicio_Assembleia,
  //DataHoraFim: Meeting.DataFim_Assembleia,
  //ID_UsuarioIniciou: Meeting.ID_UsuarioIniciou_Assembleia,
  //ID_UsuarioEncerrou: Meeting.ID_UsuarioEncerrou_Assembleia,
  ID_Entidade: Meeting.ID_Entidade_Assembleia,
  Entidade: Meeting.Entidade_Assembleia,
  //Arquivada:Meeting.Assembleia_Arquivada,
  TimerInicio: Meeting.TimerInicio,
  TimerFim: Meeting.TimerFim

  }));

    

  const [open, setOpen] = useState(false);


  if (loading) {
    return <p>carregando...</p>;
  }



  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      
      <MainStyle>

      <Page title="Reuniões | ASSEMBLEIACOOP">
      <Container>
      <div className = 'topotituloreunioes' >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom >
            Minhas Reuniões
          </Typography>
        </Stack>

       
        {MEETINGLIST.map((Meeting, id) => (
              <Reunioesdisp key={id} {...Meeting} />  
              ))}

        </div>
        </Container>
        </Page>
        <ToastContainer />
      </MainStyle>
    </RootStyle>
    

  );

}



const Reunioesdisp = (props) => {

  const { 
    id, 
    titulo, 
    DataReuniao, 
    Hora1,  
    //DataHoraInicio, 
    //DataHoraFim,
    TimerInicio,
    TimerFim
   } = props;

const idUser = getItem('userId'); 
const NomeUser = getItem('userName'); 
const TagId = getItem('tagId');
const token = getItem('token-assembleia-api');


    const date = DataReuniao;
    const format = "YYYY-MM-DD";
    const timezone = "America/Sao_Paulo";
    const dateMoment = moment.tz(date, format, timezone);


/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':00';
  const time2 = moment.utc(DataHoraFim).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));
*/

  const RoomClick = (IdRoomSelect) =>{
     
    addItem('IdRoom', IdRoomSelect);
    addItem('DscRoom', titulo);


  axios.post(functions.presenca.INSERIR_PRESENCA_ASSEMBLEIA, {
      idAssembleia:IdRoomSelect,
      idUsuario:idUser,
      cpfcnpj:TagId,
      nome:NomeUser
      },{ headers: {"Authorization" : `${token}`}})
  .then((response) => {
   });
   

}  


  return(
    <>

<Card>

<CardHeader title={titulo} className = 'titulo-reunioes' />

<Scrollbar>
  <Stack spacing={3} className = 'texto-reunioes'>
  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
      <Moment format="DD/MM/YYYY">
                {dateMoment}
      </Moment>
                {' às '} 
                  {moment.utc(Hora1).format('HH:mm')}
 </Typography>
  </Stack>
</Scrollbar>

<Divider />


<Grid container spacing={1} style={{marginTop:"10px"}} >
<Grid item xs={12} sm={2} style={{paddingTop: "0px", marginLeft: '15px'}}>
<div>
<Icon icon={(TimerFim <= 0 && closecircle) || (TimerInicio > 0 && clock) || checkmarkcircle2} 
style={{textDecoration:"none",color:(TimerFim <= 0 && 'grey') || (TimerInicio > 0 && '#0d6efd') || ('green')}}
/>
<label style={{fontSize: "14px",color:(TimerFim <= 0 && 'grey') || (TimerInicio > 0 && '#0d6efd') || ('green')}}>  
{(TimerFim <= 0 && " Finalizado") || (TimerInicio > 0 && " Em Breve") || (" Liberada")}
</label>
</div>
</Grid>

<Grid item xs={12} sm={6} style={{paddingTop: "0px"}}>
<div>
<label style={{fontSize: "14px"}}>  
</label>
</div>
</Grid>


<Grid item xs={12} sm={3.5} style={{paddingTop: "0px", textAlign: "right"}}>
<div>
{
(TimerFim < 0 && 
  <Button variant="outline-secondary" disabled={true}>Finalizado</Button>
)
||
((TimerInicio > 0 &&   
  <Button variant="outline-secondary" disabled={true}>Entrar</Button>)
  || 
  (<RouterLink to="/room" style={{textDecoration:"none",color:"#000000"}}>  
  <Button variant="outline-success" onClick={(e) => RoomClick(id, e)} disabled={false}>Entrar</Button> 
  </RouterLink>)
) 
}

</div>
</Grid>

</Grid>

<Box sx={{p: 1, textAlign: 'right' }}></Box>


</Card>   
<br />



    </>
  )

}