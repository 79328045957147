import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Reuniões',
    path: '/rooms',
    icon: getIcon(calendarFill)
  },

  
];

export default sidebarConfig;
