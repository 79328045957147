import { Icon } from '@iconify/react';
import filetext from '@iconify/icons-eva/file-text-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'ITENS DA PAUTA',
    path: '/room',
    icon: getIcon(filetext)
  }
  
];

export default sidebarConfig;
