import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { getItem, addItem, removeItem } from '../../services/LocaleStorage';
import functions from "../../../constants/serverFunctions";



import { toast } from 'react-toastify';

export function hasAuthenticated() {
    const token = getItem('token-assembleia-api');
    const result = token ? tokenIsValid(token) : false;

    if (false === result) {
        removeItem('token-assembleia-api');
    }

    return result;
}

export function recuperarSenha(credentials) {

  
console.log("teste-->Passou aqui")

return axios
.post(functions.usuario.LOGIN, credentials)
 .then(response => response.data)
 .then(token => {
       
    
    /*addItem('token-assembleia-api', token.token);

            addItem('userName', token.userId.map((id) => id.nome));
            addItem('userId', token.userId.map((id) => id.id));
            addItem('tagId', token.userId.map((id) => id.cpf_cnpj));
            addItem('userNivel', token.userPerfil);
           // addItem('userEntidades', token.userEntidades);
           addItem('userEntidades', JSON.stringify(token.userEntidades))

          
            axios.post(functions.presenca.INSERIR_ACESSO, {
                cpfcnpjemail:token.userId.map((id) => id.email),
                idUsuario:token.userId.map((id) => id.id)
                },{ headers: {"Authorization" : `${token.token}`}})
                .then((response) => {
                });

            return [token.userEntidades,token.userPerfil];

        
    // return true;

    */

 })
     
.catch((err) => {
    toast.error(err.response.data.result,{autoClose:3000});
    this.response.data({ errorMessage: err.result });
});


        
        
}






export function logout() {
   localStorage.clear();
}

function tokenIsValid(token) {
    const { exp: expiration } = jwtDecode(token);

    if (expiration * 1000 > new Date().getTime()) {
        return true;
    }

    return false;
}