import React, { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { Link as RouterLink} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import { styled} from '@material-ui/core/styles';
//Dashboard
//import DashboardNavbar from '../layouts/dashboardHome/DashboardNavbar';
//import DashboardSidebar from '../layouts/dashboardHome/DashboardSidebar';
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListCkeckin, UserListToolbar} from '../components/_dashboard/user';
import { getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import { Icon } from '@iconify/react';
import cornerupleft from '@iconify/icons-eva/corner-up-left-fill';
import functions from "../constants/serverFunctions";
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'cpfcnpj', label: 'CPF/CNPJ', alignRight: false },
  { id: '' }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}




export default function ListPresenca() {
 
  const token = getItem('token-assembleia-api');
  const IdItem = getItem('IdProps');
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [ListCheckin, setListCheckin] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);



  function formatCpf(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
   // const cpf = new String(text).replace(badchars, "");
    const cpf = text.replace(badchars, "");
    return cpf.replace(mask, "$1.$2.$3-$4");

  }

  function formatCnpj(text) {
    const badchars = /[^\d]/g
    const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
    //const cnpj = new String(text).replace(badchars, "");
    const cnpj = text.replace(badchars, "");
    return cnpj.replace(mask, "$1.$2.$3/$4-$5");

  }


//Consulta Usuarios votaram no item 
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.records === 1){

  axios
    .get(functions.voto.BUSCAR_LISTA_VOTOS_POR_ITEM + "/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
    .then((res) => {
      (res.data.records !== 0 && setListCheckin(res.data.result.recordset))
    })
    .catch((err) => {
    });


    }/*else{
    logout();
    setIsAuthenticated(false);
    toast.error('Token inválido!');
  }*/

  }); 



  const interval =  window.setInterval(() => {

    axios
  .get(functions.voto.BUSCAR_LISTA_VOTOS_POR_ITEM + "/"+IdItem, { headers: {"Authorization" : ` ${token}`} })
  .then((res) => {
    (res.data.records !== 0 && setListCheckin(res.data.result.recordset))
  })
  .catch((err) => {
  });
      
  }, 5000);

  return () => clearInterval(interval);
  
  
  }, [IdEntidade,IdUser,IdItem,token,setIsAuthenticated]);
//





const USERLIST = ListCheckin.map((listCheck, index) => ({

idVoto: listCheck.idVoto, 
idAssembleia: listCheck.idAssembleia, 
dataAssembleia: listCheck.dataAssembleia,
ItemPauta: listCheck.ItemPauta, 
tipo: listCheck.tipo, 
idUsuario: listCheck.idUsuario, 
name: listCheck.nome, 
cpfcnpj: listCheck.cpfcnpj, 
dataVoto: listCheck.dataVoto


  }));







  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
/*
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;




  


  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      
      <MainStyle>


<Page title="Lista de Presença | ASSEMBLEIACOOP">
<Container className='conteudo-geral'>
  <Stack className='titulo-geral' direction="row" alignItems="center" justifyContent="space-between" mb={5}>
    <Typography variant="h4" gutterBottom>
    Usuários que votaram no item de pauta  
    </Typography>



<Button
className="btn btn-secondary" 
component={RouterLink} 
to="proposals"
style={{textDecoration:"none",color:"#ffffff"}}
>
<Icon icon={cornerupleft}/>&nbsp;
                Voltar
            </Button>

          

  </Stack>


  

  <Card>
    <UserListToolbar
      numSelected={selected.length}
      filterName={filterName}
      onFilterName={handleFilterByName}
    />

    <Scrollbar className='tabela-vt'>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListCkeckin
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={USERLIST.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { id, name, cpfcnpj, avatarUrl } = row;
                //const isItemSelected = selected.indexOf(name) !== -1;
               

    

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                  >
                    <TableCell >
                     
                    </TableCell>
                    {/*imagem dos avatares */}
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={avatarUrl} />
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    
                    <TableCell align="left">{(cpfcnpj.length === 11 && formatCpf(cpfcnpj)) || (cpfcnpj.length === 10 && formatCpf("0"+cpfcnpj)) || (formatCnpj(cpfcnpj))}</TableCell>
                    <TableCell align="right"></TableCell>

                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>


          {isUserNotFound && (
            
           
            <TableBody> {/* mensagem Not found */}
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}

        </Table>
      </TableContainer>
    </Scrollbar>



{/*Mostrar quantidade por tela e navegar entre as quantidades*/}
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={USERLIST.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />



  </Card>
</Container>
</Page>
 
</MainStyle>
    </RootStyle>


  );


}