import React, { useState, useEffect, useContext, useRef } from 'react';
import { styled} from '@material-ui/core/styles';
//Dashboard
import DashboardNavbar from '../layouts/dashboardHomeAdm/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboardHomeAdm/DashboardSidebar';
import Page from '../components/Page';
import { 
  Grid, 
  Stack,
  Button as Button1,
  Container,
  Typography, 
  Box,
  Card,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Link as RouterLink} from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import checkmarkcircle2 from '@iconify/icons-eva/checkmark-circle-fill';
import clock from '@iconify/icons-eva/clock-fill';
import closecircle from '@iconify/icons-eva/close-circle-fill';
//notificacao
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik, Form, FormikProvider} from 'formik';
import * as Yup from 'yup';
import {Button as Button2, Row, Col} from 'reactstrap';
import { addItem, getItem } from '../components/services/LocaleStorage';
import axios from "axios";
import functions from "../constants/serverFunctions";
import Scrollbar from '../components/Scrollbar';
import Label from '../layouts/css/Label';
import Button from 'react-bootstrap/Button';
import { ItemMoreMenu } from '../components/_dashboard/ItensPauta';
import "react-widgets/styles.css";
import TimeField from 'react-simple-timefield';
import moment from 'moment-timezone';
//logout
import Auth from "../components/contexts/Auth";
import { logout } from "../components/authentication/login/LoginAuth";

// ----------------------------------------------------------------------


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


// ----------------------------------------------------------------------




export default function ListaPautaAssembleia() {

  const token = getItem('token-assembleia-api');
  const IdRoom = getItem('IdRoom'); 
  const IdEntidade = getItem('institutionId');
  const IdUser = getItem('userId');
  const [ListPauta, setListPauta] = useState([]);
  const [StatusReuniao, setStatusReuniao] = useState([]);
  const { setIsAuthenticated } = useContext(Auth);

    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



//Consulta Intens da Pauta da Reuniao.
useEffect(() => {

  axios.get(functions.usuario.VERIFICAR_PERFIL+"/"+IdEntidade+"/"+IdUser, { headers: {"Authorization" : `${token}`} })
  .then((res) => {

    if(res.data.records === 1){

      axios
      .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_IDREUNIAO_ADM+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
        .then((res) => {
      (res.data.records !== 0 && setListPauta(res.data.result.recordset))
      setStatusReuniao(res.data.result.recordset[0]['TimerFim']);
        })
        .catch((err) => {
        });

    }/*else{
      logout();
      setIsAuthenticated(false);
      toast.error('Token inválido!');
    }*/
    
  }); 


  const interval =  window.setInterval(() => {

    axios
    .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_POR_IDREUNIAO_ADM+"/"+IdRoom, { headers: {"Authorization" : `${token}`} })
      .then((res) => {
        if(res.data.result === "Token expirado"){
          logout();
          setIsAuthenticated(false);
          //toast.warn('Sessão expirada!');
        }else{
          (res.data.records !== 0 && setListPauta(res.data.result.recordset))
          setStatusReuniao(res.data.result.recordset[0]['TimerFim']);
        }
   
      })
      .catch((err) => {
      });
  
  }, 5000);

  return () => clearInterval(interval);


}, [IdEntidade,IdUser,IdRoom,token,setIsAuthenticated]);





//mapeia a consulta
const LISTAPAUTA = ListPauta.map((Lista, index) => ({

  idItem: Lista.idItem,
  itemPauta: Lista.itemPauta,
  assembleia: Lista.assembleia,
  idAssembleia: Lista.idAssembleia,
  entidade: Lista.entidade,
  idEntidade: Lista.idEntidade,
  tipoItem: Lista.tipoItem,
  configuracao: Lista.configuracao,
  idConfiguracao: Lista.idConfiguracao,
  tipoEleicao: Lista.tipoEleicao,
  quantidade_candidatos:Lista.quantidade_candidatos,
  quantidadeExataCandidatos:Lista.quantidadeExataCandidatos,
  Quant_proposta:Lista.Quant_proposta,
  voto_branco:Lista.voto_branco,
  codigoItem: Lista.codigo,
  horarioInicial: Lista.horarioInicial,
  horarioFinal: Lista.horarioFinal,
  quantidade_MinCandidatos: Lista.countVotosMin,
  QuantCandItem: Lista.QuantCandItem,
  timerS: Lista.timerS,
  TimerInicio: Lista.TimerInicio,
  TimerFim: Lista.TimerFim,
  TimerVotacao: Lista.TimerVotacao

  }));





/*
function Counter() {
  const [count, setCount] = useState(0);

  const token = getItem('token-assembleia-api');
  const IdRoom = getItem('IdRoom');
  const IdUser = getItem('userId');

  useInterval(() => {
    // Your custom logic here
    setCount(count + 1);




      axios
      .get(functions.itemPauta.BUSCAR_LISTA_ITENS_PAUTA_USER_POR_REUNIAO+"/"+IdRoom+"/"+IdUser, { headers: {"Authorization" : ` ${token}`} })
      .then((res) => {
       //(res.data.records !== 0 && setListPauta(res.data.result.recordset))
        })
        .catch((err) => {
        });
    



    (count === 20 && console.log("deu Certo"))
  }, 5000);

  return <h1>{count}</h1>;
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
*/



 

    const LoginSchema = Yup.object().shape({
      nome: Yup.string().required('campo obrigatória'),
      email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
      cpf: Yup.string().required('campo obrigatória'),
      senha: Yup.string().required('Senha obrigatória')
     });
  
    const formik = useFormik({
      initialValues: {
        nome: '',
        email: '',
        crm: '',
        cpf: '',
        senha: '',
        
      },
     
      validationSchema: LoginSchema,
      onSubmit: async () => {
  
             
  
      }
    });
  
  
  
    const { 
    
      handleSubmit, 
     
    } = formik;
  
  




  return (

    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      

      
        <MainStyle>

        <Page title="Reuniões | ASSEMBLEIACOOP">
      <Container>
      <div className = 'topotituloreunioes' >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Itens de Pauta
          </Typography>


          {/*<Counter />*/}
         

          {(StatusReuniao > 0 &&
          <Button1 className="btn-success" 
           variant="contained"
           component={RouterLink}
           to="#"
           startIcon={<Icon icon={plusFill} />}
           onClick={handleShow}
           >
             Novo Item
          </Button1>
          )}


          <Modal visible style={{ zIndex: 1200}}
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Qual novo item de pauta deseja criar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <Container>
          <Row>
            <Col xs={12} md={6} >


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/proposta-de-negocio.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box className='titulo-votacao'>
            <Typography gutterBottom variant="h6">
              Proposta
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Votação de uma ou mais propostas, estilo aclamação.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateProposta"
            variant="contained"
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>
            </Col>


            <Col xs={6} md={6}>


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/votacao-eletronica.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box className='titulo-votacao'>
            <Typography gutterBottom variant="h6">
             Eleição
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Eleição de candidatura individual ou por chapa eleitoral.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateEleicao"
            variant="contained"
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>

            </Col>
          </Row>

          
        </Container>



        </Modal.Body>


        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
         
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
       
</Modal>


{
/*

        <Modal visible style={{ zIndex: 1200}}
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Qual novo item de pauta deseja criar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <Container>
          <Row>
            <Col xs={12} md={6} >


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/proposta1.png"
            sx={{ width: 130, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Proposta
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Votação de uma ou mais propostas, estilo aclamação.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateProposta"
            variant="contained"
            //onClick={handleShow}
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>
            </Col>


            <Col xs={6} md={6}>


<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/itempauta/eleicao6.png"
            sx={{ width: 130, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
             Eleição
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Eleição de candidatura individual ou por chapa eleitoral.
            </Typography>
          </Box>

          <Button1
            fullWidth
            component={RouterLink} 
            to="CreateEleicao"
            variant="contained"
            style={{textDecoration:"none",color:"#ffffff"}}
          >
            Selecionar
          </Button1>
        </Stack>
      </Box>

            </Col>
          </Row>

          
        </Container>



        </Modal.Body>


        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
         
        </Modal.Footer>

    </Form>
  </FormikProvider>
  
       
</Modal>
*/}

       
          </Stack>


          <Grid item xs={12} md={12} lg={12}>



<div >


{LISTAPAUTA.map((lista, id) => (

<PautaDetail key={id} {...lista} />  

))}

</div>


          </Grid>

          </div>

          </Container>
          </Page>

      
        </MainStyle>
    </RootStyle>


  );


}







const PautaDetail = (props) => {
  const { 
    idItem, 
    itemPauta, 
    tipoItem, 
    tipoEleicao, 
    voto_branco, 
    Quant_proposta, 
    codigoItem, 
    horarioInicial, 
    horarioFinal,
    quantidade_MinCandidatos,
    quantidade_candidatos,
    quantidadeExataCandidatos,
    TimerInicio,
    TimerFim,
    TimerVotacao,
    QuantCandItem
  } = props;

  const token = getItem('token-assembleia-api');


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);





  const EncerrarVotacao = (IdItemPauta) => {

    const token = getItem('token-assembleia-api');

    addItem('IdVotationResult', IdItemPauta);
    addItem('ThemeVoteResult', tipoItem);

        axios.post(functions.votacao.ENCERRAR_VOTACAO, 
          {IdItemPauta: IdItemPauta},
          {headers: {"Authorization" : `${token}`} }
        )
      .then((response) => {
            toast.success('votação encerrada com sucesso!');
            window.open('https://assembleiacoop.coop.br/result_votation', '_blank');
            window.location.reload(false);
      });
     
  
  } 




   const LoginSchema = Yup.object({

    tempoDuracao: Yup
      .string()
      .required("Campo Obrigatório")
      .test("é maior", "Favor informar tempo", function(value) {
      return value !== '00:00';
      })


});



  const formik = useFormik({
    initialValues: {
      tempoDuracao: ''
      
    },
   
    validationSchema: LoginSchema,
    onSubmit: async () => {

      
      var min = 10000;
      var max = 99999;
      var Coditem = Math.floor(Math.random() * (max - min + 1)) + min;
    
      
      var timeBase = new Date("2014-03-14T" + values.tempoDuracao + ":00");
      var novahora = timeBase.getHours();
      var novominitu = timeBase.getMinutes();
      var time2 = new Date();
      var outraData = new Date();
      outraData.setHours(time2.getHours() + novahora);
      outraData.setMinutes(time2.getMinutes() + novominitu);
    
      const horaAtual =  moment(time2).format('YYYY-MM-DD HH:mm:ss');
      const horaFim =  moment(outraData).format('YYYY-MM-DD HH:mm:ss');
     


      addItem('IdVotationStart', idItem);
      addItem('ThemeVoteStart', tipoItem);

      


    


   axios.post(functions.votacao.INICIAR_VOTACAO, {
                  idItem: idItem,
                  codigo: Coditem,
                  //horarioInicial: horaAtual,
                  horarioFinal: horaFim
                },
                { headers: {"Authorization" : `${token}`} }
                )
          .then((response) => {
          toast.success('Reunião iniciado com sucesso!');
          window.open('https://assembleiacoop.coop.br/votation', '_blank');
          window.location.reload(false);
          })
          .catch((err) => {
          });
 
          

    }
  });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



/*
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = (dataAtual.getMonth() + 1);
  const ano = dataAtual.getFullYear();
  const horas = dataAtual.getHours();
  const minutos = dataAtual.getMinutes();
  const segundos = dataAtual.getSeconds();
  const dataAtualFormat = dia + '/' + mes + '/' + ano + " "+horas + ':' + minutos + ':' + segundos;
  const time2 = moment.utc(horarioFinal).format('DD/MM/YYYY HH:mm:ss');
  const ms = moment(time2,"DD/MM/YYYY HH:mm:ss").diff(moment(dataAtualFormat,"DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  const fim = (Math.floor(d.asHours()*60));
*/

  const PropsClick = (IdPropsSelect) =>{
     
    addItem('IdProps', IdPropsSelect);
  
  }  

return(

<>

<Card>
  <Box sx={{ position: 'relative' }} >
  <RouterLink onClick={(e) => PropsClick(idItem, e)} to={(tipoItem === "Proposta" && "/proposals")||("elections")} style={{textDecoration:"none",color:"#000000"}}>
  <Label
  variant="filled"
  color={(tipoItem === 'Proposta' && 'info') || ('warning')}
  sx={{
    zIndex: 9,
    top: 10,
    left: 16,
    position: 'relative',
  }}
  >
  {tipoItem}
  </Label>

 </RouterLink>
  

  <Box
    sx={{
    top: 10,
    right: 16,
    position: 'absolute',
    }}
  >
     {(TimerFim > 0 &&
  <ItemMoreMenu value={props}/>
     )}
  </Box>

 

    </Box>


<RouterLink onClick={(e) => PropsClick(idItem, e)} to={(tipoItem === "Proposta" && "/proposals")||"elections"} style={{textDecoration:"none",color:"#000000"}}>
    <CardHeader title={itemPauta} className = 'titulos-votacao'/>

    <Scrollbar>
      <Stack spacing={3} sx={{ p: 2, pr: 0 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
       {
      (
        tipoItem === "Proposta" && 

        (Quant_proposta + ((Quant_proposta > 1 && ' Propostas cadastradas') || (' Proposta cadastrada')))
       
      
      
       )
       
       || 
        (
          (tipoEleicao === "Chapa Eleitoral" &&
            (tipoEleicao +', '+ ( (voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')))
          )

          ||

          (tipoEleicao === "Ambos" &&
            ((quantidadeExataCandidatos === 'SIM' && 
              ('Chapa e Candidato Individual, Exatamente ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
            )
            ||
            (quantidade_MinCandidatos === null && 
              ('Chapa e Candidato Individual, Até ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
            )
            ||
            ('Chapa e Candidato Individual, Entre ' + quantidade_MinCandidatos + ' e ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
           )
          ) 

          ||
          
          (tipoEleicao === "Candidato Individual" &&
              ((quantidadeExataCandidatos === 'SIM' && 
                (tipoEleicao + ', Exatamente ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
              )
              ||
              (quantidade_MinCandidatos === null && 
                (tipoEleicao + ', Até ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
              )
              ||
              (tipoEleicao + ', Entre ' + quantidade_MinCandidatos + ' e ' + quantidade_candidatos + ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ((voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')) )
             ) 
          ) 
          
          

       )}
      
      {/*
          (tipoEleicao === "Ambos" && 
            'Chapa e Candidato Individual, '+ (quantidadeExataCandidatos === 'SIM' && 'exatamente ' || quantidade_MinCandidatos === null && 'Até ' || 'Entre ')+quantidade_candidatos+ ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ( (voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco'))
          )
      quantidade_MinCandidatos + " e " +
      tipoEleicao +', '+ ((quantidadeExataCandidatos === 'SIM' && 'exatamente ') || quantidade_MinCandidatos === null && 'Até ' || 'Entre ')+quantidade_candidatos+ ((quantidade_candidatos > 1 && ' Individuais') || (' Individual')) +', ' + ( (voto_branco === 'NÃO' && 'Sem voto em branco') || ('Com voto em branco')))
      */}

     </Typography>
      </Stack>
    </Scrollbar>

    <Divider />

</RouterLink>

<Grid container spacing={1} style={{marginTop:"10px"}} >

<Grid item xs={12} sm={2} style={{paddingTop: "0px", marginLeft: '15px'}}>
<div>
<Icon icon={(horarioInicial === null && clock) || (TimerVotacao <= 0 && closecircle) || checkmarkcircle2} 
style={{textDecoration:"none",color:(horarioInicial === null && '#0d6efd') || (TimerVotacao <= 0 && 'grey') || 'green'}}
/>
<label style={{fontSize: "14px",color:(horarioInicial === null && '#0d6efd') || (TimerVotacao <= 0 && 'grey') || 'green'}}>  
{(horarioInicial === null && " Em Breve") || (TimerVotacao <= 0 && " Finalizado") || " Liberada"}
</label>
</div>
</Grid>

<Grid item xs={12} sm={6} style={{paddingTop: "0px"}}>
<div>
<label style={{fontSize: "14px"}}>  
<b>Código: {((codigoItem === null && 'Não disponível') || codigoItem)} </b>
</label>

</div>
</Grid>


<Grid item xs={12} sm={3.5} style={{paddingTop: "0px", textAlign: "right"}}>
<div>
{
  (horarioInicial === null && 
    ((TimerInicio >= 0 &&
      <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
     )||
    (Quant_proposta > 0 &&
         <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>)
     ||
     (QuantCandItem > 0 &&
      <Button variant="outline-success" onClick={handleShow} disabled={false}>Iniciar</Button>)
  ||
     (
         <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Iniciar</Button>
     )

     )
  
  ) 
|| 
(TimerVotacao <= 0 && 
  <Button variant="outline-secondary" onClick={handleShow} disabled={true}>Finalizado</Button>
  )
   ||
    
    <Button variant="outline-secondary" onClick={() => EncerrarVotacao(idItem)} disabled={false}>Encerrar</Button>
    }

</div>
</Grid>

</Grid>

<RouterLink onClick={(e) => PropsClick(idItem, e)} to={(tipoItem === "Proposta" && "/proposals")||"elections"} style={{textDecoration:"none",color:"#000000"}}>
    <Box sx={{p: 1, textAlign: 'right' }}></Box>

</RouterLink>
   
    </Card>
  
     <br />







     <Modal visible style={{ zIndex: 1200}}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

  <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

       <Modal.Header style={{justifyContent: 'center'}} >
        <Modal.Title>Iniciar Votação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'scroll'}}>


<Stack spacing={3}>

<label>
Informe a duração da votação
</label>



   <TimeField 
    id="tempoDuracao" 
    input={<TextField label="Tempo de duração" variant="standard" />}  
    {...getFieldProps('tempoDuracao')}
    error={Boolean(touched.tempoDuracao && errors.tempoDuracao)}
    helperText={touched.tempoDuracao && errors.tempoDuracao}   
    //showSeconds 
                             
    />
    <label 
    style={{marginTop:"5px",fontSize:"12px",color:"grey"}}>
      Exemplo: 00:30 para 30 minutos - tempo Máximo 23h:59m
    </label>

        <br />
        
</Stack>


        </Modal.Body>
        <Modal.Footer>
          <Button2 variant="secondary" onClick={handleClose}>
            Cancelar
          </Button2>
          <Button2  type="submit" className="btn-success" loading={isSubmitting} variant="primary" >Salvar</Button2>
        </Modal.Footer>

    </Form>
  </FormikProvider>
</Modal>







</>

);


}